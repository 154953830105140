<div id="tree-container">
  <form>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Ricerca</mat-label>
      <input type="text" matInput [(ngModel)]="query" name="query" (keydown.enter)="SearchNodes()"/>
<!--       <button *ngIf="query" matSuffix mat-icon-button aria-label="Clear" (click)="ResetQuery()">
        <mat-icon>close</mat-icon>
      </button> -->
    </mat-form-field>

  </form>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox class="checklist-leaf-node" color="primary"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox [checked]="descendantsAllSelected(node)" color="primary"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>


