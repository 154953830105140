<div id="ticket-container">
  <mat-grid-list [rowHeight]="98.5" [cols]=6>
    <mat-grid-tile  colspan="6" id="sec-header">
      <div id="sec-title-conatiner">
        <span class="underline-text-nebula">
          <h5 class="nebula-dark left" class="assettitle">Ticket - Home</h5>
        </span>
      </div>
      <div id="submenu-btn-container">
        <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/list/' + selprodId" matTooltip="Elenco ticket" matTooltipPosition="right">
          <i class="material-icons">list</i></button>
        <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/calendar/' + selprodId" matTooltip="Calendario" matTooltipPosition="right">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/new/' + selprodId" matTooltip="Nuovo ticket" matTooltipPosition="right">
          <i class="material-icons">add</i></button>
      </div>
    </mat-grid-tile>
    <!--MODELLI 3D-->
    <mat-grid-tile rowspan="4" colspan="3" id="models-card-container">
      <mat-card id="models-card">
        <mat-card-title>
          <span class="underline-text-nebula">
            <h5 class="nebula-dark assettitle">Modelli</h5>
          </span>
        </mat-card-title>
        <mat-card-content>

        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <!--RIEPILOGO TICKET-->
    <mat-grid-tile rowspan="2" colspan="2" id="ticket-card-container">
      <mat-card id="ticket-card">
        <mat-card-title>
          <span class="underline-text-nebula">
            <h5 class="nebula-dark assettitle">Ticket</h5>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div id="ticket-item">
            <div id="ticket-tot-cont">
              <h4> n. {{allTickets.length}} </h4>
            </div>
            <div id="ticket-sub-cont">
              <div class="tick-sub-card">
                <h6>RISOLTI</h6>
                <div class="tsc" id="res">
                  <p>{{resolved}}</p>
                </div>
              </div>
              <div class="tick-sub-card">
                <h6>APERTI</h6>
                <div class="tsc" id="open">
                  <p>{{opened}}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <!--ALERT-->
    <mat-grid-tile rowspan="2" colspan="1" id="alert-card-container">
      <mat-card id="alert-card">
        <mat-card-title>
          <h5 style="color: white !important;" class="assettitle">Alert</h5>
        </mat-card-title>
        <mat-card-content>
          <div class="alert-item">
            <i class="material-icons">notifications</i>
            <h4> {{alerts.length}} </h4>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <!--ELENCO ALERT-->
    <mat-grid-tile rowspan="5" colspan="3" id="el-alert-card-container">
      <mat-card id="el-alert-card">
        <mat-card-title>
          <span class="underline-text-nebula">
            <h5 class="nebula-dark assettitle">Elenco Alert</h5>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div id="alert-item">
            <mat-selection-list [multiple]="false">
              <mat-list-option *ngFor="let alert of alerts">{{alert.product.code}} - {{alert.title}}</mat-list-option>
            </mat-selection-list>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <!--CLASSIFICAZIONE-->
    <mat-grid-tile rowspan="3" colspan="3" id="classification-card-container">
      <mat-card id="classification-card">
        <mat-card-title>
          <span class="underline-text-nebula">
            <h5 class="nebula-dark assettitle">Classificazione</h5>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div class="classification-item">
            <img src="/assets/image/ticket_strutturale.png" />
            <h6 class="nebula-dark"> Strutturale </h6>
            <h4 class="nebula-dark"> {{classification.strut}} </h4>
          </div>
          <div class="classification-item">
            <img src="/assets/image/ticket_elettrico.png" />
            <h6 class="nebula-dark"> Elettrico </h6>
            <h4 class="nebula-dark"> {{classification.elet}} </h4>
          </div>
          <div class="classification-item">
            <img src="/assets/image/ticket_idraulico.png" />
            <h6 class="nebula-dark"> Idraulico </h6>
            <h4 class="nebula-dark"> {{classification.idra}} </h4>
          </div>
          <div class="classification-item">
            <img src="/assets/image/ticket_meccanico.png" />
            <h6 class="nebula-dark"> Meccanico </h6>
            <h4 class="nebula-dark"> {{classification.mec}} </h4>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
