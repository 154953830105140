import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { CdeServiceService } from 'src/app/services/cde-service.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {

  files: NgxFileDropEntry[]
  constructor
  (public dialogRef: MatDialogRef<UploadDialogComponent>,
    private cdeservices: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: NgxFileDropEntry[],
    private _snackBar: MatSnackBar) {
      this.files = this.data;
    }

  ngOnInit(): void {
  }


   /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
        return "0 Bytes";
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }



}
