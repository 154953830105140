<form class="serchform" #f="ngForm">
  <mat-form-field class="input-search">
    <input matInput type="search" autocomplete="off"  [(ngModel)]="searchinput" name="searchinput" placeholder="Ricerca"/>
    <button mat-button *ngIf="searchinput" matSuffix mat-icon-button aria-label="Clear" (click)="searchinput=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-divider [vertical]=true></mat-divider>
  <mat-form-field class="selectasset">
    <mat-select  [(ngModel)]="selectedasset" name="selectedasset">
      <mat-option *ngFor="let asset of allasset" [value]="asset">{{asset}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="neb-btn-search" (click)="searchItems()"><i class="material-icons neb-icon-search">search</i></button>
</form>
