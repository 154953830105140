import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrls: ['./new-orders.component.scss']
})
export class NewOrdersComponent implements OnInit {

  newOrder: Order = {
    orderId: "",
    name: "",
    code: "",
    description: "",
    year: (new Date()).getFullYear(),
    status: "",
    product: {id: "", code: "", type: ""},
    manager: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        logo: "",
        type: ""
      },
      privilege: "",
      user_type: ""
    },
    client: {
      companyId: "",
      name: "",
      address: "",
      logo: "",
      type: ""},
    color: undefined,
    note: "",
    date_offer: new Date(),
    date_delivery: new Date(),
    price_awarded: undefined,
    cost_tot: undefined,
    costs:{
      cost_set: {
        name: "",
        onsiteC: 0,
        onlineC: 0,
        remoteC: 0,
        onsiteT: 0,
        onlineT: 0,
        remoteT: 0
      },
      costs_planned: 0,
      date_acquisition: new Date(),
      date_closing: new Date(),
      days_assigned: 0,
      days_effective: undefined,
      days_planned: undefined,
      price_offered: undefined,
    },
    activity_planned: [],
    activity_effective_ids: [],
    resources: []
  }

  constructor(private ordsrv: OrderService) {this.ordsrv.SetOrder(this.newOrder);
    }

  ngOnInit(): void {

  }

}
