import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import  localeIt from '@angular/common/locales/it';

import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileSaverModule } from 'ngx-filesaver';

//@ngular material
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTreeModule} from '@angular/material/tree';
import {MatSliderModule} from '@angular/material/slider';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

//moduli interni
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/Login/login.component';
import { HomeComponent } from './components/Home/home.component';
import { NavbarComponent } from './components/shared/Navbar/navbar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessoComponent } from './components/Login/Modules/accesso/accesso.component';
import { NewpswdComponent } from './components/Login/Modules/newpswd/newpswd.component';
import { RicercaTotComponent } from './components/shared/ricerca-tot/ricerca-tot.component';
import { UserMenuComponent } from './components/shared/Navbar/user-menu/user-menu.component';
import { ProductsComponent } from './components/Prodotti/products.component';
import { ElencoAssetComponent } from './components/shared/elenco-asset/elenco-asset.component';
import { InfoProdottoComponent } from './components/Prodotti/Modules/info-prodotto/info-prodotto.component';
import { MapDialogContent, MenuProdottoComponent } from './components/Prodotti/Modules/menu-prodotto/menu-prodotto.component';
import { HelmComponent } from './components/Helm/helm.component';
import { DashboardComponent } from './components/Helm/Dashboard/dashboard.component';
import { InternalUserComponent } from './components/Helm/InternalUser/internal-user.component';
import { NewInternaluserComponent } from './components/Helm/InternalUser/new-internaluser/new-internaluser.component';
import { EditInternaluserComponent } from './components/Helm/InternalUser/edit-internaluser/edit-internaluser.component';
import { AllInternaluserComponent } from './components/Helm/InternalUser/all-internaluser/all-internaluser.component';
import { ProductsHelmComponent } from './components/Helm/Products/products-helm.component';
import { ProductLinesHelmComponent } from './components/Helm/ProductLines/product-lines-helm.component';
import { OrdersHelmComponent } from './components/Helm/Orders/orders-helm.component';
import { CompaniesHelmComponent } from './components/Helm/Companies/companies-helm.component';
import { ExternalUserComponent } from './components/Helm/ExternalUser/external-user.component';
import { AllProductsHelmComponent } from './components/Helm/Products/all-products-helm/all-products-helm.component';
import { AllCompaniesHelmComponent } from './components/Helm/Companies/all-companies-helm/all-companies-helm.component';
import { NewCompanyHelmComponent } from './components/Helm/Companies/new-company-helm/new-company-helm.component';
import { EditCompanyHelmComponent } from './components/Helm/Companies/edit-company-helm/edit-company-helm.component';
import { ConfirmDialogComponent } from './components/shared/confirm-dialog/confirm-dialog.component';
import { NewUtilDialogComponent } from './components/shared/new-util-dialog/new-util-dialog.component';
import { AllExternaluserComponent } from './components/Helm/ExternalUser/all-externaluser/all-externaluser.component';
import { EditExternaluserComponent } from './components/Helm/ExternalUser/edit-externaluser/edit-externaluser.component';
import { NewExternaluserComponent } from './components/Helm/ExternalUser/new-externaluser/new-externaluser.component';
import { NewProductHelmComponent } from './components/Helm/Products/new-product-helm/new-product-helm.component';
import { EditProductHelmComponent } from './components/Helm/Products/edit-product-helm/edit-product-helm.component';
import { AllProductlinesComponent } from './components/Helm/ProductLines/all-productlines/all-productlines.component';
import { EditProductlineComponent } from './components/Helm/ProductLines/edit-productline/edit-productline.component';
import { NewProductlineComponent } from './components/Helm/ProductLines/new-productline/new-productline.component';
import { AllOrdersComponent } from './components/Helm/Orders/allOrders/all-orders.component';
import { NewOrdersComponent } from './components/Helm/Orders/newOrder/new-orders.component';
import { NoMainComponent } from './components/Helm/Orders/newOrder/no-main/no-main.component';
import { NoCostComponent } from './components/Helm/Orders/newOrder/no-cost/no-cost.component';
import { NoActivityComponent } from './components/Helm/Orders/newOrder/no-activity/no-activity.component';
import { EditOrderComponent } from './components/Helm/Orders/editOrder/edit-order.component';
import { EoActivityComponent } from './components/Helm/Orders/editOrder/eo-activity/eo-activity.component';
import { EoCostComponent } from './components/Helm/Orders/editOrder/eo-cost/eo-cost.component';
import { EoMainComponent } from './components/Helm/Orders/editOrder/eo-main/eo-main.component';
import { AoTableComponent } from './components/Helm/Orders/allOrders/ao-table/ao-table.component';
import { AoCalendarComponent } from './components/Helm/Orders/allOrders/ao-calendar/ao-calendar.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ViewOrderComponent } from './components/Helm/Orders/viewOrder/view-order.component';
import { VoActivityComponent } from './components/Helm/Orders/viewOrder/vo-activity/vo-activity.component';
import { VoCostComponent } from './components/Helm/Orders/viewOrder/vo-cost/vo-cost.component';
import { VoMainComponent } from './components/Helm/Orders/viewOrder/vo-main/vo-main.component';
import { CostSetDialogComponent } from './components/Helm/Orders/shared/cost-set-dialog/cost-set-dialog.component';
import { ProductlinesComponent } from './components/ProductLines/productlines.component';
import { MenuProductlineComponent } from './components/ProductLines/Modules/menu-productline/menu-productline.component';
import { InfoProductlineComponent } from './components/ProductLines/Modules/info-productline/info-productline.component';
import { ElencoProductlinesComponent } from './components/shared/elenco-productlines/elenco-productlines.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ActivitiesComponent } from './components/Activities/activities.component';
import { HomeTicketComponent } from './components/Activities/Ticket/home-ticket.component';
import { MenuActivitiesComponent } from './components/Activities/menu-activities/menu-activities.component';
import { ChatComponent } from './components/Activities/Chat/chat.component';
import { HomeMarkupComponent } from './components/Activities/Markup/home-markup.component';
import { AllTicketComponent } from './components/Activities/Ticket/all-ticket/all-ticket.component';
import { NewTicketComponent } from './components/Activities/Ticket/new-ticket/new-ticket.component';
import { ViewTicketComponent } from './components/Activities/Ticket/view-ticket/view-ticket.component';
import { EditTicketComponent } from './components/Activities/Ticket/edit-ticket/edit-ticket.component';
import { TicketListComponent } from './components/Activities/Ticket/ticket-list/ticket-list.component';
import { TicketCalendarComponent } from './components/Activities/Ticket/ticket-calendar/ticket-calendar.component';
import { ChatModuleComponent } from './components/Activities/Chat/chat-module/chat-module.component';
import { CdeHomeComponent } from './components/CDE/cde-home.component';
import { getSaver, SAVER } from './models/cde/common/saver.provider';
import { BearerTokenInterceptor } from './interceptors/bearer-token.interceptor';
import { CdeTableComponent } from './components/CDE/cde-table/cde-table.component';
import { LinkDialogComponent } from './components/CDE/dialog/link-dialog/link-dialog.component';
import { RevisionComponent } from './components/CDE/revision/revision.component';
import { CreateDialogFolderComponent } from './components/CDE/dialog/create-dialog-folder/create-dialog-folder.component';
import { UploadFileDialogComponent } from './components/CDE/dialog/upload-file-dialog/upload-file-dialog.component';
import { VideoPlayerComponent } from './components/CDE/common/video-player/video-player.component';
import { DeleteDialogComponent } from './components/CDE/dialog/delete-dialog/delete-dialog.component';
import { PreviewDialogComponent } from './components/CDE/dialog/preview-dialog/preview-dialog.component';
import { LinkHandlerComponent } from './components/CDE/link-handler/link-handler.component';
import { UploadDialogComponent } from './components/CDE/dialog/upload-dialog/upload-dialog.component';
import { DndDirective } from './directives/dnd.directive';
import { DeletePermanentlyDialogComponent } from './components/CDE/dialog/delete-permanently-dialog/delete-permanently-dialog.component';
import { LinkLoginComponent } from './components/CDE/common/link-login/link-login.component';
import { ShareWithDialogComponent } from './components/CDE/dialog/share-with-dialog/share-with-dialog.component';
import { Viewer3DComponent } from './components/Viewer3D/viewer3-d.component';
import { NavbarLightComponent } from './components/shared/navbar-light/navbar-light.component';
import { CdeLinkHomeComponent } from './components/CDE/cde-link-home.component';
import { TreeViewComponent } from './components/shared/tree-view/tree-view.component';
import { MarkupDrawComponent } from './components/shared/markup-draw/markup-draw.component';
import { TextInputDialogComponent } from './components/shared/text-input-dialog/text-input-dialog.component';
import { TextInputBottomComponent } from './components/shared/text-input-bottom/text-input-bottom.component';
import { ViewerSvgComponent } from './components/ViewerSvg/viewer-svg.component';
import { DrawingTicketComponent } from './components/Activities/Ticket/drawing-ticket/drawing-ticket.component';
import { MappaComponent } from './components/shared/mappa/mappa.component';
import { ViewerThreeJSComponent } from './components/ViewerThreeJS/viewer-three-js.component';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    //login
    LoginComponent,
    AccessoComponent,
    NewpswdComponent,
    //shared
    NavbarComponent,
    RicercaTotComponent,
    UserMenuComponent,
    ConfirmDialogComponent,
    NewUtilDialogComponent,
    MappaComponent,
    MapDialogContent,
    //home
    HomeComponent,
    //prodotti
    ProductsComponent,
    ElencoAssetComponent,
    InfoProdottoComponent,
    MenuProdottoComponent,
    //productlines
    ProductlinesComponent,
    MenuProductlineComponent,
    InfoProductlineComponent,
    ElencoProductlinesComponent,
    //helm
    HelmComponent,
    DashboardComponent,
    //helm - company
    AllCompaniesHelmComponent,
    EditCompanyHelmComponent,
    NewCompanyHelmComponent,
    CompaniesHelmComponent,
    //helm - internalusers
    AllInternaluserComponent,
    EditInternaluserComponent,
    NewInternaluserComponent,
    InternalUserComponent,
    //helm - externalusers
    ExternalUserComponent,
    AllExternaluserComponent,
    EditExternaluserComponent,
    NewExternaluserComponent,
    //helm - orders
    OrdersHelmComponent,
    AllOrdersComponent,
    NewOrdersComponent,
    NoMainComponent,
    NoCostComponent,
    NoActivityComponent,
    EditOrderComponent,
    EoActivityComponent,
    EoCostComponent,
    EoMainComponent,
    AoTableComponent,
    AoCalendarComponent,
    ViewOrderComponent,
    VoActivityComponent,
    VoCostComponent,
    VoMainComponent,
    CostSetDialogComponent,
    //helm - productlines
    ProductLinesHelmComponent,
    AllProductlinesComponent,
    EditProductlineComponent,
    NewProductlineComponent,
    //helm - products
    ProductsHelmComponent,
    AllProductsHelmComponent,
    NewProductHelmComponent,
    EditProductHelmComponent,
    ActivitiesComponent,
    HomeTicketComponent,
    MenuActivitiesComponent,
    ChatComponent,
    HomeMarkupComponent,
    AllTicketComponent,
    NewTicketComponent,
    ViewTicketComponent,
    EditTicketComponent,
    TicketListComponent,
    TicketCalendarComponent,
    ChatModuleComponent,
    //CDE
    CdeHomeComponent,
    CdeTableComponent,
    LinkDialogComponent,
    RevisionComponent,
    CreateDialogFolderComponent,
    UploadFileDialogComponent,
    VideoPlayerComponent,
    DeleteDialogComponent,
    PreviewDialogComponent,
    Viewer3DComponent,
    LinkHandlerComponent,
    UploadDialogComponent,
    DndDirective,
    DeletePermanentlyDialogComponent,
    LinkLoginComponent,
    ShareWithDialogComponent,
    NavbarLightComponent,
    CdeLinkHomeComponent,
    TreeViewComponent,
    MarkupDrawComponent,
    TextInputDialogComponent,
    TextInputBottomComponent,
    ViewerSvgComponent,
    DrawingTicketComponent,
    ViewerThreeJSComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    FileSaverModule,
    NgxMatColorPickerModule,
    // video player
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    NgxExtendedPdfViewerModule,
    NgxDocViewerModule,
    //@angluar material
    MatGridListModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatSelectModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSortModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatListModule,
    MatSidenavModule,
    MatTreeModule,
    MatSliderModule,
    DragDropModule,
    MatBottomSheetModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, strict: true}},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: SAVER, useFactory: getSaver},
    {provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true},
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }

