import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { CdeSnackBarComponent } from '../../cde-table/cde-table.component';

@Component({
  selector: 'app-create-dialog-folder',
  templateUrl: './create-dialog-folder.component.html',
  styleUrls: ['./create-dialog-folder.component.scss']
})
export class CreateDialogFolderComponent implements OnInit {

  nameFolder: string;
  @Output() confirm= new EventEmitter<CloudItem[]>();

  constructor(
    public dialogRef: MatDialogRef<CreateDialogFolderComponent>,
    private cdeservices: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  createNewFolder(): void{
    this.cdeservices
      .createCdeFolder({path: `${this.data}${this.nameFolder}/`})
        .subscribe(res => {
          console.log(res.message);
          this._snackBar.openFromComponent(CdeSnackBarComponent, {
            duration: 4 * 1000,
            data: res.message
          });
          this.nameFolder = "";
          this.confirm.emit(res.dir);
          this.dialogRef.close();
        })
  }
}
