import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/Login/authentication.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: [
    './user-menu.component.scss',
    '../../../../app.component.scss']
})
export class UserMenuComponent implements OnInit {

  usrimg: string = localStorage.getItem('image');
  name: string = localStorage.getItem('name');
  username: string = localStorage.getItem('username');

  constructor(private auth: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout(){
    this.auth.logout();
  }
}
