import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/Login/authentication.service';

@Component({
  selector: 'app-accesso',
  templateUrl: './accesso.component.html',
  styleUrls: [
    './accesso.component.scss',
    '../../../../app.component.scss',
    '../../login.component.scss'
]
})
export class AccessoComponent implements OnInit {

  bim: string = "";
  pwd: string = "";
  lat: number;
  lon: number;

  log: boolean = true;
  @Output() newPswd = new EventEmitter<boolean>();

  constructor(private auth: AuthenticationService, private router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.setLocation();
    console.log("log", this.log);
  }

  setLocation(){
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lon = position.coords.longitude;
        });
    }else{
        alert('Location not available');
    }
  }

  sendLogin(form: NgForm){
    this.auth.login(form)
      .subscribe(res => {
        this.router.navigate(['']);
      },
      (error) => {
        this.snackBar.open(error, "OK", {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top"
        })
      });
  }

  newPassword(){
    console.log('click');
    this.log = false;
    this.newPswd.emit(this.log);
    console.log("log", this.log);
  }


}
