import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: [
    './all-orders.component.scss',
    '../../../../app.component.scss'
  ]
})
export class AllOrdersComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {

  }



}
