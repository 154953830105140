import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-menu-productline',
  templateUrl: './menu-productline.component.html',
  styleUrls: [
    './menu-productline.component.scss',
    '../../../../app.component.scss'
  ]
})
export class MenuProductlineComponent implements OnInit {

  @Input() selectedProdlineId: string;
  selectedProdline: string = '/';
  //selectedProdline: string = localStorage.getItem('selectedProdline');
  constructor(private helmsrv: HelmService, private router: Router) { }

  ngOnInit(): void {
    this.LoadProductline();
  }

  ngOnChanges(){
    this.LoadProductline();
  }

  LoadProductline(){
    this.selectedProdlineId = localStorage.getItem('selectedProdline');
    if(this.selectedProdlineId != "" && this.selectedProdlineId != "TUTTE LE LINEE"){
      this.helmsrv.LoadSelectedProductline(this.selectedProdlineId)
      .subscribe( res => {
          this.selectedProdline = res.code;
        },
        (error) => {
          console.log("Errore caricamento linea di prodotto", error);
        });
    }
  }

  navigateTo(): void {
    localStorage.setItem("CLOUD", "PL");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/PRODUCTLINES/' + this.selectedProdline + '/'} });
  }
}
