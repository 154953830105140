import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NewUtilDialogComponent } from 'src/app/components/shared/new-util-dialog/new-util-dialog.component';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { User } from 'src/app/models/helm/user';
import { CommonService } from 'src/app/services/common.service';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-edit-internaluser',
  templateUrl: './edit-internaluser.component.html',
  styleUrls: [
    './edit-internaluser.component.scss',
    '../../../../app.component.scss'
  ]
})
export class EditInternaluserComponent implements OnInit {

  newImg: any;
  editableUserId: string;
  editableUser: User= {
    userId: "",
    name: "",
    surname: "",
    email: "",
    company: {companyId: "", name: "", address: "", logo: "", type: ""},
    phone: "",
    image: "",
    privilege: "",
    role: "",
    user_type: "",
    password:""
  };

  companies: CompanyLight[];
  roles: string[] = [];

  constructor(private helmsrv: HelmService, private cmnsrv: CommonService, private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.editableUserId = this.activatedRoute.snapshot.params.id;
    this.LoadUser();
  }

  LoadUser(){
    this.helmsrv.LoadSelectedUser(this.editableUserId).subscribe(
      (res: User) => {
        this.editableUser = res;
        this.helmsrv.LoadAllCompaniesLight()
          .subscribe((res) => {
            this.companies = res
            this.companies = this.companies.filter((comp) => {return comp.type == 'Owner' || comp.type == 'Partner'});
            this.cmnsrv.getUtil('roles')
              .subscribe((res) => {
                this.roles = res;
                for(let comp of this.companies){
                  if(comp.companyId == this.editableUser.company.companyId){
                    this.editableUser.company = comp;
                    break;
                  }
                }
              });
          });
      },
      (error) => {
        console.log("Errore caricamento utente selezionata", error);
      });
  }


  SaveUser(form: NgForm){
    this.editableUser = {...form.value};
    this.editableUser.userId = this.editableUserId;
    this.editableUser.user_type = "Interno";
    if(this.newImg != undefined) this.editableUser.image = this.newImg;
    if(this.editableUser.image == "https://nebulateam.bimspace.it/image/profile.png" || this.editableUser.image == undefined) this.editableUser.image = "";
    this.helmsrv.EditSelectedUser(this.editableUser)
      .subscribe(res => {
        this.router.navigateByUrl('/helm/internalusers')},
      (error) => {
        console.log("Errore editazione azienda", error);
      });
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.editableUser.image = this.newImg;
      };
    }
  }

  addNewRole(): void {
    const dialogRef = this.dialog.open(NewUtilDialogComponent, {
      height: '270px',
      width: '450px'
    });
    dialogRef.componentInstance.title = "Nuovo ruolo";
    dialogRef.componentInstance.settingType = "roles";
    dialogRef.componentInstance.confirm.subscribe(res => {
      this.roles.push(res);
    })
  }

}
