export class TreeItem {
    text: string;
    id: string;
    level?: number;
    disabled?: boolean;
    checked?: boolean;
    collapsed?: boolean;
    children?: TreeItem[];
}

export class TreeNode{
  expandable: boolean;
  name: string;
  level: number;
}
