import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cde-home',
  templateUrl: './cde-home.component.html',
  styleUrls: ['./cde-home.component.scss']
})
export class CdeHomeComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute
      .queryParams
      .subscribe(params => {
        let path = '/';
        if(params.path != undefined) path = params.path;

        this.router.navigate(["/cde"], { queryParams: { path: path } })
      })
  }

}
