<div class="maincontainer">
  <app-navbar></app-navbar>
  <mat-grid-list [cols]=12 class="appcontainer" rowHeight='fit'>
    <mat-grid-tile [colspan]=1>
      <app-menu-productline *ngIf="selectedProdlineId != 'TUTTE LE LINEE'" [selectedProdlineId]="selectedProdlineId"></app-menu-productline>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=7>
      <img *ngIf="selectedProdlineId == 'TUTTE LE LINEE'" [src]="nebulaImg" id="nebulaImg">
      <app-info-productline *ngIf="selectedProdlineId != 'TUTTE LE LINEE'" [selectedProdlineId]="selectedProdlineId"></app-info-productline>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4>
      <app-elenco-productlines (setSelProdline)="SelProdline($event)"></app-elenco-productlines>
    </mat-grid-tile>
  </mat-grid-list>
</div>
