import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cde-link-home',
  templateUrl: './cde-link-home.component.html',
  styleUrls: ['./cde-link-home.component.scss']
})
export class CdeLinkHomeComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute
      .queryParams
      .subscribe(params => {
        let share = '/';
        if(params.share != undefined) share = params.share;
        this.router.navigate(["/link"], { queryParams: { share: share } })
      })
  }
}
