import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CloudItem, Meta, Version } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss']
})

export class RevisionComponent implements OnInit {

  displayedColumns: string[] = ['Vers',	'Nome',	'Data Caricamento',	'Aggiornato da',	'Dimesione',	'Azioni'];
  meta: Meta[];
  versionNumber: number = 2;
  viewNewRevision: boolean = false;
  fileSelected: string;
  info: any = { rev: "", path: "", name: "", old_rev: ""}

  constructor(public dialogRef: MatDialogRef<RevisionComponent>,
    private cdeService: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: CloudItem,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.meta = this.data.meta;
  }

  downlaodVersion(version: Version): void {
    this.cdeService
      .dowloadFile(this.data, version)
        .subscribe(data => {
          const blob = new Blob([data], {type: data.type});
          /* const url= window.URL.createObjectURL(blob);
          window.open(url); */
          let name = this.data.name.substring(1)
          if (name.indexOf(".enc") > 0)
            name = this.data.name.substring(1, this.data.name.indexOf(".enc"))
          saveAs(blob, name);
        })
  }

  purgeVersions(): void{
    this.cdeService
      .purgeVersions({file: this.data.abs_path, number: this.versionNumber})
        .subscribe(res => {
          this.meta = res.item;
          this._snackBar.openFromComponent(RevSnackBarComponent, {
            duration: 4 * 1000,
            data: res.message
          });
        })

  }

  setRevision(version: Version, oldRevision: string): void {
    this.info.rev = version;
    this.info.path = this.data.abs_path;
    this.fileSelected = version.path;
    this.info.old_rev = oldRevision;
    this.viewNewRevision = true;
  }

  createRevision():void{
    console.log(this.info)
    this.cdeService
      .createCdeRevision(this.info)
        .subscribe(res => {
          this.meta = res.item;
          this.viewNewRevision = false;
          this._snackBar.openFromComponent(RevSnackBarComponent, {
            duration: 4 * 1000,
            data: res.message
          });
        })
  }

}


@Component({
  selector: 'app-snack-bar-component-rev',
  template: `
    <span class="revision-snackbar">
      {{data}}
    </span>
  `,
  styles: [`
    .revision-snackbar {
      color: white;
    }
  `],
})
export class RevSnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string){}

}
