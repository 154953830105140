import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  change =  new Subject<string[]>();

  constructor() { }

  emitValue(value: string[]){
    this.change.next(value);
  }
}
