<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>{{title}}</mat-label>
        <mat-select  [(ngModel)]="selectedUser" name="user">
            <mat-option *ngFor="let user of users" [value]="user" [style.backgroundColor]="user.color">
            {{user.name}} {{user.surname}} <mat-icon class="inline-icon" *ngIf="user.color == 'lightgreen'">check_circle</mat-icon>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Percorso {{data.cloud.type == "file" ? "File" : "Cartella"}}</mat-label>
        <input matInput [value]="data.cloud.abs_path" disabled name="path">
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="accent" mat-dialog-close class="confbtn menubtn">NO</button>
        <button mat-raised-button color="primary" class="confbtn menubtn" position="right" (click)="onSiClick()" cdkFocusInitial>{{buttonLabel}}</button>
    </mat-dialog-actions>
</form>