import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import { TreeItem, TreeNode } from 'src/app/models/shared/tree-item';


var TREE_DATA: TreeItem[] = [];


@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TreeItem[]>([]);

  get data(): TreeItem[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TreeItem` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TreeItem`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): TreeItem[] {
    return Object.keys(obj).reduce<TreeItem[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TreeItem();
      node.text = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.text = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}


@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  providers: [ChecklistDatabase]
})
export class TreeViewComponent implements OnInit{

  loaded:boolean = false;
  @Input() treeRoot: TreeItem[];
  @Output() selectedIds = new EventEmitter<string[]>()
  @Input() query: string;
  searchResults: TreeItem[] = [];

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeNode, TreeItem>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItem, TreeNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeNode | null = null;

  treeControl: FlatTreeControl<TreeNode>;

  treeFlattener: MatTreeFlattener<TreeItem, TreeNode>;

  dataSource: MatTreeFlatDataSource<TreeItem, TreeNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeNode>(true /* multiple */);

  constructor(private _database: ChecklistDatabase) {

  }

  ngOnInit(): void {
    TREE_DATA = this.treeRoot;
    this._database.dataChange.next(this.treeRoot);

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    this.loaded = true;
  }

  //#region TreeView with CheckBox
  getLevel = (node: TreeNode) => node.level;

  isExpandable = (node: TreeNode) => node.expandable;

  getChildren = (node: TreeItem): TreeItem[] => node.children;

  hasChild = (_: number, _nodeData: TreeNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TreeNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItem, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.text ? existingNode : new TreeNode();
    flatNode.name = node.text;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.getNodesIds();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.getNodesIds();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeNode): void {
    let parent: TreeNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeNode): TreeNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  //#endregion

  getNodesIds(){
    let ids = [];
    for(let node of this.checklistSelection.selected){
      ids.push(node.name);
    }
    this.selectedIds.emit(ids);
  }

  SearchNodes(){
    if(this.query == ""){
      this._database.dataChange.next(this.treeRoot);
    }
    else{
      this.searchResults = [];
      this.Search(this.treeRoot);
      this._database.dataChange.next(this.searchResults);
    }
  }

  Search(items: TreeItem[]){
    for(let item of items){
      if(item.text.includes(this.query))
        this.searchResults.push(item);
      if(item.children){
        this.Search(item.children);
      }
    }
  }

  ResetQuery(){
    this.query = "";
    this.SearchNodes();
  }

}

