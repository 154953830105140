import { Component, NgZone, OnInit } from '@angular/core';
import { Runtime } from 'x3dom';
import { X3dmodelInfo, X3dPart } from 'src/app/models/shared/x3dmodels';
import { Viewerx3dService } from 'src/app/services/viewerx3d.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';

declare const SystemJS: any;
declare let $ : any;

@Component({
  selector: 'app-viewer3-d',
  templateUrl: './viewer3-d.component.html',
  styleUrls: [
    './viewer3-d.component.scss',
    './x3dom.css'
  ]
})
export class Viewer3DComponent implements OnInit {
  loaded:boolean = false;
  selectedParts: string[] = [];
  sel3dPath: string;
  sel3dmodel: X3dmodelInfo;
  parts: any[] = [];
  numUnloadedParts: number = 0;
  progessActive: boolean = false;
  showAll: boolean = true;
  navigate: boolean = false;
  toolTipEnabled: boolean = true;
  measureEnabled: boolean = false;
  listEnabled: boolean = false;
  viewpoint: any;
  x3druntime: Runtime;
  selpartId: string;
  query: string = '';

  markup: boolean = false;
  markupImg: string;
  modelName: string;
  prodcode: string;

  constructor(private viewsrv: Viewerx3dService, private route: ActivatedRoute,
    private zone: NgZone, private fsaversrv: FileSaverService) {
      this.route.queryParams
      .subscribe(params => {
        this.sel3dPath = params.path;
        this.prodcode = this.sel3dPath.replace('/REPO/PRODUCTS/', '');
        this.prodcode = this.prodcode.slice(0,this.prodcode.indexOf('/'));
        this.viewsrv.GetX3dModel(this.sel3dPath)
          .subscribe(res => {
            this.sel3dmodel = res;
            this.modelName = this.sel3dmodel.name;
            console.log(this.sel3dmodel);
            this.loaded = true;
            SystemJS.import('x3dom')
              .then(() => {
                console.log('loaded');

                this.loadModel();
              })
              .catch((e: any) => {
                console.warn(e);
              })
          });
      })
  }



  ngOnInit(): void {
    // console.log(this.item)

  }

  loadModel(){
    $('#x3d').innerHeight(600);
    $('#x3d').innerWidth(1000);
    document.getElementById('x3dom-x3dElement-canvas').setAttribute('width', '1000px');
    if(this.parts.length > 0){
      this.deletePart(null);
    }

    if(this.sel3dmodel.allx3dmodels.length > 1){
      for(let model of this.sel3dmodel.allx3dmodels){
        this.createNativePart(this.sel3dmodel.path+ '/' + model.nomefile, model.id, null, model.nome);
      }
    }
    else{
      let model = this.sel3dmodel.allx3dmodels[0];
      let modelname = model.nomefile.slice(0, model.nomefile.lastIndexOf('.')) + '_1.x3d';
      this.createNativePart(this.sel3dmodel.path+ '/' + modelname, model.id, null, model.nome);
    }
    //console.log(this.parts);
    this.initViewer('message');
    this.fitAll();

  }

  deletePart(id){
    if(typeof(id)=="undefined"||id==null)
    {   //DELETE ALL
        for(var i in this.parts)
            this.deletePart(this.parts[i]);
    }
    try{
      document.getElementById('sceneMaster').removeChild(id)
        // $("#sceneMaster").removeChild(id);
      console.log('eliminato')
    }
    catch(e)
    {
      console.log(e);;
    }
  }

  createNativePart(url,id,transformmatrix,options){
    //console.log(url,id,transformmatrix,options)
    let p : X3dPart = {
      id: id,
      url: decodeURI(url),
      transformatrix: JSON.parse(transformmatrix),
      toolTipText: options !== undefined && options.toolTipText !== undefined ? options.toolTipText : id
    }

    this.numUnloadedParts++;
    this.updateProgress();

    this.addToScene(p);
  }

  updateProgress(){
    if(this.numUnloadedParts > 0 && !this.progessActive){
      this.progessActive = true;
      $('#progressBar').removeClass("progressBar_Out").addClass("progressBar_In");
    }
    else{
      this.progessActive = false;
      $('#progressBar').removeClass("progressBar_In").addClass("progressBar_Out");
    }
  }

  addToScene(part: X3dPart){
    var newInline = document.createElement('Inline');

    newInline.onload = () => {
      this.numUnloadedParts--;
      this.updateProgress();
      if(this.showAll) {
          var e = $('#x3dElement');
          var eRuntime: Runtime = e.prop('runtime') as Runtime;
          eRuntime.fitAll();
      }
    }

    newInline.onerror = () => {
      this.numUnloadedParts--;
      this.updateProgress();
      this.parts.splice(this.parts.indexOf(part), 1);
    }

    newInline.onmousemove = event => {
      if(!this.navigate){
        if(this.toolTipEnabled){
          $('#toolTip').css('left', event.x + 15 + 'px');
          $('#toolTip').css('top', event.y + 50 +'px');
        }
        else if (this.measureEnabled)
        {
            /* measure.updatePoint(event.worldX, event.worldY, event.worldZ);
            $("#toolTip").html(measure.toString());
            $('#toolTip').css('left', event.layerX + 15 + 'px');
            $('#toolTip').css('top', event.layerY + 50 +'px'); */
        }
      }
    }

    newInline.onmouseout = () => {
      $('#toolTip').hide();
    }

    newInline.onclick = event => {
      let selpart = event.target as HTMLElement;
      if(event.button == 1){
        if(this.measureEnabled)
        {
            // measure.tooglePoint();
        }
        else
        {
            $('#toolTip').hide();

          this.zone.run(() => {
            this.query =selpart.getAttribute('id');
          })
            console.log(selpart.getAttribute('id'))

            //var searchString = this.dataset.id;
            //$('#SimpleJSTree').jstree('search', searchString);
            //document.getElementById("search-input").setAttribute('value', searchString);
            //obtainFileInfo(this.dataset.id);
            this.enableList(true);
        }
      }
      else if(event.button == 2){
        $('#toolTip').hide();
        this.selpartId = selpart.getAttribute('id');
        $("#contextMenuParts").attr("data-id", selpart.getAttribute('id'));
        $("#cm_id").html(selpart.getAttribute('id'));
        $('#contextMenuParts').css('left', event.offsetX + 'px');
        $('#contextMenuParts').css('top', event.offsetY + 30 + 'px');

        $('#contextMenuParts').show();
      }
    }

    newInline.setAttribute("DEF","part_"+part.id);
    newInline.setAttribute("data-id",part.id);
    newInline.setAttribute("data-highlight", 'false');
    if(part.toolTipText!==undefined)
        newInline.setAttribute("data-tooltiptext",part.toolTipText)
    newInline.setAttribute("url",part.url);
    if(!part.transformatrix)
    {
        var trans =  document.createElement("MatrixTransform");
        trans.appendChild(newInline);
        this.parts[part.id]=trans;
        document.getElementById("sceneMaster").appendChild(trans);
    }
    else
    {
        var group =  document.createElement("Group");
        if(typeof(part.transformatrix)=="string")
            part.transformatrix=[part.transformatrix];
        for(let matrixId of part.transformatrix)
        {
            var trans =  document.createElement("MatrixTransform");
            trans.setAttribute("matrix",part.transformatrix[matrixId]);
            if(matrixId==0)
                trans.appendChild(newInline);
            else
            {
                var useInline = document.createElement("Inline");
                useInline.setAttribute("USE","part_"+part.id);
                trans.appendChild(useInline);
            }
            group.appendChild(trans);
        }
        this.parts[part.id]=group;
        document.getElementById("sceneMaster").appendChild(group);
    }
  }

  enableTooltips(value: boolean){
    this.toolTipEnabled = value;
    if(this.toolTipEnabled){
      $("#tooltipMode").removeClass("toolbarTip").addClass("toolbarTip_active");
    }
    else{
      $("#tooltipMode").removeClass("toolbarTip_active").addClass("toolbarTip");
    }
  }

  updatePartVisibility(id, val){
    console.log(id)
    if(this.parts[id] != undefined){
      this.parts[id].render = val;
      console.log("part visibility for "+ id + " changed to:  "+ val);
    }
  }

  handleBackgroundClick(event:MouseEvent){
    console.log('click')
    if (event.button == 1)
    {
/*         $(".contextMenu").hide();
        var searchString = "";
        $('#SimpleJSTree').jstree('search', searchString);
        document.getElementById("search-input").setAttribute('value', searchString); */
    }
    if (event.button == 2)
    {
        $("#contextMenuDefault").css("top", event.y + 30 + "px");
        $("#contextMenuDefault").css("left", event.x + "px");
        $("#contextMenuDefault").show();
    }
  }

  fitAll(){
    this.x3druntime.fitAll();
  }

  enableList(value) {
    this.listEnabled = value;

    if (this.listEnabled)
    {
        $("#partList").removeClass("toolbarList").addClass("toolbarList_active");
        $("#parts").removeClass("listParts").addClass("listParts_enabled");
        // $(".box").css("max-height", "500px");
    }
    else
    {
        $("#partList").removeClass("toolbarList_active").addClass("toolbarList");
        $("#parts").removeClass("listParts_enabled").addClass("listParts");
        // $(".box").prop("max-height", "350px");
    }
  }

  //#region Setting Viewer
  initViewer(controller)
  {
    var tipoController = controller;
    this.x3druntime = $("#x3dElement").prop('runtime') as Runtime;
    var viewarea = this.x3druntime.canvas.doc._viewarea;
    var navi = this.x3druntime.getActiveBindable("NavigationInfo");
    navi.setAttribute("explorationMode", "move");

    var vpchanged = function(event){
        if(event) {
            this.viewpoint={position:event.position,orientation:event.orientation};
        }
    };
    document.getElementById('vp0').addEventListener('viewpointChanged', vpchanged, false);

    this.enableTooltips(true);

    // $("#viewerVersion").html("v" + viewerVersion.toFixed(1));
    $("#aboutOverlay").click(function() {
        $(this).hide();
    });

    $("#contextMenuParts").bind("contextmenu",function(event){
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = false;
        return false;
    });
    $("#contextMenuDefault").bind("contextmenu",function(event){
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = false;
        return false;
    });

    $("#cm_hideAll").click(function(){
        for (var part in this.parts)
        {
          this.updatePartVisibility(part, false);
        }
        $("#contextMenuDefault").hide();
    });

    $("#cm_unhideAll").click(function(){
        for (var part in this.parts)
        {
            this.updatePartVisibility(part, true);
        }
        $("#contextMenuDefault").hide();
    });

    $("#contextMenuViews").css("right", "35px");
    $("#contextMenuViews").css("top", "130px");

    $("#viewpointMode").click(function(){
        $("#viewpointMode").removeClass("toolbarViewpoint").addClass("toolbarViewpoint_active");
        $("#contextMenuViews").show();
    });

    $("#cm_top").click(function(){
      this.x3druntime.showAll("negY");
        $("#contextMenuViews").hide();
    });

    $("#cm_bottom").click(function(){
    this.x3druntime.showAll("posY");
        $("#contextMenuViews").hide();
    });

    $("#cm_front").click(function(){
        this.x3druntime.showAll("posX");
        $("#contextMenuViews").hide();
    });

    $("#cm_back").click(function(){
        this.x3druntime.showAll("negX");
        $("#contextMenuViews").hide();
    });

    $("#cm_left").click(function(){
        this.x3druntime.showAll("negZ");
        $("#contextMenuViews").hide();
    });

    $("#cm_right").click(function(){
        this.x3druntime.showAll("posZ");
        $("#contextMenuViews").hide();
    });

    $("#contextMenuViews").mouseleave(function(){
        $("#viewpointMode").removeClass("toolbarViewpoint_active").addClass("toolbarViewpoint");
        $("#contextMenuViews").hide();
    });

    //notify parent window
    window.parent.postMessage({type:"viewerLoaded"}, "*");
  }

  //#endregion
  hideSelected(){
    this.updatePartVisibility(this.selpartId, false);
    $("#contextMenuParts").hide();
  }

  hideUnselected(){
    for(let p of this.sel3dmodel.allx3dmodels){
      if(p.id != this.selpartId){
        this.updatePartVisibility(p.id, false);
      }
    }
    $("#contextMenuParts").hide();
  }

  enlightParts(ids: string[]){
    this.selectedParts = ids;
    if(ids){
      for(let id of Object.keys(this.parts)){
        if(ids.indexOf(id) != -1){
          this.updatePartHighlight(id, true);
        }
        else{
          this.updatePartHighlight(id, false);
        }
      }
    }
  }

  updatePartHighlight(id: string, val: boolean){
    if(id in this.parts)
    {
        if(val){
          if(this.parts[id].dataset.color===undefined)
            this.parts[id].highlight(true,"#D9D900");
          else{
            var components = this.parts[id].dataset.color.split(",");
            for(let i in components)
                components[i]=1.0-components[i];
                this.parts[id].highlight(true,components.join(","));
          }
        }
        else{
          if(this.parts[id].dataset.color===undefined)
            this.parts[id].highlight(false,"#D9D900");
          else
            this.parts[id].highlight(true,this.parts[id].dataset.color);
        }
    }
  }

  downloadParts(){
    if(this.selectedParts.length > 0){
      this.selectedParts.sort((a,b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;})

        let parArray = {};
        for (let i = 0; i < this.selectedParts.length; i++){
            let name = this.selectedParts[i].substring(0, this.selectedParts[i].lastIndexOf('_')) == "" ? this.selectedParts[i] : this.selectedParts[i].substring(0, this.selectedParts[i].lastIndexOf('_'));
            if(parArray[name] == undefined) parArray[name] = 1;
            else parArray[name]++;
        }

        let spareParts = [];
        for(let [k,v] of Object.entries(parArray)){
          spareParts.push({name: k, quantita: v});
        }

        this.viewsrv.GetSpareParts(spareParts)
          .subscribe(res => {
            let blob = new Blob([res], {type: res.type});
            this.fsaversrv.save(blob, this.sel3dmodel.name + '.pdf')
          })

        console.log(spareParts);
    }

  }

  //#region  Markup
  loadMarkup(){
    this.markupImg = this.x3druntime.getScreenshot();
    console.log(typeof(this.markupImg));
    this.markup = true;
  }

  exitMarkup(value: string){
    if(value == 'Close'){
      this.markup = false;
      window.location.reload();
      //this.router.navigate([this.route.url])
    }
  }
  //#endregion
}
