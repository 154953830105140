<div class="maincontainer" *ngIf="loaded">
  <app-navbar></app-navbar>
  <div class="appcontainer">

    <div class="flex-container">
      <mat-card id="tree-card">
        <mat-card-title id="tree-card-title">
          <span class="underline-text-nebula">
            <h5 class="nebula-dark left" class="assettitle">{{sel3dmodel.name}}</h5>
          </span>
        </mat-card-title>
        <mat-card-content>
          <app-tree-view [treeRoot]="sel3dmodel.treemodel" [query]="query" (selectedIds)="enlightParts($event)"></app-tree-view>
        </mat-card-content>
      </mat-card>

      <div id="funcBtns">
        <a *ngIf="!markup" mat-mini-fab color="primary" matTooltip="Ricarica modello" (click)="loadModel()"><i class="material-icons">refresh</i></a>
        <!-- <a mat-mini-fab color="primary" matTooltip="Evidenzia parti" (click)="enlightParts()"><i class="material-icons">search</i></a> -->
        <a *ngIf="!markup" mat-mini-fab color="primary" matTooltip="Markup" (click)="loadMarkup()" [disabled]="x3druntime == undefined">
          <i class="material-icons">photo_camera</i></a>
        <a *ngIf="!markup" mat-mini-fab color="primary" matTooltip="Download elenco ricambi" (click)="downloadParts()" [disabled]="selectedParts.length == 0">
          <i class="material-icons">download</i></a>
      </div>
      <div class="viewer-container" *ngIf="!markup">
        <div class="x3dWrapper">
          <x3d id="x3dElement" width="100%" height="600px">
            <scene id="scene" (onbackgroundclicked)="handleBackgroundClick(event);" render="true" bboxcenter="0,0,0" bboxsize="-1,-1,-1" pickmode="idBuf" dopickpass="true">
              <environment id="env" sorttrans="true" gammacorrectiondefault="linear" tonemapping="none" frustumculling="true" smallfeaturethreshold="1" lowprioritythreshold="0.5" minframerate="1" maxframerate="62.5" userdatafactor="-1" smallfeaturefactor="-1" occlusionvisibilityfactor="-1" lowpriorityfactor="-1" tessellationerrorfactor="-1" ssaoradius="0.7" ssaoamount="0.3" ssaorandomtexturesize="4" ssaoblurdepthtreshold="1" smallfeatureculling="false" lowpriorityculling="false" enablearc="false"></environment>
              <navigationinfo id="navi" headlight="true" type="EXAMINE ANY " typeparams="-0.4,60,0.05,2.8" explorationmode="rotate" avatarsize="0.25,1.6,0.75" walkdamping="2" speed="1" transitiontime="1" transitiontype="LINEAR "></navigationinfo>
              <viewpoint id="vp0" position="0 0 10" orientation="0 0 0 1" fieldofview="0.785398" centerofrotation="0,0,0" znear="-1" zfar="-1"></viewpoint>
              <transform id="sceneMaster" translation="0 0 0" render="true" bboxcenter="0,0,0" bboxsize="-1,-1,-1" center="0,0,0" rotation="0,0,0,0" scale="1,1,1" scaleorientation="0,0,0,0"></transform>
              <transform id="sceneMaster2" render="true" bboxcenter="0,0,0" bboxsize="-1,-1,-1" center="0,0,0" translation="0,0,0" rotation="0,0,0,0" scale="1,1,1" scaleorientation="0,0,0,0"></transform>
              <transform id="lineTrafo" render="false" bboxcenter="0,0,0" bboxsize="-1,-1,-1" center="0,0,0" translation="0,0,0" rotation="0,0,0,0" scale="1,1,1" scaleorientation="0,0,0,0">
                <shape ispickable="false" render="true" bboxcenter="0,0,0" bboxsize="-1,-1,-1">
                  <appearance sorttype="auto" alphaclipthreshold="0.1">
                    <material emissivecolor="1 0 0" ambientintensity="0.2" diffusecolor="0.8,0.8,0.8" shininess="0.2" specularcolor="0,0,0"></material>
                    <depthmode enabledepthtest="false" depthfunc="none" znearrange="-1" zfarrange="-1"></depthmode>
                  </appearance>
                  <indexedlineset coordindex="0 1 0 -1" solid="true" ccw="true" usegeocache="true" lit="true" colorpervertex="true" colorindex="">
                    <coordinate id="line" point="0.0 0.0 0.0, 8.0 8.0 8.0"></coordinate>
                  </indexedlineset>
                </shape>
              </transform>
              <matrixtransform render="true" bboxcenter="0,0,0" bboxsize="-1,-1,-1" matrix="1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1">
                <inline render="false" bboxcenter="0,0,0" bboxsize="-1,-1,-1" url="" load="true" namespacename=""></inline>
              </matrixtransform>
              <matrixtransform render="true" bboxcenter="0,0,0" bboxsize="-1,-1,-1" matrix="1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1">
                <inline render="false" bboxcenter="0,0,0" bboxsize="-1,-1,-1" url="" load="true" namespacename=""></inline>
              </matrixtransform>
            </scene>
          </x3d>
        </div>

        <div id="toolTip"></div>
                  <ul id="parts" class="listParts box"></ul>

    <!--               <div  id="fileInfoView" class="fileInfo box fileInfoView">
                    <ul style="height: 40px; margin: 5px;">
                      <li style="float: right; cursor: pointer;"><a onclick="enableInfoView(!infoViewEnabled)"><i class='material-icons'>clear</i></a></li>
                      <li style="float: right; cursor: pointer;"><a onclick="enableInfoEdit(!infoEditEnabled)"><i class='material-icons'>create</i></a></li>
                    </ul>
                    <ul>
                      <li><label translate="Code">Codice</label></li>
                      <li id="iCodice" style="font-weight: bold; font-size: 18px;">Codice001</li>
                      <li><label translate="Description">Descrizione</label></li>
                      <li id="iDescrizione">Descrizione</li>
                      <li><label translate="Family">Famiglia</label></li>
                      <li id="iFamiglia">Famiglia</li>
                      <li><label translate="Material">Materiale</label></li>
                      <li id="iMateriale">Materiale</li>
                      <li><label translate="Producer">Produttore</label></li>
                      <li id="iProduttore">Produttore</li>
                      <li><label translate="DrawnBy">Disegnato da</label></li>
                      <li id="iDisegnato">Disegnato da</li>
                      <li><label translate="ApprovedBy">Approvato da</label></li>
                      <li id="iApprovato">Approvato da</li>
                      <li><label translate="Commercial">Commerciale</label></li>
                      <li id="iCommerciale">Commerciale</li>
                    </ul>
                  </div> -->

    <!--               <div  id="fileInfoEdit" class="fileInfo box fileInfoEdit">
                    <ul style="height: 40px; margin: 5px;">
                      <li style="float: right; cursor: pointer;"><a onclick="enableInfoEdit(!infoEditEnabled)"><i class='material-icons'>clear</i></a></li>
                      <li style="float: right; cursor: pointer;"><a onclick="saveInfo()"><i class='material-icons'>save</i></a></li>
                    </ul>
                    <ul>
                      <li><label translate="Code">Codice</label></li>
                      <li id="iCodice" style="font-weight: bold; font-size: 18px;"></li>
                      <li><label translate="Description">Descrizione</label></li>
                      <li><input  id="iDescrizioneE"></li>
                      <li><label translate="Family">Famiglia</label></li>
                      <li><input  id="iFamigliaE"></li>
                      <li><label translate="Material">Materiale</label></li>
                      <li><input  id="iMaterialeE"></li>
                      <li><label translate="Producer">Produttore</label></li>
                      <li><input  id="iProduttoreE"></li>
                      <li><label translate="DrawnBy">Disegnato da</label></li>
                      <li><input  id="iDisegnatoE"></li>
                      <li><label translate="ApprovedBy">Approvato da</label></li>
                      <li><input  id="iApprovatoE"></li>
                      <li><label translate="Commercial">Commerciale</label></li>
                      <li><input  id="iCommercialeE"></li>
                    </ul>
                  </div> -->

                  <ul id="contextMenuDefault" class="contextMenu" style="display: none;">
                      <li id="cm_hideAll" class="contextMenuItem" translate="HideAll">Hide All</li>
                      <li id="cm_unhideAll" class="contextMenuItem" translate="UnhideAll">Unhide All</li>
                  </ul>

                  <ul id="contextMenuParts" class="contextMenu" style="display: none;">
                      <li id="cm_id" class="contextMenuItem_static">[[ID]]</li>
                      <li class="contextMenuSeparator"></li>
                      <li id="cm_hideSelected" class="contextMenuItem" (click)="hideSelected()" translate="HideSelected">Nascondi Selezione</li>
                      <li id="cm_hideUnselected" class="contextMenuItem" (click)="hideUnselected()" translate="HideUnselected">Nascondi non selezionati</li>
                      <!-- <li id="cm_delete" class="contextMenuItem">Delete</li> -->
                      <li class="contextMenuSeparator"></li>
                      <li id="cm_statistic" class="contextMenuItem" style="display: none;">Statistic</li>
                  </ul>

                  <ul id="contextMenuViews" class="contextMenu" style="display: none;">
                    <li id="cm_top" class="contextMenuItem">Top</li>
                    <li id="cm_bottom" class="contextMenuItem">Bottom</li>
                    <li id="cm_front" class="contextMenuItem">Front</li>
                    <li id="cm_back" class="contextMenuItem">Back</li>
                    <li id="cm_left" class="contextMenuItem">Left</li>
                    <li id="cm_right" class="contextMenuItem">Right</li>
                  </ul>

                  <div id="aboutOverlay">
                      <div id="aboutWindow">
                          <div class="aboutHeader">
                              <div style="font-size: 30px; line-height: 30px;">
                                  <span style="font-weight: lighter;">Web</span><span style="font-weight: bold;">VIS</span><span id="viewerVersion" style="font-size: 12px; margin-left: 3px;">v1.0</span>
                              </div>
                              <div style="margin: -3px 0px 0px 4px; font-size: 12px; line-height: 12px;">
                                  <span>Transcoder </span><span id="transcoderVersion" style="font-size: 10px; color: #F66;">is offline</span>
                              </div>
                          </div>
                          <div>
                              <div style="font-size: 10px; margin-top: 60px">Copyright © 2013 Fraunhofer IGD / VCST</div>
                              <div style="font-size: 10px;"><a href="http://www.igd.fraunhofer.de/vcst" title="Fraunhofer IGD / VCST" target="_blank">www.igd.fraunhofer.de/vcst</a> </div>
                          </div>
                      </div>
                  </div>
      </div>
      <app-markup-draw class="viewer-container" [background]="markupImg" [modelName]="modelName" [prodcode]="prodcode"
        (exit)="exitMarkup($event)" *ngIf="markup"></app-markup-draw>
    </div>
  </div>
</div>

