import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { POINT } from '../models/shared/point';

@Injectable({providedIn: 'root'})
export class FabricService
{
  // draw properties are public to keep the demo more compact; but, you break it ... you buy it
  public strokeWidth: number;
  public strokeColor: string;
  public circleRadius: number;
  public circleFill: string;

  protected _canvas?: fabric.Canvas;

  protected _points: Array<fabric.Circle>;
  protected _polylines: Record<string, fabric.Polyline>;
  protected _rectangles: Record<string, fabric.Rect>;
  protected _circles: Record<string, fabric.Circle>;

  constructor(private http: HttpClient)
  {
    this.strokeWidth  = 2;
    this.strokeColor  = '#000000';
    this.circleFill   = '#0000ff';
    this.circleRadius = 2;

    this._points    = new Array<fabric.Circle>();
    this._polylines = {}
    this._rectangles = {}
    this._circles = {}
  }

  public set canvas(surface: fabric.Canvas)
  {
    if (surface !== undefined && surface != null && surface instanceof fabric.Canvas) {
      this._canvas = surface;
    }
  }

  public setCanvasBackground(img: string){
    fabric.Image.fromURL(img, image => {
      this._canvas.setBackgroundImage(image, this._canvas.renderAll.bind(this._canvas), {
        scaleX: this._canvas.width / image.width,
        scaleY: this._canvas.height / image.height
      })
    })
  }

  public clear(): void
  {
    if (this._canvas)
    {
      this._points.forEach( (circle: fabric.Circle): void => {
        this._canvas.remove(circle);
      });

      this._points.length = 0;

      Object.keys(this._polylines).forEach( (name: string): void => {
        this._canvas.remove(this._polylines[name]);
      });

      this._polylines = {};

      this._canvas.renderAll();
    }
  }

  public removeSelObj(name: string, type:string){
    switch (type){
      case 'polyline':
        this._canvas.remove(this._polylines[name]);
      break;
      case 'rectangle':
        this._canvas.remove(this._rectangles[name]);
      break;
    }

  }

  public addPoint(p: POINT): void
  {
    const circle: fabric.Circle = new fabric.Circle(
      {
        left: p.x - this.circleRadius,
        top: p.y - this.circleRadius,
        fill: this.circleFill,
        radius: this.circleRadius
      });

    this._points.push(circle);

    if (this._canvas)
    {
      this._canvas.add(circle);
      this._canvas.renderAll();
    }
  }

  public addPolyline(name: string, points: Array<POINT>, clear: boolean = true): void
  {
    const polyLine: fabric.Polyline = new fabric.Polyline(points,
      {
        strokeWidth: this.strokeWidth,
        stroke: this.strokeColor,
        fill: 'transparent',
        selectable: false,
        moveCursor: 'arrow',
        hoverCursor: 'arrow',
      });

    if (this._canvas)
    {
      if (clear && this._polylines[name] !== undefined) {
        this._canvas.remove(this._polylines[name]);
      }

      this._canvas.add(polyLine);
      this._canvas.renderAll();
    }

    this._polylines[name] = polyLine;
  }

  public addRectangle(name: string, points: Array<POINT>, clear: boolean = true){
    const newRect = new fabric.Rect({
      left: points[0].x,
      top: points[0].y,
      fill:'transparent',
      width: Math.abs(points[0].x - points[1].x),
      height: Math.abs(points[0].y - points[1].y),
      strokeWidth: this.strokeWidth,
      stroke: this.strokeColor,
      selectable: false,
      moveCursor: 'arrow',
      hoverCursor: 'arrow'
    })

    if (this._canvas)
    {
      if (clear && this._rectangles[name] !== undefined) {
        this._canvas.remove(this._rectangles[name]);
      }

      this._canvas.add(newRect);
      this._canvas.renderAll();
    }

    this._rectangles[name] = newRect;
  }

  public addCircle(name: string, points: Array<POINT>, clear: boolean = true){
    let radius = Math.sqrt(Math.pow(Math.abs(points[0].x - points[1].x),2) + Math.pow(Math.abs(points[0].y - points[1].y),2));
    const newCirc = new fabric.Circle({
      left: points[0].x - radius,
      top: points[0].y- radius,
      fill:'transparent',
      radius: radius,
      strokeWidth: this.strokeWidth,
      stroke: this.strokeColor,
      selectable: false,
      moveCursor: 'arrow',
      hoverCursor: 'arrow'
    })

    if (this._canvas)
    {
      if (clear && this._circles[name] !== undefined) {
        this._canvas.remove(this._circles[name]);
      }

      this._canvas.add(newCirc);
      this._canvas.renderAll();
    }

    this._circles[name] = newCirc;
  }

  public addText(text: string, point:POINT, size: number){
    const newtext = new fabric.Text(text, {
      left: point.x,
      top: point.y,
      fontSize: size,
      fill: this.strokeColor
    })
    this._canvas.add(newtext);
    this._canvas.renderAll();
  }

  public setPenWidth(val:number){
    this._canvas.freeDrawingBrush.width = val;
    this.strokeWidth = val;
  }

  public setColour(val:string){
    this._canvas.freeDrawingBrush.color = '#' + val;
    this.strokeColor = '#' + val;
  }

  public refreshCanvas(){
    let drawings = this._canvas.getObjects();
    for(let d of drawings){
      this._canvas.remove(d)
    }
    this._canvas.renderAll();
  }

  public deleteObjects(){
    if(this._canvas.getActiveObjects().length > 0) {
      for(let ob of this._canvas.getActiveObjects()){
        this._canvas.remove(ob)
      }
    }
  }

  public allowSelect(){
    this._canvas.selection = true;
    if(this._canvas.getObjects().length > 0){
      for(let ob of this._canvas.getObjects()){
        ob.selectable = true;
        ob.moveCursor= 'pointer';
        ob.hoverCursor= 'pointer';
      }
    }
  }

  public denySelect(){
    this._canvas.selection = false;
    if(this._canvas.getObjects().length > 0){
      for(let ob of this._canvas.getObjects()){
        ob.selectable = false;
        ob.moveCursor= 'arrow';
        ob.hoverCursor= 'arrow';
      }
    }
  }

  public saveMarkup(title:string, prodcode:string, model:string): Observable<string>{
    this._canvas.renderAll();
    let image = this._canvas.toDataURL({format: 'png'});
    let b64 =  image.replace(/^data:image\/(png|jpg);base64,/, '');
    console.log(this._canvas.toDataURL({format: 'png'}).length * 8)
    const ApiUrl = environment.mainUrl + 'markup/';
    let option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, {
      image: b64,
      product: prodcode,
      model: model,
      markupname: title
    }, {headers: option}).pipe(
      map(res => {
        return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
