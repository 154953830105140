
<form #fm="ngForm">
  <!-- <h4 mat-dialog-title>{{costset.name}}</h4> -->

  <mat-dialog-actions align="end">
    <button *ngIf="dialogtype=='new'" mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="SaveCost(fm)" [disabled]="fm.invalid" type="submit">
      <i class="material-icons">save</i></button>
    <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" mat-dialog-close matTooltipPosition="below">
      <i class="material-icons">close</i></button>
  </mat-dialog-actions>

  <mat-dialog-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=3  rowHeight="80px">
          <mat-grid-tile [colspan]=3>
            <mat-form-field class="row" style="margin-top: 10px;">
              <mat-label>Nome</mat-label>
              <input matInput *ngIf="dialogtype=='new'" type="text" name="name" [ngModel]="costset.name" required>
              <input matInput *ngIf="dialogtype!='new'" type="text" name="name" [ngModel]="costset.name" readonly>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="title" style="margin-top: 30px; ">
              <span class="underline-text-nebula">
                <h4 class="mdc-text left assettitle">Tecnico</h4>
              </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Online</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput *ngIf="dialogtype=='new'" type="number" name="onlineT" [ngModel]="costset.onlineT" required>
                <input matInput *ngIf="dialogtype!='new'" type="number" name="onlineT" [ngModel]="costset.onlineT" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Onsite</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput *ngIf="dialogtype=='new'" type="number" name="onsiteT" [ngModel]="costset.onsiteT" required>
                <input matInput *ngIf="dialogtype!='new'" type="number" name="onsiteT" [ngModel]="costset.onsiteT" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Onsite</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput *ngIf="dialogtype=='new'" type="number" name="remoteT" [ngModel]="costset.remoteT" required>
                <input matInput *ngIf="dialogtype!='new'" type="number" name="remoteT" [ngModel]="costset.remoteT" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=3>
            <div class="title" style="margin-top: 30px; ">
              <span class="underline-text-nebula">
                <h4 class="mdc-text left assettitle">Commerciale</h4>
              </span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Online</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput *ngIf="dialogtype=='new'" type="number" name="onlineC" [ngModel]="costset.onlineC" required>
                <input matInput *ngIf="dialogtype!='new'" type="number" name="onlineC" [ngModel]="costset.onlineC" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Onsite</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput *ngIf="dialogtype=='new'" type="number" name="onsiteC" [ngModel]="costset.onsiteC" required>
                <input matInput *ngIf="dialogtype!='new'" type="number" name="onsiteC" [ngModel]="costset.onsiteC" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Onsite</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput *ngIf="dialogtype=='new'" type="number" name="remoteC" [ngModel]="costset.remoteC" required>
                <input matInput *ngIf="dialogtype!='new'" type="number" name="remoteC" [ngModel]="costset.remoteC" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
  </mat-dialog-content>
</form>

