<div id="ticket-container">
  <mat-grid-list [rowHeight]="98.5" [cols]=1>
    <mat-grid-tile id="sec-header">
      <div id="sec-title-conatiner">
        <span class="underline-text-nebula">
          <h5 class="nebula-dark left" class="assettitle">Ticket - Elenco ticket</h5>
        </span>
      </div>
      <div id="submenu-btn-container">
        <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/list/' + selprodId" matTooltip="Elenco ticket" matTooltipPosition="right">
          <i class="material-icons">list</i></button>
        <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/calendar/' + selprodId" matTooltip="Calendario" matTooltipPosition="right">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary" [routerLink]="'/activities/ticket/new/' + selprodId" matTooltip="Nuovo ticket" matTooltipPosition="right">
          <i class="material-icons">add</i></button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile rowspan="7" id="ticketlist-container">
      <mat-card id="container-card">

      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
