import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CostSet } from 'src/app/models/helm/cost-set';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-cost-set-dialog',
  templateUrl: './cost-set-dialog.component.html',
  styleUrls: [
    './cost-set-dialog.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class CostSetDialogComponent implements OnInit {

  costset: CostSet= {
    name: '',
    onlineC: undefined,
    onsiteC: undefined,
    remoteC: undefined,
    onlineT: undefined,
    onsiteT: undefined,
    remoteT: undefined
  };

  @Input() costname: string = "";
  @Input() dialogtype: string = "";
  @Output() confirm = new EventEmitter();

  constructor(private ordsrv: OrderService, public dialogRef: MatDialogRef<CostSetDialogComponent>, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if(this.costname != ''){
      this.ordsrv.getCostSet()
      .subscribe(costs => {
        this.costset = costs.filter(c => c.name == this.costname)[0];
      })
    }
  }

  SaveCost(form: NgForm){
    this.costset = {...form.value};
    this.ordsrv.getCostSet()
      .subscribe(costs => {
        if(costs.filter(c => c.name == this.costset.name).length == 0){
          console.log(this.costset);
          this.ordsrv.AddCostSet(this.costset)
            .subscribe(msg => {
              console.log(msg);
              this.confirm.emit(this.costset.name);
              this.dialogRef.close();
            })

        }
        else{
          this.snackBar.open("Esistono già dei costi predefiniti con questo nome", "OK", {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "bottom"
          })
        }
      })
  }
}
