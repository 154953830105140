<form #fm="ngForm">
  <mat-card id="assetinfocontainer" *ngIf="loaded">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Nuovo ticket</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="AddNewTicket(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" (click)="NavigateTo()" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=3  rowHeight="126px">
          <mat-grid-tile rowspan="3">
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Titolo</mat-label>
                <input matInput type="text" name="title" [ngModel]="newticket.title" required>
              </mat-form-field>
              <mat-form-field class="row" *ngIf="selprodId != 'all'">
                <mat-label>Prodotto</mat-label>
                <input matInput type="text" name="product" [ngModel]="selProduct.code" readonly>
              </mat-form-field>
              <mat-form-field class="row" *ngIf="selprodId == 'all'">
                <mat-label>Prodotto</mat-label>
                <input matInput type="text" name="product" [(ngModel)]="selectedprod" [matAutocomplete]="autoProd" [formControl]="prodCtrl" required>
                <mat-autocomplete  #autoProd="matAutocomplete">
                  <mat-option *ngFor="let prod of filteredprods | async" [value]="prod">{{prod}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Modello</mat-label>
                <mat-select name="model" [(ngModel)]="newticket.model">
                  <mat-option *ngFor="let mod of allModels" [value]="mod">{{mod.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="modelcode" [ngModel]="newticket.model.code" readonly>
              </mat-form-field>

            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" rowspan="3" style="background-color: red;">
            <div class="col-int">
            </div>
          </mat-grid-tile>
          <mat-grid-tile rowspan="2">
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Priorità</mat-label>
                <mat-select name="priority" [ngModel]="newticket.priority" required>
                  <mat-option value="0">Bassa</mat-option>
                  <mat-option value="1">Media</mat-option>
                  <mat-option value="2">Alta</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Classificazione</mat-label>
                <mat-select name="classification" [ngModel]="newticket.classification" required>
                  <mat-option value="0">Strutturale</mat-option>
                  <mat-option value="1">Elettrico</mat-option>
                  <mat-option value="2">Idraulico</mat-option>
                  <mat-option value="3">Meccanico</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Note</mat-label>
                <textarea matInput type="text" name="note" [ngModel]="newticket.note"></textarea>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile rowspan="2">
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Aggiungi markup</mat-label>
                <input matInput type="text" name="markup" [(ngModel)]="selectemk"  required [matAutocomplete]="autoMk" [formControl]="mkCtrl">
                <mat-autocomplete  #autoMk="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let mk of filteredmarkups | async" [value]="mk">{{mk.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Data consegna</mat-label>
                <input matInput type="date" name="endDate" [ngModel]="newticket.endDate" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Assegna a</mat-label>
                <input matInput type="text" name="assigned" [(ngModel)]="selectedusr"  required [matAutocomplete]="autoUsr" [formControl]="usrCtrl">
                <mat-autocomplete  #autoUsr="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let usr of filteredusrs | async" [value]="usr">{{usr.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile rowspan="2">
            <div id="attach-container">
              <div id="attach-title">
                <span class="underline-text-nebula">
                  <h5 class="assettitle">Allegati</h5>
                </span>
                <button mat-fab class="menubtn" color="primary" (click)="fileInput.click()" matTooltip="Aggiungi allegato" matTooltipPosition="below" >
                  <input type="file" id="file" #fileInput hidden (change)="AddAttach($event)" />
                  <i class="material-icons">add</i></button>
              </div>
              <div id="attach-list">
                <mat-list>
                  <mat-list-item *ngFor="let attach of newticket.attachs">{{attach.name}}</mat-list-item>
                </mat-list>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
