import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helm',
  templateUrl: './helm.component.html',
  styleUrls: [
    './helm.component.scss',
    '../../app.component.scss'
  ]
})
export class HelmComponent implements OnInit {

  section: string = 'dashboard';
  constructor() { }

  ngOnInit(): void {
  }

  SetSection(sec: string){
    this.section = sec;
  }
}
