import * as THREE from 'three';
import {ElementRef, Injectable, NgZone, OnDestroy} from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'

const DEFAULT_CAMERA = '[default]';


@Injectable({
  providedIn: 'root'
})
export class ThreejsService  implements OnDestroy {
  filepath: string;
  defaultCamera: THREE.PerspectiveCamera;
  activeCamera: THREE.PerspectiveCamera;
  canvas: HTMLCanvasElement;
  spinner: HTMLCanvasElement;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  controls: OrbitControls;
  pickPosition: any;
  state: any;
  gui: any;
  cameraCtrl = null;
  cameraFolder = null;
  animFolder = null;
  animCtrls = [];
  morphFolder = null;
  morphCtrls = [];
  skeletonHelpers = [];
  gridHelper = null;
  axesHelper = null;
  background = null;
  content = null;
  prevTime = 0;
  mixer = null;
  clips;
  lights = [];
  options = {
      model: '',
      preset: '',
      cameraPosition: null,
      spinner: null
  }

  MAP_NAMES = [
    'map',
    'aoMap',
    'emissiveMap',
    'glossinessMap',
    'metalnessMap',
    'normalMap',
    'roughnessMap',
    'specularMap',
  ];

  private file: Blob;

  option!: HttpHeaders;
  private frameId: number = null;

  public constructor(private ngZone: NgZone, private http: HttpClient) {
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      this.canvas = null;
    }
  }

  public createScene(path: string, canvas: ElementRef<HTMLCanvasElement>, spinner: ElementRef<HTMLCanvasElement>): THREE.WebGLRenderer {
    this.filepath = path;

    this.state = {
      environment: 'Footprint Court (HDR)',
      background: false,
      playbackSpeed: 1.0,
      actionStates: {},
      camera: DEFAULT_CAMERA,
      wireframe: false,
      skeleton: false,
      grid: false,

      // Lights
      addLights: true,
      exposure: 1.0,
      textureEncoding: 'sRGB',
      ambientIntensity: 0.3,
      ambientColor: 0xFFFFFF,
      directIntensity: 0.8 * Math.PI, // TODO(#116)
      directColor: 0xFFFFFF,
      bgColor1: '#ffffff',
      bgColor2: '#353535'
    };

    this.spinner = spinner.nativeElement;
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      preservingDrawingBuffer: true,
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true // smooth edges
    });

    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    // create the scene
    this.scene = new THREE.Scene();

    this.defaultCamera = new THREE.PerspectiveCamera(
      75, this.canvas.clientWidth / this.canvas.clientHeight, 0.1, 1000
    );
    this.defaultCamera.position.z = 5;
    this.scene.add(this.defaultCamera);

    this.showSpinner();
    this.canvas.addEventListener('resize', this.resize.bind(this), false);

/*     if(this.filepath.includes('.fbx')){
      this.GetFbxModel("test")
      .subscribe(res => {
        this.loadFBX(res);
      });
    }
    else{
      this.GetGltfModel("test")
      .subscribe(res => {
        this.loadGLTF(res);
      });
    } */

    this.GetGltfModel(this.filepath)
      .subscribe(res => {
        this.loadGLTF(res);
      });
    return this.renderer;
  }



  //#region GLTF
  GetGltfModel(path: string): Observable<any>{

    const ApiUrl = environment.mainUrl + 'gltffile';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));

    return this.http.get(ApiUrl, { params: {path: path}, headers:this.option})
  }

  loadGLTF(path) {
    // Load.
    return new Promise((resolve, reject) => {
        const blobURLs = [path];
        const loader = new GLTFLoader();
        loader.load(blobURLs, (file) => {
            const scene = file;
            const clips = file.animations || [];
            this.setGltfContent(scene, clips);

            blobURLs.forEach(URL.revokeObjectURL);
              resolve(file);
          }, undefined, reject);
      });
  }

  setGltfContent(object, clips) {
    this.clear();

    const box = new THREE.Box3().setFromObject(object.scene);
    const size = box.getSize(new THREE.Vector3()).length();
    const center = box.getCenter(new THREE.Vector3());

    object.scene.position.x += (object.scene.position.x - center.x);
    object.scene.position.y += (object.scene.position.y - center.y);
    object.scene.position.z += (object.scene.position.z - center.z);

    this.defaultCamera.name = "MAIN_CAMERA"
    this.defaultCamera.near = size / 100;
    this.defaultCamera.far = size * 100;

    if (this.options.cameraPosition) {
        this.defaultCamera.position.fromArray(this.options.cameraPosition);
        this.defaultCamera.lookAt(new THREE.Vector3());
    } else {
        this.defaultCamera.position.copy(center);
        this.defaultCamera.position.x += size / 2;
        this.defaultCamera.position.y += size / 5;
        this.defaultCamera.position.z += size / 2;
        this.defaultCamera.lookAt(center);
    }

    this.setCamera(DEFAULT_CAMERA);

    this.defaultCamera.updateProjectionMatrix();

    this.controls = new OrbitControls(this.defaultCamera, this.renderer.domElement);
    this.controls.autoRotate = false;
    this.controls.autoRotateSpeed = -10;
    this.controls.enablePan = true;
    this.controls.maxDistance = size * 10;
    this.controls.enabled = true;
    this.controls.update();

    this.scene.add(object.scene);
    this.content = object.scene;

    this.state.addLights = true;
    this.content.traverse((node) => {
        if (node.isLight) {
            this.state.addLights = false;
        }
    });

    this.setClips(clips);
    this.updateLights();
    this.updateTextureEncoding();
    this.updateDisplay();
    this.animate();

    console.info('[FBX Viewer] THREE.Scene exported as `window.scene`.');
  }
  //#endregion

  //#region FBX
  GetFbxModel(path: string): Observable<any>{
    const ApiUrl = environment.mainUrl + 'fbxfile';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));

    return this.http.get(ApiUrl, { params: {path: path}, headers:this.option})
  }

  loadFBX(path) {
    // Load.
    return new Promise((resolve, reject) => {
        const blobURLs = [path];
        const loader = new FBXLoader();
        loader.load(blobURLs, (file) => {
            const scene = file;
            const clips = file.animations || [];
            this.setFbxContent(scene, clips);

            blobURLs.forEach(URL.revokeObjectURL);
              resolve(file);
          }, undefined, reject);
      });
  }

  setFbxContent(object, clips) {
    this.clear();

    const box = new THREE.Box3().setFromObject(object);
    const size = box.getSize(new THREE.Vector3()).length();
    const center = box.getCenter(new THREE.Vector3());

    object.position.x += (object.position.x - center.x);
    object.position.y += (object.position.y - center.y);
    object.position.z += (object.position.z - center.z);

    this.defaultCamera.name = "MAIN_CAMERA"
    this.defaultCamera.near = size / 100;
    this.defaultCamera.far = size * 100;


    if (this.options.cameraPosition) {

        this.defaultCamera.position.fromArray(this.options.cameraPosition);
        this.defaultCamera.lookAt(new THREE.Vector3());

    } else {

        this.defaultCamera.position.copy(center);
        console.log('[this.defaultCamera]', this.defaultCamera);
        this.defaultCamera.position.x += size / 2;
        this.defaultCamera.position.y += size / 5;
        this.defaultCamera.position.z += size / 2;
        this.defaultCamera.lookAt(center);
    }


    this.setCamera(DEFAULT_CAMERA);
    console.log(this.defaultCamera);

    this.defaultCamera.updateProjectionMatrix();

    this.controls = new OrbitControls(this.defaultCamera, this.renderer.domElement);
    this.controls.autoRotate = false;
    this.controls.autoRotateSpeed = -10;
    this.controls.enablePan = true;
    this.controls.maxDistance = size * 10;
    this.controls.enabled = true;
    this.controls.update();

      this.scene.add(object);
      this.content = object;

      this.state.addLights = true;
      this.content.traverse((node) => {
          if (node.isLight) {
              this.state.addLights = false;
          }
      });

      this.setClips(clips);

      this.updateLights();
      this.updateTextureEncoding();
      this.updateDisplay();
      this.animate();
      console.info('[GLTF Viewer] THREE.Scene loaded.');
  }
  //#endregion

  //#region VIEWER UTILS
  animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        this.canvas.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      this.canvas.addEventListener('resize', () => {
        this.resize();
      });
    });
  }

  render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });

    /* this.cube.rotation.x += 0.01;
    this.cube.rotation.y += 0.01; */
    this.renderer.render(this.scene, this.defaultCamera);
    this.hideSpinner();
  }

  resize(): void {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    this.defaultCamera.aspect = width / height;
    this.defaultCamera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  clear() {

    if (!this.content) {
        return;
    }

    this.scene.remove(this.content);

    // dispose geometry
    this.content.traverse((node) => {

        if (!node.isMesh) {
            return;
        }

        node.geometry.dispose();

    });

    // dispose textures
    this.traverseMaterials(this.content, (material) => {

        this.MAP_NAMES.forEach((map) => {

            if (material[map]) {
                material[map].dispose();
            }

        });

    });

  }

  getCubeMapTexture(environment) {
    const {path, format} = environment;

    // no envmap
    if (!path) {
        return Promise.resolve({envMap: null, cubeMap: null});
    }

    const cubeMapURLs = [
        path + 'posx' + format, path + 'negx' + format,
        path + 'posy' + format, path + 'negy' + format,
        path + 'posz' + format, path + 'negz' + format
    ];

    // standard
    const envMap = new THREE.CubeTextureLoader().load(cubeMapURLs);
    //envMap.format = THREE.RGBFormat;
    return Promise.resolve({envMap, cubeMap: envMap});
  }

  updateDisplay() {
    if (this.skeletonHelpers.length) {
        this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
    }

    this.traverseMaterials(this.content, (material) => {
        material.wireframe = this.state.wireframe;
    });

    this.content.traverse((node) => {
        if (node.isMesh && node.skeleton && this.state.skeleton) {
            const helper = new THREE.SkeletonHelper(node.skeleton.bones[0].parent);
            //helper.material.linewidth = 3;
            this.scene.add(helper);
            this.skeletonHelpers.push(helper);
        }
    });

    if (this.state.grid !== Boolean(this.gridHelper)) {
        if (this.state.grid) {
            this.gridHelper = new THREE.GridHelper(5,1);
            this.axesHelper = new THREE.AxesHelper();
            //this.axesHelper.renderOrder = 999;
            //this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
            this.scene.add(this.gridHelper);
            //this.scene.add(this.axesHelper);
        } else {
            this.scene.remove(this.gridHelper);
            //this.scene.remove(this.axesHelper);
            this.gridHelper = null;
            this.axesHelper = null;
        }
    }
  }

  traverseMaterials(object, callback) {
    object.traverse((node) => {
        if (!node.isMesh) {
            return;
        }
        const materials = Array.isArray(node.material)
            ? node.material
            : [node.material];
        materials.forEach(callback);
    });
  }

  updateTextureEncoding() {
    const encoding = this.state.textureEncoding === 'sRGB'
        ? THREE.sRGBEncoding
        : THREE.LinearEncoding;
    this.traverseMaterials(this.content, (material) => {
        if (material.map) {
            material.map.encoding = encoding;
        }
        if (material.emissiveMap) {
            material.emissiveMap.encoding = encoding;
        }
        if (material.map || material.emissiveMap) {
            material.needsUpdate = true;
        }
    });
  }

  setClips(clips) {
    if (this.mixer) {
        this.mixer.stopAllAction();
        this.mixer.uncacheRoot(this.mixer.getRoot());
        this.mixer = null;
    }

    this.clips = clips;
    if (!clips.length) {
        return;
    }

    this.mixer = new THREE.AnimationMixer(this.content);
  }

  setCamera(name) {
    if (name === DEFAULT_CAMERA) {
        //this.controls.enabled = true;
        this.activeCamera = this.defaultCamera;
    } else {
        //this.controls.enabled = false;
        this.content.traverse((node) => {
            if (node.isCamera && node.name === name) {
                this.activeCamera = node;
            }
        });
    }
  }

  updateLights() {
    const state = this.state;
    const lights = this.lights;

    if (state.addLights && !lights.length) {
        this.addLights();
    } else if (!state.addLights && lights.length) {
        this.removeLights();
    }

    this.renderer.toneMappingExposure = state.exposure;

    if (lights.length === 2) {
        lights[0].intensity = state.ambientIntensity;
        lights[0].color.setHex(state.ambientColor);
        lights[1].intensity = state.directIntensity;
        lights[1].color.setHex(state.directColor);
    }
  }

  addLights() {
    const state = this.state;
    const hemiLight = new THREE.HemisphereLight();
    hemiLight.name = 'hemi_light';
    this.scene.add(hemiLight);
    this.lights.push(hemiLight);
    return;
  }

  removeLights() {
      this.lights.forEach((light) => light.parent.remove(light));
      this.lights.length = 0;
  }
  //#endregion

  //#region SPINNER
  showSpinner() {
    this.spinner.style.display = '';
  }

  hideSpinner() {
      this.spinner.style.display = 'none';
  }
  //#endregion

  //#region MARKUP
  public snapshot(): string{
    this.renderer.render(this.scene, this.defaultCamera);
    return this.renderer.domElement.toDataURL("image/png");
  }
  //#endregion
}
