<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
<!--     <div class="legend" *ngIf="this.legenda == true">
      <h4 style="text-align: center; margin-top: 20px; font-weight: bold">
        <mat-select placeholder="Complessivo"
          style="width: 100%"
          (selectionChange)="searchMediaManutenzione($event.value)"
        >
          <mat-option [value]="0">Impianto Elettrico</mat-option>
          <mat-option [value]="1">Impianto Meccanico</mat-option>
          <mat-option [value]="2">Impianto Termico</mat-option>
          <mat-option [value]="3">Impianto Refrigerante</mat-option>
          <mat-option [value]="4">Impianto Idrico</mat-option>
          <mat-option [value]="5">Complessivo</mat-option>
        </mat-select>
      </h4>
      <hr />
        <ng-container matColumnDef="legenda">

        </ng-container>
      <span>
        <mat-checkbox
          [(ngModel)]="visibilita[1]"
          checkbox="visibilita == true, checked"
          color="primary"
          style="margin-left: 5px"
          matTooltip="Accendi/Spegni categoria"
          (change)="makeManutenzioneVisible($event.checked)"
        ></mat-checkbox>
        <mat-icon style="color: rgb(66, 245, 11); margin-left: 10px"
          >label_important</mat-icon
        >(0) Nessun intervento da prevedere
      </span>
      <br>
      <span>
        <mat-checkbox
          [(ngModel)]="visibilita[2]"
          checkbox="visibilita == true, checked"
          color="primary"
          style="margin-left: 5px; margin-top: 20px"
          matTooltip="Accendi/Spegni categoria"
          (change)="makeManutenzioneVisible($event.checked)"
        ></mat-checkbox>
        <mat-icon style="color: green; margin-top: 20px; margin-left: 10px"
          >label_important</mat-icon
        >(1) Intervento man straordinaria da prevedere fra cinque e dieci anni
      </span>
      <br>
      <span>
        <mat-checkbox
          [(ngModel)]="visibilita[3]"
          checkbox="visibilita == true, checked"
          color="primary"
          style="margin-left: 5px; margin-top: 20px"
          matTooltip="Accendi/Spegni categoria"
          (change)="makeManutenzioneVisible($event.checked)"
        ></mat-checkbox>
        <mat-icon style="color: yellow; margin-left: 10px"
          >label_important</mat-icon
        >(2) Intervento man straordinaria da prevedere fra due e cinque anni
      </span>
      <br>
      <span>
        <mat-checkbox
          [(ngModel)]="visibilita[4]"
          checkbox="visibilita == true, checked"
          color="primary"
          style="margin-left: 5px; margin-top: 20px"
          matTooltip="Accendi/Spegni categoria"
          (change)="makeManutenzioneVisible($event.checked)"
        ></mat-checkbox>
        <mat-icon style="color: orange; margin-left: 10px"
          >label_important</mat-icon
        >(3) Intervento man straordinaria urgente da prevedere entro due anni
      </span>
      <br>
      <span>
        <mat-checkbox
          [(ngModel)]="visibilita[5]"
          checkbox="visibilita == true, checked"
          color="primary"
          style="margin-left: 5px; margin-top: 20px;"
          matTooltip="Accendi/Spegni categoria"
          (change)="makeManutenzioneVisible($event.checked)"
        ></mat-checkbox>
        <mat-icon style="color: red; margin-left: 10px"
          >label_important</mat-icon
        >(4) Intervento man ordinaria
      </span>
      <br>
      <span>
        <mat-checkbox
          [(ngModel)]="visibilita[0]"
          checkbox="visibilita == true, checked = true"
          color="primary"
          style="margin-left: 5px; margin-top: 20px; margin-bottom: 10px"
          matTooltip="Accendi/Spegni categoria"
          (change)="makeManutenzioneVisible($event.checked)"
        ></mat-checkbox>
        <mat-icon
          style="color: rgb(2, 2, 2); margin-top: 20px; margin-left: 10px"
          >label_important</mat-icon
        >NC
      </span>
      <br>
    </div> -->
<!--     <button
      matTooltip="Accendi visualizzazione legenda"
      *ngIf="this.legenda == false"
      style="float: left; bottom: 5px; left: -22px"
      (click)="accendiSpegniLegenda()"
      mat-button
      color="primary"
    >
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      *ngIf="this.legenda == true"
      style="float: left; bottom: 5px; left: -22px"
      (click)="accendiSpegniLegenda()"
      mat-button
      color="primary"
      matTooltip="Spegni visualizzazione legenda"
    >
      <mat-icon>visibility_off</mat-icon>
    </button> -->
  </div>
</div>
