<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left assettitle">Gestione Costi</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'activity')" matTooltip="Attività" matTooltipPosition="below" type="submit">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'main')" matTooltip="Torna all'anagrafica" matTooltipPosition="below" type="button">
          <i class="material-icons">arrow_back</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=3  rowHeight="100px">
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data offerta</mat-label>
                <input matInput type="date" name="date_offer" [(ngModel)]="newOrder.date_offer">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data acquisizione</mat-label>
                <input matInput type="date" name="date_acquisition" [(ngModel)]="newCost.date_acquisition">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data consegna</mat-label>
                <input matInput type="date" name="date_delivery" [(ngModel)]="newOrder.date_delivery">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore offerto</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_offered" [(ngModel)]="newCost.price_offered">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore aggiudicato</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_awarded" [(ngModel)]="newOrder.price_awarded">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi uomo previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="costs_planned" [(ngModel)]="newCost.costs_planned" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Giornate uomo previste</mat-label>
                <input matInput type="number" name="days_planned" [(ngModel)]="newCost.days_planned">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Giornate uomo pianificate</mat-label>
                <input matInput type="number" name="days_assigned" [(ngModel)]="newCost.days_assigned" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="cost_tot" [(ngModel)]="newOrder.cost_tot">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi predefiniti</mat-label>
                <mat-select name="cost_set" [(ngModel)]="newCost.cost_set">
                  <mat-option *ngFor="let cost of allcostset" [value]="cost">{{cost.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int" style="margin-left: 7%;">
              <button mat-fab class="menubtn left" color="primary" (click)="openDialog('new')" matTooltip="Aggiungi costo predefinito" matTooltipPosition="below" type="submit">
                <i class="material-icons">add</i></button>
              <button *ngIf="newCost.cost_set.name != ''" mat-fab class="menubtn left" color="primary" (click)="openDialog('view')" matTooltip="Visualizza costo predefinito" matTooltipPosition="below" type="button">
                <i class="material-icons">local_atm</i></button>
              <button *ngIf="newCost.cost_set.name != ''" mat-fab class="menubtn left" color="primary" (click)="UpdateCost()" matTooltip="Aggiorna costo uomo" matTooltipPosition="below" type="button">
                <i class="material-icons">refresh</i></button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
