import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { environment } from 'src/environments/environment';
import { CdeSnackBarComponent } from '../../cde-table/cde-table.component';

@Component({
  selector: 'app-link-login',
  templateUrl: './link-login.component.html',
  styleUrls: ['./link-login.component.scss']
})
export class LinkLoginComponent implements OnInit {

  hide = true;
  password: string;
  share: string;
  code: string;
  nebulaImg: string = environment.imageUrl + 'nebula.png';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdeService: CdeServiceService,
    private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.route
    .queryParams
      .subscribe(params => {
        this.code = params.share
        this.share = "share=" + params.share;
      })
  }

  login(){
    console.log(this.share);
    this.cdeService
      .privateLinkLogin(this.password, this.share)
        .subscribe(res => {
          this.router.navigate(["link"], { queryParams: {share : this.code, path: res.root}});
        }, error => {
          this._snackBar.openFromComponent(CdeSnackBarComponent, {
            duration: 4 * 1000,
            data: error.error
          });
        })
  }

}
