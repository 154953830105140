import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/Helm/Dashboard/dashboard.component';
import { HelmComponent } from './components/Helm/helm.component';
import { AllInternaluserComponent } from './components/Helm/InternalUser/all-internaluser/all-internaluser.component';
import { InternalUserComponent } from './components/Helm/InternalUser/internal-user.component';
import { NewInternaluserComponent } from './components/Helm/InternalUser/new-internaluser/new-internaluser.component';
import { LoginComponent } from './components/Login/login.component';
import { HomeComponent } from './components/Home/home.component';
import { ProductsComponent } from './components/Prodotti/products.component';
import { LoginGuard } from './login.guard';
import { ExternalUserComponent } from './components/Helm/ExternalUser/external-user.component';
import { CompaniesHelmComponent } from './components/Helm/Companies/companies-helm.component';
import { ProductsHelmComponent } from './components/Helm/Products/products-helm.component';
import { ProductLinesHelmComponent } from './components/Helm/ProductLines/product-lines-helm.component';
import { AllProductsHelmComponent } from './components/Helm/Products/all-products-helm/all-products-helm.component';
import { AllCompaniesHelmComponent } from './components/Helm/Companies/all-companies-helm/all-companies-helm.component';
import { NewCompanyHelmComponent } from './components/Helm/Companies/new-company-helm/new-company-helm.component';
import { EditCompanyHelmComponent } from './components/Helm/Companies/edit-company-helm/edit-company-helm.component';
import { EditInternaluserComponent } from './components/Helm/InternalUser/edit-internaluser/edit-internaluser.component';
import { AllExternaluserComponent } from './components/Helm/ExternalUser/all-externaluser/all-externaluser.component';
import { NewExternaluserComponent } from './components/Helm/ExternalUser/new-externaluser/new-externaluser.component';
import { EditExternaluserComponent } from './components/Helm/ExternalUser/edit-externaluser/edit-externaluser.component';
import { AdminGuard } from './guards/admin.guard';
import { AdmSupusrUsrGuard } from './guards/adm-supusr-usr.guard';
import { AdmSupusrGuard } from './guards/adm-supusr.guard';
import { NewProductHelmComponent } from './components/Helm/Products/new-product-helm/new-product-helm.component';
import { EditProductHelmComponent } from './components/Helm/Products/edit-product-helm/edit-product-helm.component';
import { AllProductlinesComponent } from './components/Helm/ProductLines/all-productlines/all-productlines.component';
import { NewProductlineComponent } from './components/Helm/ProductLines/new-productline/new-productline.component';
import { EditProductlineComponent } from './components/Helm/ProductLines/edit-productline/edit-productline.component';
import { AllOrdersComponent } from './components/Helm/Orders/allOrders/all-orders.component';
import { AoTableComponent } from './components/Helm/Orders/allOrders/ao-table/ao-table.component';
import { AoCalendarComponent } from './components/Helm/Orders/allOrders/ao-calendar/ao-calendar.component';
import { NewOrdersComponent } from './components/Helm/Orders/newOrder/new-orders.component';
import { EditOrderComponent } from './components/Helm/Orders/editOrder/edit-order.component';
import { NoMainComponent } from './components/Helm/Orders/newOrder/no-main/no-main.component';
import { NoActivityComponent } from './components/Helm/Orders/newOrder/no-activity/no-activity.component';
import { NoCostComponent } from './components/Helm/Orders/newOrder/no-cost/no-cost.component';
import { EoMainComponent } from './components/Helm/Orders/editOrder/eo-main/eo-main.component';
import { EoActivityComponent } from './components/Helm/Orders/editOrder/eo-activity/eo-activity.component';
import { EoCostComponent } from './components/Helm/Orders/editOrder/eo-cost/eo-cost.component';
import { ViewOrderComponent } from './components/Helm/Orders/viewOrder/view-order.component';
import { VoMainComponent } from './components/Helm/Orders/viewOrder/vo-main/vo-main.component';
import { VoActivityComponent } from './components/Helm/Orders/viewOrder/vo-activity/vo-activity.component';
import { VoCostComponent } from './components/Helm/Orders/viewOrder/vo-cost/vo-cost.component';
import { ProductlinesComponent } from './components/ProductLines/productlines.component';
import { HomeTicketComponent } from './components/Activities/Ticket/home-ticket.component';
import { ActivitiesComponent } from './components/Activities/activities.component';
import { AllTicketComponent } from './components/Activities/Ticket/all-ticket/all-ticket.component';
import { HomeMarkupComponent } from './components/Activities/Markup/home-markup.component';
import { ChatComponent } from './components/Activities/Chat/chat.component';
import { TicketListComponent } from './components/Activities/Ticket/ticket-list/ticket-list.component';
import { TicketCalendarComponent } from './components/Activities/Ticket/ticket-calendar/ticket-calendar.component';
import { NewTicketComponent } from './components/Activities/Ticket/new-ticket/new-ticket.component';
import { EditTicketComponent } from './components/Activities/Ticket/edit-ticket/edit-ticket.component';
import { ViewTicketComponent } from './components/Activities/Ticket/view-ticket/view-ticket.component';
import { CdeHomeComponent } from './components/CDE/cde-home.component';
import { LinkHandlerComponent } from './components/CDE/link-handler/link-handler.component';
import { LinkLoginComponent } from './components/CDE/common/link-login/link-login.component';
import { CdeLinkHomeComponent } from './components/CDE/cde-link-home.component';
import { Viewer3DComponent } from './components/Viewer3D/viewer3-d.component';
import { ViewerSvgComponent } from './components/ViewerSvg/viewer-svg.component';
import { DrawingTicketComponent } from './components/Activities/Ticket/drawing-ticket/drawing-ticket.component';
import { ViewerThreeJSComponent } from './components/ViewerThreeJS/viewer-three-js.component';


const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [LoginGuard]},
  {path: 'login', component: LoginComponent},
  {path:'helm', redirectTo: 'helm/dashboard'},
  {path:'helm/internalusers', redirectTo: 'helm/internalusers/all'},
  {path:'helm/externalusers', redirectTo: 'helm/externalusers/all'},
  {path:'helm/orders', redirectTo: 'helm/orders/all/table'},
  {path:'helm/orders/all', redirectTo: 'helm/orders/all/table'},
  {path:'helm/orders/new', redirectTo: 'helm/orders/new/main'},
  //{path:'helm/orders/edit/:id', redirectTo: 'helm/orders/edit/main/:id'},
  {path:'helm/companies', redirectTo: 'helm/companies/all'},
  {path:'helm/products', redirectTo: 'helm/products/all'},
  {path:'helm/productlines', redirectTo: 'helm/productlines/all'},
  {path: 'helm', component: HelmComponent,
    children:[
      {path: 'dashboard', component: DashboardComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
      {path: 'internalusers', component: InternalUserComponent, children:[
        {path: 'all', component: AllInternaluserComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
        {path: 'new', component: NewInternaluserComponent, canActivate: [LoginGuard, AdminGuard]},
        {path: 'edit/:id', component: EditInternaluserComponent, canActivate: [LoginGuard, AdminGuard]}],
        canActivate: [LoginGuard]
      },
      {path: 'externalusers', component: ExternalUserComponent, children:[
        {path: 'all', component: AllExternaluserComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
        {path: 'new', component: NewExternaluserComponent, canActivate: [LoginGuard, AdminGuard]},
        {path: 'edit/:id', component: EditExternaluserComponent, canActivate: [LoginGuard, AdminGuard]}],
        canActivate: [LoginGuard]
      },
      {path: 'orders', component: ExternalUserComponent, children:[
        {path: 'all', component: AllOrdersComponent,children:[
          {path: 'table', component: AoTableComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
          {path: 'calendar', component: AoCalendarComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]}
        ], canActivate: [LoginGuard, AdmSupusrUsrGuard]},
        {path: 'new', component: NewOrdersComponent, children:[
          {path: 'main', component: NoMainComponent, canActivate: [LoginGuard, AdminGuard]},
          {path: 'activity', component: NoActivityComponent, canActivate: [LoginGuard, AdminGuard]},
          {path: 'cost', component: NoCostComponent, canActivate: [LoginGuard, AdminGuard]}
        ], canActivate: [LoginGuard, AdminGuard]},
        {path: 'edit', component: EditOrderComponent, children:[
          {path: 'main/:id', component: EoMainComponent, canActivate: [LoginGuard, AdminGuard]},
          {path: 'activity/:id', component: EoActivityComponent, canActivate: [LoginGuard, AdminGuard]},
          {path: 'cost/:id', component: EoCostComponent, canActivate: [LoginGuard, AdminGuard]}
        ],  canActivate: [LoginGuard, AdminGuard]},
        {path: 'view', component: ViewOrderComponent, children:[
          {path: 'main/:id', component: VoMainComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
          {path: 'activity/:id', component: VoActivityComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
          {path: 'cost/:id', component: VoCostComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]}
        ],  canActivate: [LoginGuard, AdmSupusrUsrGuard]}],
        canActivate: [LoginGuard]
      },
      {path: 'companies', component: CompaniesHelmComponent, children:[
        {path: 'all', component: AllCompaniesHelmComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
        {path: 'new', component: NewCompanyHelmComponent, canActivate: [LoginGuard, AdminGuard]},
        {path: 'edit/:id', component: EditCompanyHelmComponent, canActivate: [LoginGuard, AdminGuard]}],
        canActivate: [LoginGuard]
      },
      {path: 'products', component: ProductsHelmComponent, children:[
        {path: 'all', component: AllProductsHelmComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
        {path: 'new', component: NewProductHelmComponent, canActivate: [LoginGuard, AdmSupusrGuard]},
        {path: 'edit/:id', component: EditProductHelmComponent, canActivate: [LoginGuard, AdmSupusrGuard]}],
        canActivate: [LoginGuard]
      },
      {path: 'productlines', component: ProductLinesHelmComponent, children:[
        {path: 'all', component: AllProductlinesComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
        {path: 'new', component: NewProductlineComponent, canActivate: [LoginGuard, AdmSupusrGuard]},
        {path: 'edit/:id', component: EditProductlineComponent, canActivate: [LoginGuard, AdmSupusrGuard]}],
        canActivate: [LoginGuard]
      }
    ],
    canActivate: [LoginGuard]},
  {path: 'products', component: ProductsComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
  {path: 'productlines', component: ProductlinesComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
  {path:'activities', redirectTo: 'activities/ticket/all/all'},
  {path: 'activities', component: ActivitiesComponent, children:[
    {path: 'ticket/all/:prodid', component: AllTicketComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
    {path: 'ticket/list/:prodid', component: TicketListComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
    {path: 'ticket/calendar/:prodid', component: TicketCalendarComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
    {path: 'ticket/new/:prodid', component: NewTicketComponent, canActivate: [LoginGuard, AdmSupusrGuard]},
    {path: 'ticket/edit/:id', component: EditTicketComponent, canActivate: [LoginGuard, AdmSupusrGuard]},
    {path: 'ticket/view/:id', component: ViewTicketComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
    {path: 'markup/all/:prodid', component: HomeMarkupComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]},
    {path: 'chat/all/:prodid', component: ChatComponent, canActivate: [LoginGuard, AdmSupusrUsrGuard]}
  ], canActivate: [LoginGuard]},
  {path: 'cde', component: CdeHomeComponent, children:[], canActivate: [LoginGuard]},
  {path: 'link', component: CdeLinkHomeComponent, children:[], canActivate: []},
  {path: 'private', component: LinkLoginComponent, children:[], canActivate: []},
  {path: '3dviewer', component: Viewer3DComponent, children:[], canActivate: [LoginGuard]},
  {path: 'svgviewer', component: DrawingTicketComponent, children:[], canActivate: [LoginGuard]},
  {path: 'threeviewer', component: ViewerThreeJSComponent, children:[], canActivate: [LoginGuard]},
  {path:'**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
