import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivityLight } from 'src/app/models/helm/activity-light';
import { Order } from 'src/app/models/helm/order';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-eo-activity',
  templateUrl: './eo-activity.component.html',
  styleUrls: [
    './eo-activity.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class EoActivityComponent implements OnInit {

  editableOrder: Order;
  newActivityVoid: ActivityLight;
  newActivity: ActivityLight = {
    activityId: 1,
    user: {
      userId: "",
      name: "",
      surname: "",
      privilege: "",
      user_type: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        logo: "",
        type: ""
      }
    },
    start: undefined,
    end: undefined,
    days: undefined,
    type: "",
    user_type: "",
    cost: undefined
  };
  fullday: boolean;
  counter: number = 1;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  resCtrl = new FormControl();
  filteredres: Observable<AutocompObj[]>;
  selectedres: AutocompObj;

  displayedColumns = ['user', 'start', 'end', 'type', 'user_type', 'days', 'actions'];
  dataSource = new MatTableDataSource<ActivityLight>();


  constructor(private helmsrv: HelmService, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar) {
    this.editableOrder = this.ordsrv.GetOrder();
    this.dataSource.data = this.editableOrder.activity_planned;
  }

  ngOnInit(): void {
    this.LoadSettings();
    this.newActivityVoid == {...this.newActivity};
  }

  LoadSettings(){
    this.helmsrv.LoadAllUsersAuth()
      .subscribe((res) => {
        this.users = res
        for(let usr of this.users){
          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(this.editableOrder.activity_planned == undefined) this.editableOrder.activity_planned = []
          if(this.editableOrder.activity_planned.length > 0)
            this.counter = this.editableOrder.activity_planned[this.editableOrder.activity_planned.length -1].activityId + 1;
        }
        this.filteredres = this.resCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
      });

  }

  AddRes(form: NgForm){
    this.newActivity = {...form.value};
    this.newActivity.end = new Date(this.newActivity.end);
    this.newActivity.start = new Date(this.newActivity.start);
    var diff = Math.floor(this.newActivity.end.getTime() - this.newActivity.start.getTime());
    var day = 1000 * 60 * 60 * 24;
    this.newActivity.days = this.fullday ? Math.floor(diff/day) : Math.floor(diff/day)/2;
    if(this.newActivity.days > 0){
      this.newActivity.user = this.users.filter(usr => usr.userId == this.selectedres.id)[0];
      this.newActivity.activityId = this.counter;
      this.counter++;
      if(this.editableOrder.costs.cost_set != undefined && this.editableOrder.costs.cost_set.name != ""){
        switch(this.newActivity.user_type){
          case 'Commerciale':
            switch(this.newActivity.type){
              case 'Online':
                this.newActivity.cost = this.editableOrder.costs.cost_set.onlineC * this.newActivity.days;
                break;
              case 'Remote':
                this.newActivity.cost = this.editableOrder.costs.cost_set.remoteC * this.newActivity.days;
                break;
              case 'Onsite':
                this.newActivity.cost = this.editableOrder.costs.cost_set.onsiteC * this.newActivity.days;
                break;
            }
            break;
          case 'Tecnico':
            switch(this.newActivity.type){
              case 'Online':
                this.newActivity.cost = this.editableOrder.costs.cost_set.onlineT * this.newActivity.days;
                break;
              case 'Remote':
                this.newActivity.cost = this.editableOrder.costs.cost_set.remoteT * this.newActivity.days;
                break;
              case 'Onsite':
                this.newActivity.cost = this.editableOrder.costs.cost_set.onsiteT * this.newActivity.days;
                break;
            }
          break;
        }
      }
      this.editableOrder.activity_planned.push(this.newActivity);
      this.dataSource.data = this.editableOrder.activity_planned;
      console.log(this.editableOrder);
      this.newActivity = {...this.newActivityVoid}
      this.newActivity.start = new Date();
      this.newActivity.end = new Date();
      this.resCtrl.setValue('');
    }
    else{
      this.snackBar.open("La data di fine attività non può essere precedente a quella di inizio", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
  }



  navigate(form: NgForm, area: string){
    this.ordsrv.SetOrder(this.editableOrder);
    this.router.navigateByUrl(`/helm/orders/edit/${area}/${this.editableOrder.orderId}`);
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }

}
