<div class="upload-table">
    <table class="table">
        <thead>
            <tr>
                <th>Name</th>
            </tr>
        </thead>
        <tbody class="upload-name-style">
            <tr *ngFor="let item of files; let i=index">
                <td>File <strong>{{item.fileEntry.name }}</strong> - Path : {{ item.relativePath }}</td>
            </tr>
        </tbody>
    </table>
    <button mat-raised-button color="accent" mat-dialog-close class="confbtn menubtn">Chiudi</button>
</div>
