
<mat-grid-list class="usrmenu" [cols]=3 rowHeight="120px">
  <mat-grid-tile>
    <img [src]="usrimg" class="profile-circle2"/>
  </mat-grid-tile>
  <mat-grid-tile [colspan]= 2>
    <div class="internalMatGrid">
      <mat-grid-list [cols]=1 rowHeight="40px">
        <mat-grid-tile><a class="black-text usrmenuname">{{name}}</a></mat-grid-tile>
        <mat-grid-tile><a class="grey-text usrmenuusername">{{username}}</a></mat-grid-tile>
        <mat-grid-tile><a mat-raised-button color="accent" routerLink="profile" class="mfgColor white-text usraccount">Account</a></mat-grid-tile>
      </mat-grid-list>
    </div>
  </mat-grid-tile>
</mat-grid-list>
<div class="logoutarea ">
  <a mat-raised-button routerLink="login" color="primary" (click)="logout()" class="logoutbtn" matTooltip="Logout" matTooltipPosition="below">
    <i class="material-icons mdc-text lighten-2">power_settings_new</i></a>
</div>
