<form action="/login" #f="ngForm" (submit)=sendLogin(f)>
  <mat-form-field appearance="outline" class="inputField">
    <input matInput id="email" type="text" placeholder="Email" name="bim" [ngModel]="bim" #inputEmailRef="ngModel" required>
  </mat-form-field>
  <br>
  <mat-form-field appearance="outline" class="inputField">
    <input matInput id="pass" type="password" placeholder="Password" name="pwd" [ngModel]="pwd" #inputPassRef="ngModel" required>
  </mat-form-field>
  <input type="hidden" name="lat" [value]="lat"/>
  <input type="hidden" name="lon" [value]="lon"/>
  <div class="center-horizzontal" style="cursor: pointer;">
    <a (click)="newPassword()" class="index color-logo underline">Forgot password?</a>
  </div>
  <br>
  <div class="center-horizzontal">
    <button mat-raised-button color="primary" class="login-btn" type="submit" [disabled]="f.invalid">LOGIN</button>
  </div>
</form>
