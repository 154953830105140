import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-productlines',
  templateUrl: './productlines.component.html',
  styleUrls: [
    './productlines.component.scss',
    '../../app.component.scss'
  ]
})
export class ProductlinesComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';
  selectedProdlineId: string;

  constructor() { }

  ngOnInit(): void {
    this.selectedProdlineId = localStorage.getItem('selectedProdline');
  }

  SelProdline(prodlineid:string){
    this.selectedProdlineId = prodlineid;
  }
}
