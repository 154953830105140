import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-light',
  templateUrl: './navbar-light.component.html',
  styleUrls: ['./navbar-light.component.scss']
})
export class NavbarLightComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';

  constructor() { }

  ngOnInit(): void {
  }

}
