import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ActivityLight } from 'src/app/models/helm/activity-light';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-vo-activity',
  templateUrl: './vo-activity.component.html',
  styleUrls: [
    './vo-activity.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class VoActivityComponent implements OnInit {

  loaded: boolean = false;
  viewOrder: Order;

  displayedColumns = ['user', 'start', 'end', 'type', 'user_type', 'days'];
  dataSource = new MatTableDataSource<ActivityLight>();

  constructor(private ordsrv: OrderService, private activatedRoute: ActivatedRoute) {
    this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
      .subscribe(ord => {
        this.viewOrder = ord;
        this.dataSource.data = ord.activity_planned;
        this.loaded = true;
      })
  }

  ngOnInit(): void {
  }

}
