import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-vo-main',
  templateUrl: './vo-main.component.html',
  styleUrls: [
    './vo-main.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class VoMainComponent implements OnInit {

  loaded: boolean = false;
  viewOrder: Order;

  date_offer: string;
  date_delivery: string;
  owner: string;

  constructor(private ordsrv: OrderService, private activatedRoute: ActivatedRoute) {
    this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
      .subscribe(ord => {
        this.viewOrder = ord;
        this.date_offer = this.viewOrder.date_offer.toLocaleDateString();
        this.date_delivery = this.viewOrder.date_delivery.toLocaleDateString();
        this.owner = `${this.viewOrder.manager.surname} ${this.viewOrder.manager.name}`
        this.loaded = true;
      })
   }

  ngOnInit(): void {
  }

}
