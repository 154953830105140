<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Edita utente interno</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="SaveUser(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/internalusers" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=5 rowHeight="550px">
          <mat-grid-tile>
            <div class="col-int">
              <button type="button" id="loadFileBtn">
                <input type="file" id="loadFileInput" hidden (change)="readUrl($event)" accept=".png,.jpg"/>
                <label for="loadFileInput"><img id="usrimg" [src]="editableUser.image"></label>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="editableUser.name" readonly required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Cognome</mat-label>
                <input matInput type="text" name="surname" [ngModel]="editableUser.surname" readonly required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Email</mat-label>
                <input matInput type="text" name="email" [ngModel]="editableUser.email" required
                  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Telefono</mat-label>
                <input matInput type="text" name="phone" [ngModel]="editableUser.phone">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Azienda</mat-label>
                <mat-select name="company" [ngModel]="editableUser.company">
                  <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Autorizzazione</mat-label>
                <mat-select name="privilege" [ngModel]="editableUser.privilege" required>
                  <mat-option value="superuser">User</mat-option>
                  <mat-option value="admin">Admin</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="row" id="role-container">
                <mat-form-field id="role">
                  <mat-label>Ruolo</mat-label>
                  <mat-select name="role" [ngModel]="editableUser.role" >
                    <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-fab class="utilbtn" type="button" (click)="addNewRole()"  matTooltip="Nuovo ruolo"  matTooltipPosition="below">
                  <i class="material-icons">add</i></button>
              </div>
              <mat-form-field class="row">
                <mat-label>Nuova Password</mat-label>
                <input matInput type="password" name="password" [ngModel]="editableUser.password">
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
