import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Company } from 'src/app/models/helm/company';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-company-helm',
  templateUrl: './new-company-helm.component.html',
  styleUrls: [
    './new-company-helm.component.scss',
    '../../../../app.component.scss'
  ]
})
export class NewCompanyHelmComponent implements OnInit {

  newCompany: Company = {
    companyId: "",
    logo: environment.imageUrl + "/defaultcompany.png",
    name: "",
    address: "",
    email: "",
    phone: "",
    type: "",
    website: "",
    internalname: "",
    internalemail: "",
    internalphone: "",
    repository: false
  }

  newImg: string = "";

  constructor(private helmsrv: HelmService, private router: Router) { }

  ngOnInit(): void {
  }

  AddNewCompany(form: NgForm){
    this.newCompany = {...form.value};
    if(this.newImg != "") this.newCompany.logo = this.newImg;
    if(this.newCompany.logo == environment.imageUrl + "/defaultcompany.png" || this.newCompany.logo == undefined) this.newCompany.logo = "";
    this.helmsrv.AddNewCompany(this.newCompany)
      .subscribe(res => {
        console.log(res);
        this.router.navigateByUrl('/helm/companies')},
      (error) => {
        console.log("Errore inserimento nuova azienda", error);
      });
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.newCompany.logo = this.newImg;
      };
    }
  }
}
