<div id="markup-container">
  <form #fm="ngForm" (submit)="saveMarkup()" >
  <mat-toolbar style="background-color:white">
    <mat-toolbar-row style="justify-content: space-between;">
      <div class="toolbar-btn">
        <button mat-icon-button [color]="drawingType == 'selection'? 'warning' : 'primary'" (click)="setDrawingMode('selection')" type="button"
          matTooltip="Selezione"><i class="material-icons">highlight_alt</i></button>
        <mat-divider [vertical]="true"></mat-divider>
        <button mat-icon-button [color]="drawingType == 'pen'? 'warning' : 'primary'" (click)="setDrawingMode('pen')" type="button"
          matTooltip="Penna"><i class="material-icons">mode_edit</i></button>
        <button mat-icon-button [color]="drawingType == 'rectangle'? 'warning' : 'primary'" (click)="setDrawingMode('rectangle')" type="button"
          matTooltip="Rettangolo"><i class="material-icons">rectangle</i></button>
        <button mat-icon-button [color]="drawingType == 'circle'? 'warning' : 'primary'" (click)="setDrawingMode('circle')" type="button"
          matTooltip="Cerchio"><i class="material-icons">circle</i></button>
        <button mat-icon-button [color]="drawingType == 'line'? 'warning' : 'primary'" (click)="setDrawingMode('line')" type="button"
          matTooltip="Linea"><i class="material-icons">show_chart</i></button>
        <mat-divider [vertical]="true" ></mat-divider>
        <button mat-icon-button [color]="drawingType == 'text'? 'warning' : 'primary'" (click)="setDrawingMode('text')" type="button"
          matTooltip="Testo"><i class="material-icons">title</i></button>
          <mat-divider *ngIf="canDelete" [vertical]="true" ></mat-divider>
        <button mat-icon-button *ngIf="canDelete" (click)="deleteObjs()" color="primary" type="button"
          matTooltip="Cancella oggetti selezionati"><i class="material-icons">delete</i></button>
      </div>
      <div  class="toolbar-btn">
        <button mat-icon-button color="primary" matTooltip="Reset" (click)="refresh()" type="button"><i class="material-icons">refresh</i></button>
        <button mat-icon-button color="primary" matTooltip="Salva markup" type="submit"><i class="material-icons">save</i></button>
        <button mat-icon-button color="primary" matTooltip="Torna al visualizzatore" (click)="closeMarkup()" type="button">
          <i class="material-icons">close</i></button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  </form>

  <canvas id="fabricSurface"></canvas>

  <div *ngIf="drawingType != 'selection'" class="draggable-box" cdkDragBoundary="#markup-container" cdkDrag>
    <div id="drawing-settings">
      <div id="spessore">
        <mat-slider matTooltip="Spessore penna" color="primary" id="penWidth" [(ngModel)]="penWidth" thumbLabel tickInterval="1" step="1" min="1" max="40" ></mat-slider>
        <label for="penWidth">Spessore tratto</label>
      </div>
      <div>
        <mat-form-field  id="color-picker" appearance="standard">
          <mat-label>Colore</mat-label>
          <input matInput [ngxMatColorPicker]="picker" name="color" [(ngModel)]="color"  >
          <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #picker [touchUi]="false" color="primary"></ngx-mat-color-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="draggable-handle" cdkDragHandle>
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>
  </div>
</div>
