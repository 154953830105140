import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdeUser } from 'src/app/models/cde/cde-user';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { CdeSnackBarComponent } from '../../cde-table/cde-table.component';

@Component({
  selector: 'app-share-with-dialog',
  templateUrl: './share-with-dialog.component.html',
  styleUrls: ['./share-with-dialog.component.scss']
})
export class ShareWithDialogComponent implements OnInit {

  @Input() message: string = "";
  @Output() confirm = new EventEmitter();
  title: string;
  buttonLabel: string;

  selectedUser: CdeUser;
  users: CdeUser[];

  constructor(public dialogRef: MatDialogRef<ShareWithDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdeServices: CdeServiceService,
    private _snackBar: MatSnackBar) {
      if (this.data.share) {
        this.title = "Condividi con ...";
        this.buttonLabel = "Condividi"
      }
      else{
         this.title = "Elimina condivisione con ..."
         this.buttonLabel = "Elimina"
      }
    }

  ngOnInit(): void {
    this.cdeServices
      .getUsers("appuser", "all")
        .subscribe(res => {
          this.users = res.users;
          this.users.map(item => {
            let trovato = false;
            for(let it of this.data.cloud.members){
              if (it.user_id == item.id && it.access == "s"){
                item.color = "lightgreen"
                trovato = true;
              }
            }
            if (!trovato)  item.color = "trasparent"
          })
          console.log(this.users)
        })
  }

  onSiClick(): void {
    if (this.data.share) {
      this.cdeServices
      .shareItem(this.data.cloud, this.selectedUser.id)
        .subscribe(res => {
          this._snackBar.openFromComponent(CdeSnackBarComponent, {
            duration: 2 * 1000,
            data: res.message + " con " + this.selectedUser.name + " " + this.selectedUser.surname
          });
          this.confirm.emit("SI");
          this.dialogRef.close();
        })
    }else{
      this.cdeServices
        .unshareItem(this.data.cloud, this.selectedUser.id)
          .subscribe(res => {
          this._snackBar.openFromComponent(CdeSnackBarComponent, {
            duration: 2 * 1000,
            data: res.message + " con " + this.selectedUser.name + " " + this.selectedUser.surname
          });
          this.confirm.emit("SI");
          this.dialogRef.close();
        })
    }
  }

}
