import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';
import { CostSetDialogComponent } from '../../shared/cost-set-dialog/cost-set-dialog.component';

@Component({
  selector: 'app-vo-cost',
  templateUrl: './vo-cost.component.html',
  styleUrls: [
    './vo-cost.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class VoCostComponent implements OnInit {

  loaded: boolean = false;
  viewOrder: Order;

  date_offer: string;
  date_delivery: string;
  date_acquisition: string;

  constructor(private ordsrv: OrderService, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
      .subscribe(ord => {
        this.viewOrder = ord;
        this.date_offer = this.viewOrder.date_offer.toLocaleDateString();
        this.date_delivery = this.viewOrder.date_delivery.toLocaleDateString();
        this.date_acquisition = (new Date(this.viewOrder.costs.date_acquisition)).toLocaleDateString();
        this.loaded = true;
      })
  }

  ngOnInit(): void {
  }

  openDialog(dialogtype: string): void {
    const dialogRef = this.dialog.open(CostSetDialogComponent, {
      width: '600px'
    });
    dialogRef.componentInstance.costname = this.viewOrder.costs.cost_set.name;
    dialogRef.componentInstance.dialogtype = dialogtype;
  }

}
