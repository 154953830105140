<form class="example-form">
    <div class="full-width no-margin">
        <h2 class="col">Storicizzazione</h2>
        <mat-dialog-actions class="col right no-padding" align="top">
            <button mat-raised-button color="primary" mat-dialog-close class="confbtn menubtn">Chiudi</button>
        </mat-dialog-actions>
        <button mat-raised-button color="warn" (click)="purgeVersions()" class="col right">Purge <mat-icon>delete</mat-icon></button>
        <div class="col right reduct custom-input" appearance="fill">
            <label class="custom-label">No. Versioni</label>
            <input matInput [(ngModel)]="versionNumber" name="purge" type="number"/>
        </div>
    </div>
    <div class="full-width">
        <div class="full-width" *ngFor="let rev of meta">
            <h4>Revisione : {{rev.rev}}</h4>
            <table class="full-width"  mat-table [dataSource]="rev.version" >
                <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                <!-- Version Column -->
                <ng-container matColumnDef="Vers">
                    <th mat-header-cell *matHeaderCellDef> Versione </th>
                    <td mat-cell *matCellDef="let element"> {{element.ver}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="Nome">
                    <th mat-header-cell *matHeaderCellDef> Nome </th>
                    <td mat-cell *matCellDef="let element"> {{element.path}} </td>
                </ng-container>
                
                <!-- Date Column -->
                <ng-container matColumnDef="Data Caricamento">
                    <th mat-header-cell *matHeaderCellDef> Data Caricamento </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date:'MMM d, y, h:mm' }} </td>
                </ng-container>
                
                <!-- Symbol Column -->
                <ng-container matColumnDef="Aggiornato da">
                    <th mat-header-cell *matHeaderCellDef> Aggiornato da </th>
                    <td mat-cell *matCellDef="let element"> {{element.updateBy}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="Dimesione">
                    <th mat-header-cell *matHeaderCellDef> Dimesione </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="(element.dimension/1024) > 0 && (element.dimension/1024) <= 1024 "> {{ element.dimension/1024 | number: '1.2-2' }} KB </span>
                        <span *ngIf="(element.dimension/(1024*1024)) > 1.1  && (element.dimension/1024) <= 1000000"> {{element.dimension/(1024*1024) | number: '1.2-2' }} MB </span>
                        <span *ngIf="(element.dimension/(1024*1024*1024)) > 1.1 && (element.dimension/1024) <= 1000000000"> {{ element.dimension/(1024*1024*1024) | number: '1.2-2' }} GB  </span> 
                    </td>
                </ng-container>

                    <!-- Symbol Column -->
                <ng-container matColumnDef="Azioni">
                    <th mat-header-cell *matHeaderCellDef> Azioni </th>
                    <td mat-cell *matCellDef="let element"> 
                        <a class="cursor" (click)="downlaodVersion(element)"><mat-icon>file_download</mat-icon></a>
                        <a class="cursor" (click)="setRevision(element, rev.rev)"><mat-icon>open_in_new</mat-icon></a>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
        </div>
    </div>
    <div class="full-width" *ngIf="viewNewRevision == true">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Nome Revisione </mat-label>
            <input matInput [(ngModel)]="info.name" name="revisione">
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>File Sezionato</mat-label>
            <input matInput [(ngModel)]="fileSelected" name="link" readonly>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="createRevision()" class="col right">Salva <mat-icon>save</mat-icon></button>
    </div>
</form>



