<div class="container-fluid">
  <div id='online-messages'>
    <div>{{messageArray}}
      <div *ngFor="let item of messageArray">
        <span><strong>{{item.user}} : </strong> {{item.message}}</span>
      </div>
    </div>
  </div>
  <div id="mymessage">
    <mat-form-field>
      <input matInput placeholder="Nuovo messaggio" [(ngModel)]="messageText" (keydown.enter)="sendMessage()">
    </mat-form-field>
  </div>
</div>
