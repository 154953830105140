
<app-video-player *ngIf="videoLink" [src]="videoLink"></app-video-player>
<ngx-doc-viewer *ngIf="docUrl" [url]="docUrl" viewer="office" style="width:100%;height:600px;"></ngx-doc-viewer>
<app-viewer3-d *ngIf="viewer" [item]="data.cloud"></app-viewer3-d>
<ngx-extended-pdf-viewer *ngIf="blob && data.cloud.contentType == 'application/pdf'" [src]="blob" [useBrowserLocale]="true"  [textLayer]="true" [showHandToolButton]="true" height="90%" width="100%"></ngx-extended-pdf-viewer>
<img *ngIf="safeUrl && data.cloud.contentType.includes('image')" [src]="safeUrl" height="500px" style="display: block; margin-left: auto; margin-right: auto;"/>
<mat-dialog-actions align="end">
    <button mat-raised-button color="accent" *ngIf="!videoLink && !nameDocLink" mat-dialog-close class="confbtn menubtn">Chiudi</button>
    <button mat-raised-button color="accent" *ngIf="videoLink && !nameDocLink" (click)="stopVideo()" mat-dialog-close class="confbtn menubtn">Chiudi</button>
    <button mat-raised-button color="accent" *ngIf="nameDocLink && !videoLink" (click)="deleteDocPreview()" mat-dialog-close class="confbtn menubtn">Chiudi</button>
</mat-dialog-actions>
