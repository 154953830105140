import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss']
})
export class TextInputDialogComponent implements OnInit {

  @Input() title:string = "";
  textInput:string = "";
  @Output() confirm = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<TextInputDialogComponent>) { }

  ngOnInit(): void {
  }

  onSiClick(): void {
    this.confirm.emit(["SI", this.textInput]);
    this.dialogRef.close();
  }
}
