import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Markup } from 'src/app/models/activity/markup';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-home-markup',
  templateUrl: './home-markup.component.html',
  styleUrls: ['./home-markup.component.scss']
})
export class HomeMarkupComponent implements OnInit {

  priv: string = localStorage.getItem('priv');
  loaded: boolean = false;
  selprodId: string;
  markups: Markup[] = [];
  selMarkup: Markup;

  constructor(private actsrv: ActivityService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.selprodId = routeParams.prodid;
      console.log(this.selprodId);
      this.LoadMarkups();
    })

  }

  LoadMarkups(){
    this.actsrv.LoadMarkup(this.selprodId)
      .subscribe(res => {
        this.markups = res;
        this.loaded = true;
      })
  }

  SelectMarkup(mk: Markup){
    this.selMarkup = mk;
  }

  UnselectMarkup(){
    this.selMarkup = undefined;
  }

  DeleteMarkup(){
    this.actsrv.DeleteMarkup(this.selMarkup.code)
      .subscribe(res => {
        console.log(res);
        this.selMarkup = undefined;
        this.LoadMarkups();
      })
  }
}
