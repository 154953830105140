import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ticket } from 'src/app/models/activity/ticket';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  selprodId: string;
  allTickets: Ticket[] = [];

  constructor(private actsrv: ActivityService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.selprodId = routeParams.prodid;
      console.log(this.selprodId);
      this.LoadTicket();
    })
  }

  LoadTicket(){
    this.actsrv.LoadAllTickets()
      .subscribe(res => {
        this.allTickets = res;
      })
  }

}
