import { Component, OnInit, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { FabricService } from 'src/app/services/fabric.service';
import { fabric } from 'fabric';
import { POINT } from 'src/app/models/shared/point';
import { NgForm } from '@angular/forms';
import { Color } from '@angular-material-components/color-picker';
import { MatDialog } from '@angular/material/dialog';
import { TextInputDialogComponent } from '../text-input-dialog/text-input-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TextInputBottomComponent } from '../text-input-bottom/text-input-bottom.component';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-markup-draw',
  templateUrl: './markup-draw.component.html',
  styleUrls: ['./markup-draw.component.scss']
})
export class MarkupDrawComponent implements OnInit {

  canvas?: fabric.Canvas;
  points: POINT[] = [];
  counter: number = 0;
  drawingType: string = 'selection';
  selectedFontSize: number = 20;
  penWidth: number = 2;
  textToAdd: string = '';
  canDelete:boolean = false;
  mkName: string;

  color: Color;

  @Input() background: string;
  @Input() modelName: string;
  @Input() prodcode: string;
  @Output() exit = new EventEmitter();

  protected _mouseDown: (evt: fabric.IEvent) => void;
  protected _mouseUp: (evt: fabric.IEvent) => void;
  protected _mouseMove: (evt: fabric.IEvent) => void;

  constructor(private fabricService: FabricService, private zone: NgZone, private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet, private sharedsrv: SharedService) {
    this.sharedsrv.change.subscribe(value => {
      if (value) {
        console.log(value);
        if(value[0] === 'OK')
          this.textToAdd = value[1];
      }
    });
  }

  ngOnInit(): void {
      this.zone.runOutsideAngular( () => {
        this.canvas = new fabric.Canvas('fabricSurface', {
          backgroundColor: '#ebebef',
          selection: false,
          preserveObjectStacking: true,
          width: 1000,
          height: 585
        });

        this.fabricService.canvas = this.canvas;
        this.fabricService.setCanvasBackground(this.background);

        this._mouseDown = (evt: fabric.IEvent) => this.__onMouseDown(evt);
        this._mouseUp = (evt: fabric.IEvent) => this.__onMouseUp(evt);
        this._mouseMove = (evt: fabric.IEvent) => this.__mouseMove(evt);
        this.canvas.on('mouse:down', this._mouseDown);
        this.canvas.on('mouse:up', this._mouseUp);
        this.canvas.on('mouse:move', this._mouseMove);

        this.color = new Color(0,0,0,1);

      });
  }

  public __onMouseUp(evt: fabric.IEvent): void{
    this.zone.run(() => {
      if (evt.pointer){
        const p: POINT = {x: evt.pointer.x, y: evt.pointer.y};
        switch(this.drawingType){
          case 'selection':
            if(this.canvas.getActiveObjects().length > 0){
              this.canDelete = true;
            }
            else{
              this.canDelete = false;
            }
            break;

          case 'pen':

            break;
          case 'circle':

            break;
          case 'line':

            break;
        }
      }
    })

  }

  public __onMouseDown(evt: fabric.IEvent): void{

    if (evt.pointer){
      const p: POINT = {x: evt.pointer.x, y: evt.pointer.y};
      switch(this.drawingType){
        case 'selection':
          break;
        case 'pen':

          break;
        case 'circle':
          if(p.y == 0) break;
          this.points.push(p);
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          if(this.points.length == 2){
            this.fabricService.addCircle(`cr${this.counter}`, this.points, true);
            this.counter++;
            this.points=[];
          }
          break;
        case 'rectangle':
          if(p.y == 0) break;
          this.points.push(p);
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          if(this.points.length == 2){
            this.fabricService.addRectangle(`rt${this.counter}`, this.points, true);
            this.counter++;
            this.points=[];
          }
          break;
        case 'line':
          if(p.y == 0) break;
          this.points.push(p);
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          if(this.points.length == 2){
            this.fabricService.addPolyline(`pl${this.counter}`, this.points, true);
            this.counter++;
            this.points.splice(0, 1);
          }
          break;
        case 'text':
          if(this.textToAdd != ''){
            this.fabricService.addText(this.textToAdd, p, this.selectedFontSize);
            this.textToAdd = '';
            this.setDrawingMode('selection');
          }
          break;
      }
    }
  }

  public __mouseMove(evt: fabric.IEvent): void{
    if (evt.pointer){
      const p: POINT = {x: evt.pointer.x, y: evt.pointer.y};
      switch(this.drawingType){
        case 'selection':

          break;
        case 'pen':
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          break;
        case 'circle':
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          if(this.points.length == 0) break;
          this.points.push(p);
          if(this.points.length == 2){
            this.fabricService.addCircle(`cr${this.counter}`, this.points, true);
            this.points.splice(1, 1);
          }
          break;
        case 'rectangle':
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          if(this.points.length == 0) break;
          this.points.push(p);
          if(this.points.length == 2){
            this.fabricService.addRectangle(`rt${this.counter}`, this.points, true);
            this.points.splice(1, 1);
          }
        break;
        case 'line':
          this.fabricService.setPenWidth(this.penWidth);
          this.fabricService.setColour(this.color.hex);
          if(this.points.length == 0) break;
          this.points.push(p);
          if(this.points.length == 2){
            this.fabricService.addPolyline(`pl${this.counter}`, this.points, true);
            this.points.splice(1, 1);
          }
          break;
      }
    }
  }

  public setDrawingMode(mode: string){
    this.zone.run( () => {
      this.drawingType = mode;
      this.points = [];
      this.fabricService.denySelect();
      this.canvas.isDrawingMode = false;
      this.fabricService.removeSelObj(`pl${this.counter}`, 'polyline');
      this.fabricService.removeSelObj(`rt${this.counter}`, 'rectangle');
      this.fabricService.setPenWidth(this.penWidth);
      this.fabricService.setColour(this.color.hex);
      switch(this.drawingType){
        case 'selection':
          this.fabricService.allowSelect();
          break;
        case 'pen':
          this.canvas.isDrawingMode = true;
          break;
        case 'circle':
          break;
        case 'rectangle':
          break;
        case 'line':
          break;
        case 'text':
          this.setTextToAdd();
          //this.viewTextArea = true;
          break;
      }
    })

  }

  public setTextToAdd(){
    this._bottomSheet.open(TextInputBottomComponent);
  }

  public refresh(){
    this.points = [];
    this.fabricService.refreshCanvas();
  }

  public deleteObjs(){
    this.fabricService.deleteObjects();
  }

  public saveMarkup(){
    const dialogRef = this.dialog.open(TextInputDialogComponent, {
      width: '400px',
      height: '220px'
    });

    dialogRef.componentInstance.title = "Titolo Markup";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res[0] == "SI"){
        console.log(res[1]);
        this.fabricService.saveMarkup(res[1], this.prodcode, this.modelName)
          .subscribe(res => {
            console.log(res);
            this.exit.emit('Close');
          });

      }
    })
  }

  public closeMarkup(){
    this.exit.emit('Close');
  }
}
