import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/models/shared/product';
import { HelmService } from 'src/app/services/helm.service';
import { InstantChatService } from 'src/app/services/instant-chat.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';
  loaded: boolean = false;
  chatenabled: boolean = false;
  selprodId: string;
  selProd: Product;

  user: String;
  room: String;

  constructor(private helmsrv: HelmService, private activatedRoute: ActivatedRoute, private instantChatservice: InstantChatService) { this.loaded = false; }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(routeParams => {
      this.chatenabled = false;
      this.selprodId = routeParams.prodid;
      if(routeParams.prodid != 'all'){
        this.helmsrv.LoadSelectedProduct(routeParams.prodid)
        .subscribe(res => {

          this.selProd = res;
          this.loaded = true;

        })
      }
      else this.loaded = true;
    })
  }

  JoinChat(){

  }

}
