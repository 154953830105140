import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';

@Component({
  selector: 'app-delete-permanently-dialog',
  templateUrl: './delete-permanently-dialog.component.html',
  styleUrls: ['./delete-permanently-dialog.component.scss']
})
export class DeletePermanentlyDialogComponent implements OnInit {

  @Output() confirm = new EventEmitter<CloudItem[]>();

  constructor(public dialogRef: MatDialogRef<DeletePermanentlyDialogComponent>,
    private cdeservices: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  onSiClick(): void {
    this.cdeservices
      .deleteMultipleItemPermanently(this.data.items, this.data.path)
        .subscribe(res => {
          this.confirm.emit(res);
          this.dialogRef.close();
        })
    
  }

}
