import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivityLight } from 'src/app/models/helm/activity-light';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { OrderProd } from 'src/app/models/helm/order-prod';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import { MY_FORMATS } from 'src/app/models/shared/datepickerformat';

const moment =  _moment;

@Component({
  selector: 'app-eo-main',
  templateUrl: './eo-main.component.html',
  styleUrls: [
    './eo-main.component.scss',
    '../../../../../app.component.scss'
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class EoMainComponent implements OnInit {
  loaded: boolean = false;
  date_offer = new FormControl(moment());
  date_delivery = new FormControl(moment());

  editableOrder?: Order;
  activitySet: ActivityLight[];
  costsSet: OrderCost;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  products: OrderProd[]= [];
  prodsForSel: AutocompObj[] = [];
  prodCtrl = new FormControl();
  filteredprod: Observable<AutocompObj[]>;
  selectedprod: AutocompObj;

  companies: CompanyLight[]= [];
  companiesForSel: AutocompObj[] = [];
  companiesCtrl = new FormControl();
  filteredcompanies: Observable<AutocompObj[]>;
  selectedcompany: AutocompObj;


  constructor(private helmsrv: HelmService, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
    this.editableOrder = this.ordsrv.GetOrder();
    if(this.editableOrder != undefined){
      this.editableOrder = this.ordsrv.GetOrder();
      this.activitySet = this.editableOrder.activity_planned;
        this.costsSet = this.editableOrder.costs;
        this.LoadSettings();
    }
    else{
      this.ordsrv.LoadSelectedOrder(this.activatedRoute.snapshot.params.id)
      .subscribe(ord => {
        this.ordsrv.SetOrder(ord);
        this.editableOrder = ord;
        this.activitySet = ord.activity_planned;
        this.costsSet = ord.costs;
        this.LoadSettings();
      })
    }
   }

  ngOnInit(): void {

  }

  LoadSettings(){
    this.helmsrv.LoadAllUsersAuth()
      .subscribe((res) => {
        this.users = res
        for(let usr of this.users){
          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(this.editableOrder.manager.userId == "" && usr.userId == localStorage.getItem('id'))
            this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
        }
        this.filteredusrsowner = this.ownerCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.ordsrv.LoadOrderProds()
          .subscribe(res => {
            this.products = res;
            for(let prod of this.products){
              this.prodsForSel.push({id: prod.id, text: prod.code});
            }
            this.filteredprod = this.prodCtrl.valueChanges
            .pipe(
              startWith(''),
              map(prod => this._filterProd(prod))
            );
            this.helmsrv.LoadAllCompaniesLight()
            .subscribe(res => {
              this.companies = res;
              for(let comp of this.companies){
                this.companiesForSel.push({id: comp.companyId, text: comp.name});
              }
              this.filteredcompanies = this.companiesCtrl.valueChanges
              .pipe(
                startWith(''),
                map(comp => this._filterComp(comp))
              );
              this.loaded = true;
            })
          })
      });
      if(this.selectedowner == undefined && this.editableOrder.manager.userId != undefined)
        this.selectedowner = {id: this.editableOrder.manager.userId, text: `${this.editableOrder.manager.surname} ${this.editableOrder.manager.name}  - ${this.editableOrder.manager.company.name}`};
      if(this.selectedprod == undefined && this.editableOrder.product != undefined)
        this.selectedprod = {id: this.editableOrder.product.id, text: this.editableOrder.product.code};
      if(this.selectedcompany == undefined && this.editableOrder.client != undefined)
        this.selectedcompany = {id: this.editableOrder.client.companyId, text: this.editableOrder.client.name};
  }


  navigate(form: NgForm, area: string){
    let orderId = this.editableOrder.orderId;
    let doff = this.editableOrder.date_offer;
    let ddel = this.editableOrder.date_delivery;
    this.editableOrder = {...form.value};
    this.editableOrder.orderId = orderId;
    this.editableOrder.date_offer = doff;
    this.editableOrder.date_delivery = ddel;
    if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
      this.editableOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
    if(this.selectedprod != undefined && typeof(this.selectedprod) == 'object')
      this.editableOrder.product = this.products.filter(prod => prod.id == this.selectedprod.id)[0];
    if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
      this.editableOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
    this.editableOrder.activity_planned = this.activitySet;
    if(this.costsSet.cost_set == undefined) this.costsSet.cost_set = {name:'', onlineC: 0, onsiteC:0 ,remoteC: 0, onlineT: 0, onsiteT:0, remoteT:0};
    this.editableOrder.costs = this.costsSet;
    this.ordsrv.SetOrder(this.editableOrder);
    this.router.navigateByUrl(`/helm/orders/edit/${area}/${this.editableOrder.orderId}`);
  }

  AddNewOrder(form: NgForm){
    if(typeof(this.selectedowner) != 'object'){
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else if(typeof(this.selectedprod) != 'object'){
      this.snackBar.open("Selezionare un prodotto o una linea di prodotti", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else{
      let id = this.editableOrder.orderId;
      this.editableOrder = {...form.value};
      this.editableOrder.orderId = id;
      if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
        this.editableOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
      if(this.selectedprod != undefined && typeof(this.selectedprod) == 'object')
        this.editableOrder.product = this.products.filter(prod => prod.id == this.selectedprod.id)[0];
      if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
        this.editableOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
      this.editableOrder.activity_planned = this.activitySet;
      this.editableOrder.costs = this.costsSet;
      this.ordsrv.SetOrder(this.editableOrder);
      this.ordsrv.UpdateOrder()
        .subscribe(res => {
          console.log(res);
          this.router.navigateByUrl(`/helm/orders`);
        })
    }
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterProd(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.prodsForSel.filter(prod => prod.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterComp(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.companiesForSel.filter(comp => comp.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }
}
