<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Assegnazione risorse</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'cost')" matTooltip="Tempi e Costi" matTooltipPosition="below" type="submit">
          <i class="material-icons">euro</i></button>
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'main')" matTooltip="Torna all'anagrafica" matTooltipPosition="below" type="button">
          <i class="material-icons">arrow_back</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=9  rowHeight="100px">
          <mat-grid-tile [colspan]=3>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Risorsa</mat-label>
                <input matInput type="text" name="res" [(ngModel)]="selectedres"  required [matAutocomplete]="auto" [formControl]="resCtrl">
                <mat-autocomplete  #auto="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let res of filteredres | async" [value]="res">{{res.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data inizio</mat-label>
                <input matInput type="date" name="start" [ngModel]="newActivity.start" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile >
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data fine</mat-label>
                <input matInput type="date" name="end" [ngModel]="newActivity.end" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Luogo</mat-label>
                <mat-select name="type" [ngModel]="newActivity.type" required>
                  <mat-option value="Onsite">Onsite</mat-option>
                  <mat-option value="Online">Online</mat-option>
                  <mat-option value="Remote">Remote</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Durata</mat-label>
                <mat-select name="fullday" [(ngModel)]="fullday" required>
                  <mat-option [value]="true">Giornata Intera</mat-option>
                  <mat-option [value]="false">Mezza giornata</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Tipo risorsa</mat-label>
                <mat-select name="user_type" [ngModel]="newActivity.user_type" required>
                  <mat-option value="Tecnico">Tecnico</mat-option>
                  <mat-option value="Commerciale">Commerciale</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <button mat-fab class="utilbtn" color="primary" (click)="AddRes(fm)" [disabled]="fm.invalid" type="submit" matTooltip="Aggiungi risorsa"  matTooltipPosition="below">
                <i class="material-icons">add</i></button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <table mat-table [dataSource]="dataSource" class="internalTable" *ngIf="editableOrder.activity_planned != undefined && editableOrder.activity_planned.length > 0">
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> Risorsa </th>
          <td mat-cell *matCellDef="let element"> {{element.user.surname}} {{element.user.name}}</td>
        </ng-container>
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef> Data inizio </th>
          <td mat-cell *matCellDef="let element"> {{element.start | date: 'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef> Data fine </th>
          <td mat-cell *matCellDef="let element"> {{element.end | date: 'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Luogo </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>
        <ng-container matColumnDef="user_type">
          <th mat-header-cell *matHeaderCellDef> Tipo risorsa </th>
          <td mat-cell *matCellDef="let element"> {{element.user_type}} </td>
        </ng-container>
        <ng-container matColumnDef="days">
          <th mat-header-cell *matHeaderCellDef> Giornate </th>
          <td mat-cell *matCellDef="let element"> {{element.days}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Azioni </th>
          <td mat-cell *matCellDef="let element">
            <a matTooltip="Elimina" matTooltipPosition="below" routerLink="/helm/products" (click)="openDialog(element.productId)"><i class="material-icons nebula-dark">delete</i></a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</form>

