import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { AssetInfo } from '../models/shared/asset-info';
import { AssetLight } from '../models/shared/asset-light';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  option!: HttpHeaders;

  constructor(private http: HttpClient)
  {

  }

  /* getAllAssetsLight(){
    const ApiUrl = environment.mainUrl + 'assetsName';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<AssetLight[]>(ApiUrl, {headers: this.option})
  } */

  /* getAllAssets(): Observable<AssetInfo[]>{
    const ApiUrl = environment.mainUrl + 'asset/all';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<AssetInfo[]>(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['allAsset'];
      }),
        catchError(this.errorhandler)
      );
  }

  getAsset(code: string): Observable<AssetInfo>{
    const ApiUrl = environment.mainUrl + 'asset/' + code;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<AssetInfo>(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['asset'];
      }),
        catchError(this.errorhandler)
      );
  } */

  DownloadTemplate(temptype: string): any {
    const AppUrl = environment.mainUrl+"downloadtemplate/" + temptype;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(AppUrl, {responseType:"blob", headers:this.option});
  }

  UploadTemplate(formData:FormData, temptype: string): Observable<string[]>{
    const AppUrl = environment.mainUrl + '/upload/excel/' + temptype;
    this.option = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(AppUrl, formData, {headers:this.option})
      .pipe(
        map((res:any) => {
          return res['message'];
        }),
        catchError(this.errorhandler)
      )
  }

  getUtil(type: string): Observable<string[]>{
    const ApiUrl = environment.mainUrl + 'utils/' + type;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<string[]>(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res[type];
      }),
        catchError(this.errorhandler)
      );
  }

  addUtil(type: string, newUtil: string): Observable<string>{
    const ApiUrl = environment.mainUrl + 'utils/' + type;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.patch<string>(ApiUrl, {newUtil} , {headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
