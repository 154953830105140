import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/helm/company';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-edit-company-helm',
  templateUrl: './edit-company-helm.component.html',
  styleUrls: [
    './edit-company-helm.component.scss',
    '../../../../app.component.scss'
  ]
})
export class EditCompanyHelmComponent implements OnInit {

  editableCompany: Company = {
    companyId: "",
    logo: "",
    name: "",
    address: "",
    email: "",
    phone: "",
    type: "",
    website: "",
    internalname: "",
    internalemail: "",
    internalphone: "",
    repository: false
  }
  editableCompanyId: string;
  newImg: string = "";

  constructor(private helmsrv: HelmService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.editableCompanyId = this.activatedRoute.snapshot.params.id;
    this.LoadCompany();
  }

  LoadCompany(){
    this.helmsrv.LoadSelectedCompany(this.editableCompanyId).subscribe(
      (res: Company) => {
        this.editableCompany = res;
      },
      (error) => {
        console.log("Errore caricamento azienda selezionata", error);
      });
  }

  SaveCompany(form: NgForm){
    this.editableCompany = {...form.value};
    this.editableCompany.companyId = this.editableCompanyId;
    if(this.newImg != "") this.editableCompany.logo = this.newImg;
    if(this.editableCompany.logo == "https://nebulateam.bimspace.it/image/profile.png" || this.editableCompany.logo == undefined) this.editableCompany.logo = "";
    this.helmsrv.EditSelectedCompany(this.editableCompany)
      .subscribe(res => {
        console.log(res);
        this.router.navigateByUrl('/helm/companies')},
      (error) => {
        console.log("Errore editazione azienda", error);
      });
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.editableCompany.logo = this.newImg;
      };
    }
  }
}
