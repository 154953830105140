import { Component, OnInit} from '@angular/core';




@Component({
  selector: 'app-internal-user',
  templateUrl: './internal-user.component.html',
  styleUrls: [
    './internal-user.component.scss',
    '../../../app.component.scss'
  ]
})
export class InternalUserComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {

  }


}

