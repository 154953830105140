import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-menu-activities',
  templateUrl: './menu-activities.component.html',
  styleUrls: ['./menu-activities.component.scss']
})
export class MenuActivitiesComponent implements OnInit {

  @Input() selectedProd: string;

  @Output() setSelMenu = new EventEmitter<string>()
  constructor() { }

  ngOnInit(): void {
    this.selectedProd = localStorage.getItem('selectedProd');
  }

  ngOnChanges(){
    this.selectedProd = localStorage.getItem('selectedProd');
  }

  SetMenu(menu: string){
    this.setSelMenu.emit(menu);
  }
}
