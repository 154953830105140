
<mat-toolbar class="navbar" (click)="resetInfo()">
    <span *ngFor="let el of breadcumbs; let index = index">
        <button mat-button (click)="navigateTo(el.path)"> {{el.name}}</button>
        <span *ngIf="index < (breadcumbs).length-1"> / </span>
    </span>
    <span class="right">
        <button mat-mini-fab color="primary"
            class="mr-10"
            *ngIf="selection.selected.length > 0"
            (click)="downloadSelected()"
            #tooltip="matTooltip"
            matTooltip="Download files/cartelle"
            [matTooltipPosition]="topTooltip">
                <mat-icon>download</mat-icon></button>

        <button mat-mini-fab color="primary"
            class="mr-10"
            *ngIf="selection.selected.length > 0 && !showDeleted"
            (click)="deleteSelected()"
            #tooltip="matTooltip"
            matTooltip="Cancella files/cartelle"
            [matTooltipPosition]="topTooltip">
                <mat-icon>delete</mat-icon></button>

        <button mat-mini-fab color="primary"
            class="mr-10"
            *ngIf="selection.selected.length > 0 && showDeleted"
            (click)="OnSelectedDeletePermanently()"
            #tooltip="matTooltip"
            matTooltip="Cancellazione files/cartelle permanentemente"
            [matTooltipPosition]="topTooltip">
                <mat-icon>delete</mat-icon></button>

        <button mat-mini-fab color="primary"
            class="mr-10"
            *ngIf="selection.selected.length > 0 && showDeleted"
            (click)="OnRestoreSelectedPermanently()"
            #tooltip="matTooltip"
            matTooltip="Ripristina files/cartelle"
            [matTooltipPosition]="topTooltip">
                <mat-icon>restore_from_trash</mat-icon></button>

        <button mat-mini-fab color="primary"
            class="mr-10"
            *ngIf="showPasteButton == true"
            (click)="pastItem()"
            #tooltip="matTooltip"
            matTooltip="Incolla elemento"
            [matTooltipPosition]="topTooltip">
                <mat-icon>content_paste</mat-icon></button>

        <button mat-mini-fab color="primary"
            class="mr-10"
            (click)="openDialogFolder()"
            #tooltip="matTooltip"
            matTooltip="Nuova cartella"
            [matTooltipPosition]="topTooltip">
                <mat-icon>add</mat-icon></button>

        <button mat-mini-fab color="primary"
            class="mr-10"
            (click)="openUpdateDialog()"
            #tooltip="matTooltip"
            matTooltip="Upload files"
            [matTooltipPosition]="topTooltip">
                <mat-icon>upload</mat-icon></button>

        <div class="search-bar" *ngIf="showBar">
            <div class="fields-wrapper">
                <mat-form-field class="search-form-field" appearance="fill">
                    <mat-label>Ricerca</mat-label>
                    <input matInput type="text" [(ngModel)]="searchText">
                    <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-flat-button color="primary"
                    class="mr-10"
                    #tooltip="matTooltip"
                    matTooltip="Ricerca files/cartelle"
                    [matTooltipPosition]="topTooltip"
                    (click)="OpenSearchResult()">Cerca</button>
            </div>
        </div>
        <button mat-mini-fab color="primary"
            class="mr-10"
            #tooltip="matTooltip"
            matTooltip="Ricerca files/cartelle"
            [matTooltipPosition]="topTooltip"
            (click)="OpenSearch()"><mat-icon>search</mat-icon></button>

        <button mat-mini-fab color="primary" class="mr-10" [matMenuTriggerFor]="menu2" *ngIf="loaded"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu2="matMenu" *ngIf="loaded">
            <mat-slide-toggle [(ngModel)]="showDeleted" class="full-width-button" name="showDeleted" (change)="onShowDeleted()">Mostra Cancellati</mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="emptyFolder" class="full-width-button" name="emptyFolder">Solo Cartelle Piene</mat-slide-toggle>
            <mat-divider></mat-divider>
            <button mat-button color="primary" class="full-width"><mat-icon>file_download</mat-icon> Report </button>
        </mat-menu>
    </span>
</mat-toolbar>

<div class="mat-elevation-z8 mt-64 fileover" (click)="resetInfo()" appDnd (fileDropped)="onFileDropped($event)" webkitDirectory>
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" style="display:none"/>
    <table mat-table [dataSource]="dataSource" matSort class="full-container">

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" color="primary"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <!-- Nome Column -->
        <ng-container matColumnDef="Nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 65em"> Nome </th>
            <td mat-cell *matCellDef="let element" >
                <mat-list class="no-padding">
                    <mat-list-item (click)="navigate(element)" class="no-padding pointer">
                        <img *ngIf="element.type == 'file'" [src]="mainUrl + 'image/format/' + element.icon.substring(1) + '.png'" height="32" width="32" class="left"/>
                        <div mat-line *ngIf="element.type == 'file' && !element.renameActive" class="pl-40">{{element.name.substring(1, element.name.lastIndexOf('.'))}} </div>
                        <img *ngIf="element.type == 'folder'" [src]="mainUrl + 'image/folder2.png'" height="32" width="32" class="left"/>
                        <div mat-line *ngIf="element.type == 'folder' && !element.renameActive" class="pl-40"> {{element.name.substring(1)}} </div>
                        <!-- sezione rename -->
                        <input class="example-input" [(ngModel)]="nameFolder" [type]="element.renameActive ? 'text' : 'hidden'">
                        <button *ngIf="element.renameActive" mat-icon-button color="primary" aria-label="Example icon button with a delete icon" (click)="renameItem(element)">
                        <mat-icon>done</mat-icon>
                        </button>
                        <button *ngIf="element.renameActive" mat-icon-button color="primary" aria-label="Example icon button with a delete icon" (click)="cancelRename(element)">
                        <mat-icon>close</mat-icon>
                        </button>
                        <!-- fine sezione rename -->
                    </mat-list-item>
                </mat-list>
            </td>
        </ng-container>

        <!-- Dimensione Column -->
        <ng-container matColumnDef="Dimensione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dimensione </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.type == 'file' && (element.dimension/1024) > 0 && (element.dimension/1024) <= 1024 "> {{ element.dimension/1024 |
                    number: '1.2-2' }} KB </span>
                <span *ngIf="element.type == 'file' && (element.dimension/(1024*1024)) > 1.1  && (element.dimension/1024) <= 1000000"> {{element.dimension/(1024*1024) | number: '1.2-2' }} MB </span>
                <span *ngIf="element.type == 'file' && (element.dimension/(1024*1024*1024)) > 1.1 && (element.dimension/1024) <= 1000000000"> {{ element.dimension/(1024*1024*1024) | number: '1.2-2' }} GB  </span>
                <span *ngIf="element.type == 'folder'"> &mdash; </span>
            </td>
        </ng-container>

        <!-- Ultima modifica Column -->
        <ng-container matColumnDef="Ultima modifica">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ultima modifica </th>
            <td mat-cell *matCellDef="let element"> {{element.date | date:'MMM d, y, h:mm' }} </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]="row.selected" [class.opacity]="row.hidden" (contextmenu)="onRightClick($event, row)"></tr>
    </table>

    <!-- an hidden div is created to set the position of appearance of the menu-->
    <div style="visibility: hidden; position: fixed;"
    [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y"
    [matMenuTriggerFor]="rightMenu" #rMenu="matMenuTrigger"></div>

    <!-- standard material menu -->
    <mat-menu #rightMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item><mat-icon>done</mat-icon>Selected {{item.name.substring(1)}}</button>
        <button mat-menu-item (click)="OnOpenInfo(item)" *ngIf="item.hidden == false"><mat-icon>info</mat-icon> Info </button>
        <button mat-menu-item *ngIf="item.type == 'file' && item.hidden == false" (click)="onPreviewItem(item)"><mat-icon>remove_red_eye</mat-icon> Anteprima</button>
        <button mat-menu-item (click)="restoreItem(item)" *ngIf="item.hidden == true"><mat-icon>restore_from_trash</mat-icon> Ripristina </button>
        <button mat-menu-item (click)="copyItem(item)" *ngIf="item.hidden == false"><mat-icon>content_copy</mat-icon> Copia</button>
        <button mat-menu-item (click)="cutItem(item)" *ngIf="item.hidden == false"><mat-icon>content_cut</mat-icon> Taglia</button>
        <button mat-menu-item (click)="activeRename(item)" *ngIf="item.hidden == false"><mat-icon>edit</mat-icon> Rinomina</button>
        <button mat-menu-item (click)="download(item)"><mat-icon>file_download</mat-icon> Download</button>
        <button mat-menu-item (click)="deleteItem(item)" *ngIf="item.hidden == false"><mat-icon>delete</mat-icon> Cancella</button>
        <button mat-menu-item (click)="deleteItemPermanently(item)" *ngIf="item.hidden == true"><mat-icon color="red">delete</mat-icon> Cancella Definintivamente</button>
        <mat-divider *ngIf="item.hidden == false"></mat-divider>
        <button mat-menu-item (click)="OnOpenComments(item)" *ngIf="item.hidden == false"><mat-icon>comment</mat-icon> Commenti </button>
        <button mat-menu-item (click)="openLinkCreation(item)" *ngIf="item.hidden == false"><mat-icon>link</mat-icon> Link </button>
        <button mat-menu-item (click)="OnShareCdeItem(item)" *ngIf="item.hidden == false"><mat-icon>share</mat-icon> Condividi con ...</button>
        <button mat-menu-item (click)="OnUnShareCdeItem(item)" *ngIf="item.hidden == false && item.shared > 0"><mat-icon>settings</mat-icon> Gestisci Condisione</button>
        <button mat-menu-item (click)="openHistoryFile(item)" *ngIf="item.hidden == false"><mat-icon>history</mat-icon> Versioni </button>
    </ng-template>
    </mat-menu>

     <mat-card *ngIf="showProgressBar" style="position: absolute; bottom: 10px; width: 30%; right: 20px; box-shadow: 1px 1px 2px 2px lightgrey;">
        <div class="right top-right">
            <button mat-mini-fab (click)="onCloseProgress()" aria-label="Close download">
              <mat-icon>clear</mat-icon>
            </button>
        </div>
        <br>
        <br>
        <h5>Download {{selectItem.type == "folder" ? "cartella" : selectItem.type}} - {{selectItem.name.substring(1)}}</h5>
        <mat-progress-bar *ngIf="download$ | async as download"
            [mode]="download.state == 'IN_PROGRESS' ? 'buffer' : 'determinate'"
            [value]="download.progress">
        </mat-progress-bar>

    </mat-card>
</div>

<mat-sidenav class="size-side-nav" #snav id="snav" *ngIf="selectItem" mode="over" position="end" [(opened)]="opened">
    <div class="sidenav-header">
        <img *ngIf="selectItem.icon" class="img-info" [src]="mainUrl + 'image/format/' + selectItem.icon.substring(1) + '.png'" [alt]="" width="36" height="36"/>
        <img *ngIf="!selectItem.icon" class="img-info" [src]="mainUrl + 'image/folder2.png'" width="36" height="36"/>
        <p class="p-info" #tooltip="matTooltip" [matTooltip]="selectItem.name" position="bottom">{{selectItem.name}}</p>
        <button mat-mini-fab color="primary" class="mr-10 right top-right2" (click)="snav.close()"><mat-icon>clear</mat-icon></button>
    </div>
    <mat-divider class="mt-10"></mat-divider>
    <div class="sidenav-content">
        <mat-tab-group mat-align-tabs="center" *ngIf="!commentSection" (selectedTabChange)="onTabChanged($event)">
            <mat-tab label="Dettagli">
                <div class="container" *ngIf="moreInfo">
                    <h3 class="nomargin">Proprietà</h3>
                    <mat-list class="list" role="list">
                        <mat-list-item role="listitem">Propietario : <img class="image-circle" [src]="moreInfo.owner.image" height="32" width="32"/> &nbsp;&nbsp;{{moreInfo.owner.name}} {{moreInfo.owner.surname}}</mat-list-item>
                        <mat-list-item role="listitem">Tipo : {{moreInfo.type}}</mat-list-item>
                        <mat-list-item role="listitem" *ngIf="moreInfo.type=='file'">Dimensione : {{formatBytes(moreInfo.dimension)}}</mat-list-item>
                        <mat-list-item role="listitem">Data Creazione : {{moreInfo.creation | date:'dd/MM/yyyy'}}</mat-list-item>
                        <mat-list-item role="listitem">Data Ultima Modifica : {{moreInfo.date | date:'dd/MM/yyyy'}}</mat-list-item>
                        <mat-list-item role="listitem"><p class="list-info" [matTooltip]="moreInfo.abs_path" [matTooltipPosition]="topTooltip">Percorso : {{moreInfo.abs_path}}</p> </mat-list-item>
                        <mat-list-item role="listitem">MIME : {{moreInfo.contentType}}</mat-list-item>
                    </mat-list>
                    <h3 >Permessi</h3>
                    <table mat-table [dataSource]="dataSourceSharing" class="mat-elevation-z8 list">

                        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->

                        <!-- Position Column -->
                        <ng-container matColumnDef="utenza">
                          <th mat-header-cell *matHeaderCellDef> Utenza </th>
                          <td mat-cell *matCellDef="let element"> {{element.user}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="r">
                          <th mat-header-cell *matHeaderCellDef> R </th>
                          <td mat-cell *matCellDef="let element"> <mat-icon *ngIf="element.read" class="green-text">done</mat-icon> <mat-icon *ngIf="!element.read" class="red-text">clear</mat-icon></td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="rw">
                          <th mat-header-cell *matHeaderCellDef> R/W </th>
                          <td mat-cell *matCellDef="let element"><mat-icon *ngIf="element.write" class="green-text">done</mat-icon> <mat-icon *ngIf="!element.write" class="red-text">clear</mat-icon></td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="share">
                          <th mat-header-cell *matHeaderCellDef> Share </th>
                          <td mat-cell *matCellDef="let element"><mat-icon *ngIf="element.share" class="green-text">done</mat-icon> <mat-icon *ngIf="!element.share" class="red-text">clear</mat-icon></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="permissionColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: permissionColumns;"></tr>
                      </table>

                </div>
            </mat-tab>
            <mat-tab label="Attivita">
                <mat-list role="list">
                    <span *ngFor="let item of activities">
                        <mat-list-item role="listitem" class="custom-height-list">
                            <div class="list-header">
                                <h6 style="margin-left: 20px; margin-bottom: 5px; margin-top: 8px;">{{item.data | date:"d MMM, y, HH:mm:ss"}}</h6>
                            </div>
                            <div class="list-content">
                                <span style="display: inline-block; width: 100%;">
                                    <img class="image-circle-activity" [src]="item.image" height="32" width="32"/>
                                    <p style="margin-left: 20px; width: 100%">{{item.user}} {{item.message }} </p>
                                </span>
                                <p style="margin-left: 62px; margin-top:0px;"><strong>{{item.item}}</strong></p>

                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </span>
                  </mat-list>

            </mat-tab>
        </mat-tab-group>
        <div *ngIf="commentSection">
            <div class="container">
                <h3 class="heading">Commenti</h3>
                <div class="messaging">
                      <div class="inbox_msg">
                        <div class="mesgs">
                          <div class="msg_history" *ngFor="let msg of comments">
                            <div class="incoming_msg" *ngIf="user_id !== msg.user_id">
                              <div class="incoming_msg_img"> <img [src]="msg.image" alt="sunil"> </div>
                              <div class="received_msg">
                                <div class="received_withd_msg">
                                  <p>{{msg.message}}</p>
                                  <span class="time_date"> {{msg.data | date:"HH:mm"}} | {{msg.data | date:"dd MMM YY"}} </span></div>
                              </div>
                            </div>
                            <div class="outgoing_msg" *ngIf="user_id === msg.user_id">
                              <div class="sent_msg">
                                <p>{{msg.message}}</p>
                                <span class="time_date-r"> {{msg.data | date:"HH:mm"}} | {{msg.data | date:"dd MMM YY"}} </span> </div>
                            </div>
                          </div>

                        </div>
                      </div>
                </div>
                <div class="message-box">
                    <textarea type="text" class="message-input" placeholder="Type message..." [(ngModel)]="outMessage"></textarea>
                    <button type="submit" (click)="OnSendComment(this.selectItem)" class="message-submit">Invia</button>
                </div>
            </div>
        </div>
    </div>
</mat-sidenav>


<div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <img src="assets/img/dnd/ic-file.svg" width="45px" alt="file">
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
      </div>
    </div>
</div>


<!-- <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
</ngx-file-drop> -->
<!-- <div class="container" appDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
    <img src="assets/img/dnd/ic-upload-file.svg" alt="">
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label for="fileDropRef">Browse for file</label>
  </div>

  </div> -->
