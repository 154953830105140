import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Model3d } from 'src/app/models/activity/model3d';
import { Ticket } from 'src/app/models/activity/ticket';
import { Myfile } from 'src/app/models/shared/myfile';
import { ProdLight } from 'src/app/models/shared/prod-light';
import { Product } from 'src/app/models/shared/product';
import { ActivityService } from 'src/app/services/activity.service';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss']
})
export class NewTicketComponent implements OnInit {

  loaded = false;
  selprodId: string;

  newticket: Ticket = {
    assigned: undefined,
    attachs: [],
    classification: '0',
    creator: undefined,
    endDate: undefined,
    id: '',
    images: [],
    isProduct: true,
    model: {
      code:'',
      description: '',
      fileid: undefined,
      filename: '',
      name:'',
      product: ''
    },
    note: '',
    opened: true,
    priority: '0',
    prodline: undefined,
    product: undefined,
    resinfo: undefined,
    startDate: undefined,
    title:''
  }

  allProductsLight: ProdLight[] = []
  selProductLight: ProdLight;
  selProduct: Product;

  allModels: Model3d[] = [];

  selectedprod: string;
  prodsForSel: string[] = [];
  prodCtrl = new FormControl();
  filteredprods: Observable<string[]>;

  constructor(private actsrv: ActivityService, private helmsrv: HelmService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.selprodId = routeParams.prodid;
      this.LoadProds();
    })
  }

  LoadProds(){
    if(this.selprodId == 'all'){
      this.helmsrv.LoadAllProductsLight()
        .subscribe(res => {
          this.allProductsLight = res;
          for(let prod of this.allProductsLight){
            this.prodsForSel.push(prod.code);
          }
          this.filteredprods = this.prodCtrl.valueChanges
            .pipe(
              startWith(''),
              map(prod => this._filterProd(prod))
            );
          this.loaded = true;
        })
    }
    else{
      this.helmsrv.LoadSelectedProduct(this.selprodId)
        .subscribe(res => {
          this.selProduct = res;
          this.actsrv.LoadModels(this.selprodId)
            .subscribe(res => {
              this.allModels = res;
              this.loaded = true;
            })

        })
    }
  }

  GetModels(prodcode: string){
    let selprod = this.allProductsLight.filter(p => p.code.toUpperCase() == prodcode.toUpperCase());
    if(selprod.length > 0){
      this.actsrv.LoadModels(selprod[0].productId)
        .subscribe(res => {
          this.allModels = res;
        })
    }
  }

  AddNewTicket(form: NgForm){

  }

  NavigateTo(){

  }

  AddAttach(event: any){
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let newattach: Myfile = {
          path: file as string,
          name: (reader.result as string).substring((reader.result as string).lastIndexOf('\''), (reader.result as string).length)
        }
        this.newticket.attachs.push(newattach);
      };
    }
  }

  private _filterProd(value: string): string[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      this.GetModels(value);
      return this.prodsForSel.filter(prod => prod.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }

}
