<mat-card class="helmcard">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="mdc-text left" class="assettitle">Utenti interni</h5>
    </span>
    <div class="right">
      <button mat-fab class="menubtn" color="primary"matTooltip="Download template"  (click)="DownloadTemplate()" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
        <i class="material-icons">file_download</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Upload template utenti" (click)="fileInput.click()"  matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
        <input #fileInput type="file" id="loadFileInput" hidden (change)="UploadTemplate($event)" accept=".xlsx"/>
        <i class="material-icons">file_upload</i>
        </button>
      <button mat-fab class="menubtn" color="primary"matTooltip="Nuovo utente interno" routerLink="/helm/internalusers/new" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
        <i class="material-icons">person_add</i></button>
      <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.image"></td>
      </ng-container>
      <ng-container matColumnDef="name" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
        <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Telefono </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Azienda </th>
        <td mat-cell *matCellDef="let element"> {{element.company.name}} </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ruolo </th>
        <td mat-cell *matCellDef="let element"> {{element.role}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="userprivilege == 'admin'" matTooltip="Edita utente" matTooltipPosition="below" [routerLink]="['/helm/internalusers/edit/', element.userId]" ><i class="material-icons nebula-dark">edit</i></a>
          <a *ngIf="userprivilege == 'admin'" routerLink="/helm/internalusers" matTooltip="Elimina utente" matTooltipPosition="below" (click)="openDialog(element.userId)"><i class="material-icons nebula-dark">delete</i></a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
