import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  option: HttpHeaders = null;

  constructor(private http: HttpClient, private router: Router) {

   }

  LoadInfoUser(privilege: string[]) : Observable<boolean>{
    const ApiUrl = environment.mainUrl + 'authuser';
    this.option= new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(ApiUrl, {privilege: privilege}, {headers: this.option}).pipe(
      map(res => {
        if(!res['auth']) this.router.navigate(['']);
        return res['auth'];
      }),
        catchError(this.errorhandler)
      );
  }

  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
