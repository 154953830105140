import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CloudRes, CloudRes1 } from '../models/cde/cloud-res';
import { CloudItem, Version } from '../models/cde/cloud-item';
import { LinkModel } from '../models/cde/link-model';
import { ResRevision } from '../models/cde/res-revision';
import { LinkRes } from '../models/cde/link-res';
import { map } from 'rxjs/operators';
import { download, Download } from '../models/cde/common/download';
import { Saver, SAVER } from '../models/cde/common/saver.provider';
import { CommentReq } from '../models/cde/comment-req';
import { CdeUser } from '../models/CDE/cde-user';
import { AuthenticationService } from './Login/authentication.service';



const ApiUrl = environment.mainUrl

@Injectable({
  providedIn: 'root'
})

export class CdeServiceService {

  option: HttpHeaders = null;
  linkRootPath: string;

  constructor(private http: HttpClient, private router: Router, private auth: AuthenticationService, @Inject(SAVER) private save: Saver) {}

  //#region Gestione Cloud Base

  getCloud(path: string, hidden: boolean, notification: boolean): Observable<CloudRes> {
    let params = new HttpParams()
      .set("path", path)
        .set("showDeleted", hidden)
          .set("notification", notification)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get<CloudRes>(ApiUrl + "v2/directory", {params: params, headers: this.option});
  }

  uploadFiles(formData: FormData): Observable<any>{
    this.option = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(ApiUrl + "upload", formData, { reportProgress: true,
      observe: 'events', headers: this.option})
  }

  dowloadFile(item: CloudItem, ver?: Version): any {
    if (item.type == "folder") ver = {path: "", date: new Date(), dbId : "", dimension: 0, updateBy: "", ver: ""}
    let params = new HttpParams()
      .set("original", item.abs_path)
        .set("path", ver == undefined ? item.meta[item.meta.length - 1].version[item.meta[item.meta.length - 1].version.length - 1].path : ver.path)

    let url = item.type == "file" ? ApiUrl + "file" : ApiUrl + "folder"
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
    return this.http.get(url, {
      params: params,
      headers: this.option,
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(download(blob => {
      let name = item.name.substring(1)
      if (name.indexOf(".enc") > 0 && item.type == "file")
        name = item.name.substring(1, item.name.indexOf(".enc"))
      if (item.type == "folder") name += ".zip"
      this.save(blob, name)
    }));
  }

  dowloadPdfV1(item: CloudItem, ver?: Version): any {
    let params = new HttpParams()
      .set("original", item.abs_path)
        .set("path", ver == undefined ? item.meta[item.meta.length - 1].version[item.meta[item.meta.length - 1].version.length - 1].path : ver.path)

    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
        return this.http.get(ApiUrl + "file", { params: params, headers: this.option, responseType: 'blob'});
  }

  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

  createCdeFolder(data: any): Observable<CloudRes1>{
    this.option= new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<CloudRes1>(ApiUrl + "directory", data , { headers: this.option});
  }

  deleteCdeItem(item: CloudItem): Observable<CloudItem[]>{
    let params = new HttpParams()
      .set("path", item.abs_path)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.delete<CloudItem[]>(ApiUrl + "file2", {params: params, headers: this.option});
  }

  deleteCdeItemPermanently(item: CloudItem): Observable<CloudItem[]>{
    let params = new HttpParams()
      .set("path", item.abs_path)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.delete<CloudItem[]>(ApiUrl + "file/permanent", {params: params, headers: this.option});
  }

  renameCdeItem(item: CloudItem, name: string): Observable<CloudRes1>{
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<CloudRes1>(ApiUrl + "rename", {path: item.abs_path, type: item.type, name: name, old: item.name }, { headers: this.option});
  }

  createCdeRevision(data: any): Observable<ResRevision>{
    this.option= new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<ResRevision>(ApiUrl + "revision", data , { headers: this.option});
  }

  purgeVersions(data: any): Observable<ResRevision>{
    this.option= new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<ResRevision>(ApiUrl + "purge", data , { headers: this.option});
  }

  pasteCdeItem(src: string, dest: string, url: string): Observable<CloudItem[]>{
    this.option= new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return  this.http.post<CloudItem[]>(ApiUrl + url, {src: src, destination: dest} , { headers: this.option});
  }

  restoreCdeItem(item: CloudItem): Observable<CloudRes1>{
    let params = new HttpParams()
      .set("path", item.abs_path)
        .set("type", item.type)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.patch<CloudRes1>(ApiUrl + "restore", {}, {params: params,headers: this.option});
  }

  getStream(item: CloudItem, share: string): Observable<any> {
    let params = new HttpParams()
      .set("path", item.abs_path)
      console.log(share)
    if (share != "") params.set("share", "share=" + share)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
    return this.http.get<any>(ApiUrl + "video/stream", {params: params, headers: this.option});
  }

  deleteStream(path: string): Observable<any> {
    let params = new HttpParams()
      .set("name", path)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
    return this.http.delete<any>(ApiUrl + "video/stream", {params: params, headers: this.option});
  }

  searchItems(searched: string): Observable<CloudItem[]>{
    let params = new HttpParams()
    .set("searched", searched)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token')))
    return this.http.get<any>(ApiUrl + "search", {params: params, headers: this.option});
  }

  shareItem(item: CloudItem, id: string): Observable<any>{
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token')))
    return this.http.post<any>(ApiUrl + "shared", {path: item.abs_path, id: id }, {headers: this.option});
  }

  getOfficePreview(item: CloudItem, share: string): Observable<any> {
    let params = new HttpParams()
      .set("link", item.meta[item.meta.length - 1].version[item.meta[item.meta.length - 1].version.length - 1].path)
      .set("file", item.abs_path)
      if (share != "") params.set("share", "share=" + share)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
    return this.http.get<any>(ApiUrl + "office/link", {params: params, headers: this.option});
  }

  deleteOfficePreview(link: string): Observable<any> {
    let params = new HttpParams()
      .set("name", link)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
    return this.http.delete<any>(ApiUrl + "office/link", {params: params, headers: this.option});
  }

  dowload3DFile(path: string, original: string): any {
    let params = new HttpParams()
      .set("path", path)
        .set("original", original)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
    return this.http.get(ApiUrl + "3dfile", { responseType: "blob", params: params, headers: this.option});
  }

  getMoreInfo(item: CloudItem): Observable<any> {
    let params = new HttpParams()
      .set("path", item.abs_path)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(ApiUrl + "full/info", {params: params, headers: this.option});
  }

  getActivities(item: CloudItem): Observable<any> {
    let params = new HttpParams()
      .set("id", item.info)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(ApiUrl + "activity/cde", {params: params, headers: this.option});
  }

  getSharingInfo(item: CloudItem): Observable<any>{
    let params = new HttpParams()
      .set("path", item.abs_path)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(ApiUrl + "v2/shareinfo", {params: params, headers: this.option});
  }

  getComments(item: CloudItem): Observable<any>{
    let params = new HttpParams()
      .set("id", item.info)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(ApiUrl + "cdecomments", {params: params, headers: this.option});
  }

  sendComment(item: CommentReq): Observable<any>{
    this.option = new HttpHeaders()
      .set("Content-Type", "application/json")
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(ApiUrl + "comment", item, { headers: this.option})
  }

  getUsers(type: string, option: string): Observable<any>{
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get<any>(ApiUrl + `v2/users/${option}/${type}`, {headers: this.option});
  }

  unshareItem(item: CloudItem, id: string): Observable<any>{
    let params = new HttpParams()
      .set("id", id)
        .set("path", item.abs_path)
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token')))
    return this.http.delete<any>(ApiUrl + "v2/unshare", {params: params, headers: this.option});
  }

  //#endregion

  //#region Gestione operazioni su piu file/cartelle

  dowloadZip(item: CloudItem[], path: string): any {
    let array = item.map(it => { return {abs_path: it.abs_path, id: it.id}})
    let params = new HttpParams()
        .set("array", JSON.stringify(array))
          .set("original", path)
    let url = ApiUrl + "v2/zip";
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
        return this.http.get(url, {
          params: params,
          headers: this.option,
          reportProgress: true,
          observe: 'events',
          responseType: 'blob'
        }).pipe(download(blob => {
          let name = "Archivio.zip"
          this.save(blob, name)
        }));
  }

  deleteMultipleItem(items: CloudItem[], main: string): Observable<CloudItem[]>{
/*     let params = new HttpParams()
      .set("main", main)
        .set("array", JSON.stringify(items)) */
    let array = items.map(it => { return {abs_path: it.abs_path, id: it.id}})

    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<CloudItem[]>(ApiUrl + "v2/delete", {'main': main, 'array': array}, {headers: this.option});
  }

  deleteMultipleItemPermanently(items: CloudItem[], main: string): Observable<CloudItem[]>{
/*     let params = new HttpParams()
      .set("main", main)
        .set("array", JSON.stringify(items)) */
    let array = items.map(it => { return {abs_path: it.abs_path, id: it.id}})

    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<CloudItem[]>(ApiUrl + "v2/permanent/delete", {'main': main, 'array': array}, {headers: this.option});
  }

  restoreMultipleItem(items: CloudItem[], main: string): Observable<any>{
/*     let params = new HttpParams()
      .set("main", main)
        .set("array", JSON.stringify(items)) */
    let array = items.map(it => { return {abs_path: it.abs_path, id: it.id}})

    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post<CloudItem[]>(ApiUrl + "v2/restore", {'main': main, 'array': array}, { headers: this.option});
  }

  //#endregion

  //#region Gestione link pubblici e privati
  createCdeLink(item: LinkModel ): Observable<any>{
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(ApiUrl + "v2/link", item , { headers: this.option});
  }

  privateLinkLogin(password: string, share: string): Observable<LinkRes> {
    return this.http.post<LinkRes>(ApiUrl + "v2/link/private", {password: password, share: share});
  }

  getCdeLinkInfo(share: string): Observable<LinkModel>{
    this.option= new HttpHeaders()
    .set('Content-Type','application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get<LinkModel>(ApiUrl + "v2/linkinfo", {params: {share: share}, headers: this.option});
  }

  deleteCdeLink(objectId: string): Observable<any>{
    let link = objectId.slice(objectId.indexOf('?') + 1);
    this.option= new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.delete(ApiUrl + "v2/link", {params: {link: link}, headers: this.option});
  }

  getLinkInfo(share: string): Observable<LinkRes> {
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
    return this.http.get<LinkRes>(ApiUrl + "url/" + share, {headers: this.option})
    .pipe(
      map((res: LinkRes) => {
        if(res.token){
          this.auth.setPublicToken(res.token, res.onlyview, res.read, res.write);
        }
        return res;
    }))
  }

  getLinkCloud(path: string, share: string): Observable<LinkRes> {
    let params = new HttpParams()
      .set("path", path)
        .set("share", "share=" + share)
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('link_token'))
    return this.http.get<LinkRes>(ApiUrl + "v2/directory", {params: params, headers: this.option});
  }

  uploadLinkFiles(formData: FormData): Observable<any>{
    this.option = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('link_token'))
    return this.http.post(ApiUrl + "v2/link/upload", formData, { reportProgress: true,
      observe: 'events', headers: this.option})
  }

  //#endregion

}
