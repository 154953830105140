import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VgApiService, VgMediaDirective} from '@videogular/ngx-videogular/core';
import { CdeServiceService } from 'src/app/services/cde-service.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input() src : string;
  safeUrl: string;
  api

  constructor(private cdeservices: CdeServiceService,
    private sanitizer: DomSanitizer) { 
  }

  ngOnInit(): void { 
    this.safeUrl = this.src;
    /* console.log(this.src)
    this.cdeservices
          .dowloadFile(this.src)
              .subscribe(res => {
                  console.log(res.type)
                  console.log("Video")
                  let unsafeImageUrl = URL.createObjectURL(res);
                  this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
              }) */
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(
        () => {
            // Set the video to the beginning
            this.api.getDefaultMedia().currentTime = 0;
            this.api.getDefaultMedia().play();
        }
    );
  }

}
