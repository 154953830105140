<mat-card id="assetinfocontainer" *ngIf="loaded">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="nebula-dark left" class="assettitle">Markup</h5>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div id="mk-container">
      <mat-grid-list [cols]=4 [rowHeight]="150" *ngIf="selMarkup == undefined">
        <mat-grid-tile *ngFor="let mk of markups">
          <img class="mk-image" [src]="mk.simage" (click)="SelectMarkup(mk)"/>
        </mat-grid-tile>
      </mat-grid-list>
      <div *ngIf="selMarkup != undefined">
        <mat-card id="selmk-card">
          <mat-card-header>
            <mat-card-title class="nebula-dark">{{selMarkup.name}}</mat-card-title>
            <div class="right">
              <button mat-fab class="menubtn" color="primary" matTooltip="Aggiungi a ticket" (click)="UnselectMarkup()" matTooltipPosition="below">
                <i class="material-icons">note_add</i></button>
                <button *ngIf="priv == 'admin' || priv == 'superuser'" mat-fab class="menubtn" color="primary" matTooltip="Cancella markup"
                  (click)="DeleteMarkup()" matTooltipPosition="below">
                  <i class="material-icons">delete</i></button>
              <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" (click)="UnselectMarkup()" matTooltipPosition="below">
                <i class="material-icons">close</i></button>
            </div>

          </mat-card-header>
          <img mat-card-image [src]="selMarkup.simage">
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>
