import { Component, OnInit, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/models/helm/user';
import { CommonService } from 'src/app/services/common.service';
import { HelmService } from 'src/app/services/helm.service';
@Component({
  selector: 'app-all-externaluser',
  templateUrl: './all-externaluser.component.html',
  styleUrls: [
    './all-externaluser.component.scss',
    '../../../../app.component.scss'
  ]
})
export class AllExternaluserComponent implements OnInit {

  userprivilege = localStorage.getItem('priv');
  externalUsers: User[] = [];
  confirm: boolean = false;

  displayedColumns = ['image', 'name', 'surname', 'email', 'phone', 'company', 'role', 'actions'];
  dataSource = new MatTableDataSource<User>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('fileInput') fileIn: ElementRef;

  constructor(private helmsrv: HelmService, public dialog: MatDialog, private cmnsrv: CommonService) { }

  ngOnInit(): void {
    this.LoadUsers();
  }

  ngAfterViewInit(): void{
    this.dataSource.sort = this.sort;
  }

  LoadUsers(){
    this.helmsrv.LoadAllUsers('esterno')
      .subscribe((res: User[]) => {
        this.externalUsers = res;
        this.dataSource.data = this.externalUsers;
      })
  }

  UploadTemplate(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const formData = new FormData();
      const[file] = event.target.files;
      formData.append('file', file);
      formData.append('user_type', 'Esterno');
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.cmnsrv.UploadTemplate(formData, 'user')
        .subscribe(res => {
          console.log(res);
          this.LoadUsers();
          this.fileIn.nativeElement.value = "";
        })
      };
    }
  }

  DownloadTemplate(){
    this.cmnsrv.DownloadTemplate('user')
    .subscribe((response) => {
      const blob = new Blob([response], {type: response.type});
      saveAs(blob, 'UserTemplate.xlsx');
    })
  }

  openDialog(userId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare l'utente selezionato?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.helmsrv.DeleteUser(userId).subscribe(res=>{
          console.log(res);
          this.LoadUsers();
        }, (err) => {
          console.log(err);
        })
      }
    })
  }

}
function saveAs(blob: Blob, arg1: string) {
  throw new Error('Function not implemented.');
}

