<body class="mycolor" style="height: 100vh;">
  <mat-grid-list cols="3" rowHeight="100%">
    <mat-grid-tile style="text-align: right;">
      <app-accesso *ngIf="log" (newPswd)="setNewPass($event)"></app-accesso>
      <app-newpswd *ngIf="!log"></app-newpswd>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=2>
      <img [src]="nebulaImg" id="nebulaImg">
    </mat-grid-tile>
  </mat-grid-list>

<!--   <script>
  function showPwd(){
      var c = document.getElementById("pass").type;
      if (c == "password") document.getElementById("pass").type = "text";
      else document.getElementById("pass").type = "password";
  }
  function checkPasswordMatch() {
  var password = $("#master").val();
  var confirmPassword = $("#confirm").val();

  if (password != confirmPassword && (password!= "" && confirmPassword!= "")){
      $("#divCheckPasswordMatch").css("display", "block");
    }
  else{
      $("#divCheckPasswordMatch").css("display", "none");
    }
  }

  $(document).ready(function () {
    $("#confirm").keyup(checkPasswordMatch);
    });
    </script> -->
</body>
