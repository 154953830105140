import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: [
    './products.component.scss',
    '../../app.component.scss'
  ]
})
export class ProductsComponent implements OnInit {
  nebulaImg: string = environment.imageUrl + 'nebula.png';
  selectedProdId: string;

  constructor() {
  }

  ngOnInit(): void {
    this.selectedProdId = localStorage.getItem('selectedProd')
  }

  SelProd(prodid:string){
    this.selectedProdId = prodid;
  }
}
