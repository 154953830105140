  <div mat-dialog-title>
    <div id="role-title">
      <span class="underline-text-nebula">
        <h2>{{title}}</h2>
      </span>
      <button mat-fab class="menubtn" color="primary" mat-dialog-close  matTooltip="Nuovo ruolo"  matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </div>
  <div mat-dialog-content>
    <div id="role-container">
      <mat-form-field>
        <mat-label>{{title}}</mat-label>
        <input matInput [(ngModel)]="settingValue">
      </mat-form-field>
      <button mat-fab class="utilbtn" color="primary" (click)="Add()"  matTooltip="Nuovo ruolo"  matTooltipPosition="below">
        <i class="material-icons">add</i></button>
    </div>
  </div>

