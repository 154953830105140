import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';
  selectedProdId: string;
  selmenu: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.selectedProdId = localStorage.getItem('selectedProd')
    this.selmenu = 'ticket'
  }

  SelProd(prodid:string){
    this.selectedProdId = prodid;
    this.NavigateTo(this.selmenu)
  }

  NavigateTo(selmenu?: string){
    this.selmenu = selmenu;
    // console.log(`/activities/${this.selmenu}/${this.selectedProdId}`)
    if(localStorage.getItem('selectedProd') != 'TUTTI I PRODOTTI')
      this.router.navigateByUrl(`/activities/${this.selmenu}/all/${this.selectedProdId}`);
    else
    this.router.navigateByUrl(`/activities/${this.selmenu}/all/all`);
  }

}
