<mat-card class="helmcard">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="mdc-text left" class="assettitle">Aziende</h5>
    </span>
    <div class="right">
      <button mat-fab class="menubtn" color="primary" matTooltip="Download template"  (click)="DownloadTemplate()" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
        <i class="material-icons">file_download</i></button>
      <button mat-fab class="menubtn" color="primary"  matTooltip="Upload template utenti" (click)="fileInput.click()"  matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
        <input #fileInput type="file" id="loadFileInput" hidden (change)="UploadTemplate($event)" accept=".xlsx"/>
        <i class="material-icons">file_upload</i>
        </button>
      <button mat-fab class="menubtn" color="primary"  matTooltip="Nuova azienda" routerLink="/helm/companies/new" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
        <i class="material-icons">add</i></button>
      <button mat-fab class="menubtn" color="primary"  matTooltip="Indietro" routerLink="/helm" matTooltipPosition="below">
        <i class="material-icons">close</i></button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef> Logo </th>
        <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.logo"></td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Telefono </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>
      <ng-container matColumnDef="interno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contatto interno </th>
        <td mat-cell *matCellDef="let element"> {{element.internalname}} </td>
      </ng-container>
      <ng-container matColumnDef="azioni">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="userprivilege == 'admin'" matTooltip="Edita azienda" matTooltipPosition="below" [routerLink]="['/helm/companies/edit/', element.companyId]"><i class="material-icons nebula-dark">edit</i></a>
          <a *ngIf="element.type != 'Owner' && userprivilege == 'admin'" routerLink="/helm/companies" matTooltip="Elimina azienda" matTooltipPosition="below" (click)="openDialog(element.companyId)"><i class="material-icons nebula-dark">delete</i></a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
