<form #fm="ngForm" *ngIf="loaded">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Gestione Costi</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"*ngIf="viewOrder.activity_planned.length > 0" [routerLink]="'/helm/orders/view/activity/' + viewOrder.orderId" matTooltip="Attività" matTooltipPosition="below" type="submit">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary"[routerLink]="'/helm/orders/view/main/' + viewOrder.orderId" matTooltip="Torna all'anagrafica" matTooltipPosition="below" type="button">
          <i class="material-icons">arrow_back</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=3  rowHeight="100px">
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data offerta</mat-label>
                <input matInput name="date_offer" [ngModel]="date_offer" readonly type="text">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data acquisizione</mat-label>
                <input matInput name="date_acquisition" [ngModel]="date_acquisition" readonly type="text">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data consegna</mat-label>
                <input matInput name="date_delivery" [ngModel]="date_delivery" readonly type="text">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore offerto</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_offered" [(ngModel)]="viewOrder.costs.price_offered" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore aggiudicato</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_awarded" [(ngModel)]="viewOrder.price_awarded" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi uomo previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="costs_planned" [(ngModel)]="viewOrder.costs.costs_planned" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Giornate uomo previste</mat-label>
                <input matInput type="number" name="days_planned" [(ngModel)]="viewOrder.costs.days_planned" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Giornate uomo pianificate</mat-label>
                <input matInput type="number" name="days_assigned" [(ngModel)]="viewOrder.costs.days_assigned" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="cost_tot" [(ngModel)]="viewOrder.cost_tot" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi predefiniti</mat-label>
                <input matInput type="text" name="cost_set_name" [(ngModel)]="viewOrder.costs.cost_set.name" readonly>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="col-int" style="margin-left: 7%;">
              <button *ngIf="viewOrder.costs.cost_set.name != ''" mat-fab class="menubtn left" color="primary" (click)="openDialog('view')" matTooltip="Visualizza costo predefinito" matTooltipPosition="below" type="button">
                <i class="material-icons">local_atm</i></button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
