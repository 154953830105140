import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-ticket',
  templateUrl: './home-ticket.component.html',
  styleUrls: ['./home-ticket.component.scss']
})
export class HomeTicketComponent implements OnInit {

  @Input() selectedProdId: string;

  constructor() { }

  ngOnInit(): void {

  }
}
