<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Nuovo prodotto</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="AddNewProd(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/products" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=5  rowHeight="550px">
          <mat-grid-tile [colspan]=1>
            <div class="col-int">
              <button type="button" id="loadFileBtn">
                <input type="file" id="loadFileInput" hidden (change)="readUrl($event)" accept=".png,.jpg"/>
                <label for="loadFileInput"><img id="prodimg" [src]="newProd.image"></label>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [ngModel]="newProd.code" required (blur)="CheckCode(fm)">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="newProd.name" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [ngModel]="newProd.description" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Indirizzo</mat-label>
                <input matInput type="text" name="address" [ngModel]="newProd.address">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Proprietario</mat-label>
                <input matInput type="text" name="owner" [(ngModel)]="selectedowner"  required [matAutocomplete]="auto" [formControl]="ownerCtrl">
                <mat-autocomplete  #auto="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let usr of filteredusrsowner | async" [value]="usr">{{usr.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <div class="row" id="role-container">
                <mat-form-field id="role">
                  <mat-label>Aggiungi membro</mat-label>
                  <input matInput type="text" name="member" [(ngModel)]="selectedmember" [matAutocomplete]="autoMem" [formControl]="memberCtrl" >
                  <mat-autocomplete  #autoMem="matAutocomplete" [displayWith]="getDisplayFn()">
                    <mat-option *ngFor="let usr of filteredusrsmember | async" [value]="usr">{{usr.text}}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <button mat-fab class="utilbtn" type="button" (click)="addMember()" color="primary" matTooltip="Aggiungi membro"  matTooltipPosition="below">
                  <i class="material-icons">add</i></button>
              </div>
              <div *ngIf="members.length > 0" [ngfo]="{standalone: true}">
                <div class="col-int">
                  <span class="underline-text-nebula2 left">
                    <h2 class="mdc-text ">Membri</h2>
                  </span>
                  <div id="member-table">
                    <table mat-table [dataSource]="dataSource">
                      <ng-container matColumnDef="memb" >
                        <th mat-header-cell *matHeaderCellDef > Utente </th>
                        <td mat-cell *matCellDef="let element"> {{element.member.text}} </td>
                      </ng-container>
                      <ng-container matColumnDef="permission" >
                        <th mat-header-cell *matHeaderCellDef> Permessi </th>
                        <td mat-cell *matCellDef="let element" >
                          <mat-select name="perm{{element.member.id}}" [(ngModel)]="element.permission">
                            <mat-option *ngIf="element.privilege != 'viewer'" [value]="true">Lettura/Scrittura</mat-option>
                            <mat-option [value]="false">Lettura</mat-option>
                          </mat-select>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          <a matTooltip="Rimuovi membro" routerLink="/helm/products/new" matTooltipPosition="below" (click)="remMemb(element)"><i class="material-icons nebula-dark">delete</i></a>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
