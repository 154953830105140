import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    '../../app.component.scss'
  ]
})
export class LoginComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';
  @Input() log: boolean = true;

  constructor() { }

  ngOnInit(): void {

  }

  setNewPass(np: boolean){
    this.log = np;
    console.log(np);
  }
}
