import { Component, OnInit, Input } from '@angular/core';
import { AssetInfo } from 'src/app/models/shared/asset-info';
import { ProdLight } from 'src/app/models/shared/prod-light';
import { Product } from 'src/app/models/shared/product';
import { CommonService } from 'src/app/services/common.service';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-info-prodotto',
  templateUrl: './info-prodotto.component.html',
  styleUrls: [
    './info-prodotto.component.scss',
    '../../../../app.component.scss']
})
export class InfoProdottoComponent implements OnInit {
  loaded: boolean = false;
  selectedProd: Product = {
    owner: {name:'', privilege:'', surname:'', userId: '', user_type:'', company: {type:'', name:'', address: '', companyId:'', logo:''}},
    productId: '',
    code: '',
    image: '',
    address: '',
    name: '',
    description: '',
    members: []
  };
  @Input() selectedProdId: string;

  constructor(private helmsrv: HelmService) { }

  ngOnInit(): void {
    this.LoadProduct();
  }

  ngOnChanges(){
    this.LoadProduct();
  }

  LoadProduct(){
    this.selectedProdId = localStorage.getItem('selectedProd');
    if(this.selectedProdId != "" && this.selectedProdId != "TUTTI I PRODOTTI"){
      this.helmsrv.LoadSelectedProduct(this.selectedProdId)
      .subscribe( res => {
          this.selectedProd = res;
          this.loaded = true;
        },
        (error) => {
          console.log("Errore caricamento prodotto", error);
        });
    }
  }
}
