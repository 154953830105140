import { Component, Input, OnInit } from '@angular/core';
import { Prodline } from 'src/app/models/shared/prodline';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-info-productline',
  templateUrl: './info-productline.component.html',
  styleUrls: ['./info-productline.component.scss']
})
export class InfoProductlineComponent implements OnInit {

  loaded: boolean = false;
  selectedProdline: Prodline = {
    owner: {name:'', privilege:'', surname:'', userId: '', user_type:'', company: {type:'', name:'', address: '', companyId:'', logo:''}},
    prodlineId: '',
    code: '',
    image: '',
    products: [],
    name: '',
    description: '',
    members: []
  };
  @Input() selectedProdlineId: string;

  constructor(private helmsrv: HelmService) { }

  ngOnInit(): void {
    this.LoadProductline();
  }

  ngOnChanges(){
    this.LoadProductline();
  }

  LoadProductline(){
    this.selectedProdlineId = localStorage.getItem('selectedProdline');
    if(this.selectedProdlineId != "" && this.selectedProdlineId != "TUTTE LE LINEE"){
      this.helmsrv.LoadSelectedProductline(this.selectedProdlineId)
      .subscribe( res => {
          this.selectedProdline = res;
          this.loaded = true;
        },
        (error) => {
          console.log("Errore caricamento linea di prodotto", error);
        });
    }
  }

}
