<div class="maincontainer">
  <app-navbar></app-navbar>
  <div class="appcontainer">

    <div class="flex-container">
      <mat-card id="tree-card">
        <mat-card-title id="tree-card-title">
          <span class="underline-text-nebula">
            <h5 class="nebula-dark left" class="assettitle">{{filename}}</h5>
          </span>
        </mat-card-title>
        <mat-card-content>

        </mat-card-content>
      </mat-card>

      <div id="funcBtns">
        <a mat-mini-fab color="primary" matTooltip="Ricarica modello" ><i class="material-icons">refresh</i></a>
        <!-- <a mat-mini-fab color="primary" matTooltip="Evidenzia parti" (click)="enlightParts()"><i class="material-icons">search</i></a> -->
        <a mat-mini-fab color="primary" matTooltip="Markup">
          <i class="material-icons">photo_camera</i></a>
        <a mat-mini-fab color="primary" matTooltip="Download elenco ricambi">
          <i class="material-icons">download</i></a>
      </div>
      <div id="viewer-container">
        <form #fm="ngForm" (submit)="saveSvg()" >
          <mat-toolbar style="background-color:rgb(230, 228, 228); z-index: 1000;">
            <mat-toolbar-row style="justify-content: space-between;">
              <div class="toolbar-btn">
                <button mat-icon-button [color]="mouseMode == 'selection'? 'warning' : 'primary'" (click)="setMouseMode('selection')" type="button"
                  matTooltip="Selezione"><i class="material-icons">highlight_alt</i></button>
                <button mat-icon-button [color]="mouseMode == 'pan'? 'warning' : 'primary'" (click)="setMouseMode('pan')" type="button"
                  matTooltip="Pan"><i class="material-icons">open_with</i></button>
                <mat-divider [vertical]="true"></mat-divider>
                <button mat-icon-button [color]="mouseMode == 'pen'? 'warning' : 'primary'" (click)="setMouseMode('pen')" type="button"
                  matTooltip="Penna"><i class="material-icons">mode_edit</i></button>
                <button mat-icon-button [color]="mouseMode == 'rectangle'? 'warning' : 'primary'" (click)="setMouseMode('rectangle')" type="button"
                  matTooltip="Rettangolo"><i class="material-icons">rectangle</i></button>
                <button mat-icon-button [color]="mouseMode == 'circle'? 'warning' : 'primary'" (click)="setMouseMode('circle')" type="button"
                  matTooltip="Cerchio"><i class="material-icons">circle</i></button>
                <button mat-icon-button [color]="mouseMode == 'line'? 'warning' : 'primary'" (click)="setMouseMode('line')" type="button"
                  matTooltip="Linea"><i class="material-icons">show_chart</i></button>
                <mat-divider [vertical]="true" ></mat-divider>
                <button mat-icon-button [color]="mouseMode == 'text'? 'warning' : 'primary'" (click)="setMouseMode('text')" type="button"
                  matTooltip="Testo"><i class="material-icons">title</i></button>
                  <mat-divider *ngIf="canDelete" [vertical]="true" ></mat-divider>
                <button mat-icon-button *ngIf="canDelete" (click)="deleteObjs()" color="primary" type="button"
                  matTooltip="Cancella oggetti selezionati"><i class="material-icons">delete</i></button>
              </div>
              <div  class="toolbar-btn">
                <button mat-icon-button color="primary" matTooltip="Reset" (click)="refresh()" type="button"><i class="material-icons">refresh</i></button>
                <button mat-icon-button color="primary" matTooltip="Salva markup" type="submit"><i class="material-icons">save</i></button>
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
        </form>

        <div id="svgcontainer" [innerHTML]="svg"></div>

        <div *ngIf="mouseMode != 'selection' && mouseMode != 'pan'" class="draggable-box" cdkDragBoundary="#viewer-container" cdkDrag>
          <div id="drawing-settings">
            <div id="spessore">
              <mat-slider matTooltip="Spessore penna" color="primary" id="penWidth" [(ngModel)]="penWidth" thumbLabel tickInterval="1" step="1" min="1" max="40" ></mat-slider>
              <label for="penWidth">Spessore tratto</label>
            </div>
            <div>
              <mat-form-field  id="color-picker" appearance="standard">
                <mat-label>Colore</mat-label>
                <input matInput [ngxMatColorPicker]="picker" name="color" [(ngModel)]="color"  >
                <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #picker [touchUi]="false" color="primary"></ngx-mat-color-picker>
              </mat-form-field>
            </div>
          </div>
          <div class="draggable-handle" cdkDragHandle>
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

