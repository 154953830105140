<div class="full-width">
    <h2>Seleziona i file da caricare</h2>
    <input type="file" class="file-input" (change)="onFileSelected($event)" [hidden]="true" #fileUpload multiple>
    <div class="file-upload">
        <mat-list role="list" *ngIf="files.length > 0">
            <mat-list-item id="filelist" role="listitem" *ngFor="let file of files; let index = index">
              <div id="filename"><mat-icon>attach_file</mat-icon> <div>{{file.name}}</div> </div>
              <mat-icon (click)="deleteFile(index)" class="icon-right">delete</mat-icon></mat-list-item>
        </mat-list>
    </div>
    <div class="progress mt-20">
        <mat-progress-bar class="progress-bar" mode="determinate"
                            [value]="uploadProgress" *ngIf="uploadProgress">
        </mat-progress-bar>
        <mat-icon class="cancel-upload" (click)="cancelUpload()"
                    *ngIf="uploadProgress">delete_forever</mat-icon>
    </div>
    <div id="btn-row">
      <div id="cls-btn">
        <button mat-raised-button color="accent" mat-dialog-close class="confbtn menubtn">Chiudi</button>
      </div>
      <div id="fn-btn">
        <button mat-mini-fab color="primary" class="upload-btn"
        (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
        <button mat-mini-fab color="primary" class="upload-btn"
        (click)="uploadRealFiles()">
            <mat-icon>upload</mat-icon>
        </button>
      </div>
    </div>

</div>
