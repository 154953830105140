import { Component, OnInit } from '@angular/core';
import { AssetLight } from 'src/app/models/shared/asset-light';
import { CommonService } from 'src/app/services/common.service';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-ricerca-tot',
  templateUrl: './ricerca-tot.component.html',
  styleUrls: [
    './ricerca-tot.component.scss',
    '../../../app.component.scss']
})
export class RicercaTotComponent implements OnInit {

  allasset: string[] = ["TUTTI I PRODOTTI"];
  searchinput: string;
  selectedasset: string;

  constructor(private helmsrv: HelmService) { }

  ngOnInit(): void {
    this.LoadAsset();
    this.selectedasset = this.allasset[0];
  }

  searchItems(){

  }

  private LoadAsset(){
    this.helmsrv.LoadAllProductsLight()
    .subscribe(res => {
        for(let ass of res){
          this.allasset.push(ass.code)
        }
        this.helmsrv.LoadAllProductlines()
          .subscribe(pl => {
            for(let p of pl){
              this.allasset.push(p.code);
            }
          })
      },
      (error) => {
        console.log("Errore caricamento asset", error);
      });
  }
}
