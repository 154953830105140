import { Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DrawingsService } from 'src/app/services/drawings.service';
import { Color } from '@angular-material-components/color-picker';

@Component({
  selector: 'app-drawing-ticket',
  templateUrl: './drawing-ticket.component.html',
  styleUrls: ['./drawing-ticket.component.scss']
})
export class DrawingTicketComponent implements OnInit {

  filecontent: string = "";
  filename: string = "";
  svg:SafeHtml;
  svgPath: string;
  prodcode: string;
  htmlsvg: any;
  mouseMode: string = "selection";
  canDelete: boolean = false;
  canMove: boolean = false;
  color: Color;

  protected _mouseWheel: (evt: any) => void;
  protected _mouseDown: (evt: MouseEvent) => void;
  protected _mouseUp: (evt: MouseEvent) => void;
  protected _mouseMove: (evt: MouseEvent) => void;

  constructor(private zone: NgZone, private sanitizer: DomSanitizer, private route: ActivatedRoute, private drwsrv: DrawingsService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.svgPath = params.path;
        this.prodcode = this.svgPath.replace('/REPO/PRODUCTS/', '');
        this.prodcode = this.prodcode.slice(0,this.prodcode.indexOf('/'));
        this.color = new Color(0,0,0,1);
        var svgcontainer = document.getElementById("svgcontainer");
        this._mouseWheel = (evt: any) => this.__mouseWheel(evt);
        this._mouseDown = (evt: MouseEvent) => this.__mouseDown(evt);
        this._mouseUp = (evt: MouseEvent) => this.__mouseUp(evt);
        this._mouseMove = (evt: MouseEvent) => this.__mouseMove(evt);
        svgcontainer.addEventListener("mousewheel", this._mouseWheel, false);
        svgcontainer.addEventListener("DOMMouseScroll", this._mouseWheel, false);
        svgcontainer.addEventListener("mousedown", this._mouseDown, false);
        svgcontainer.addEventListener("mouseup", this._mouseUp, false);
        svgcontainer.addEventListener("mousemove", this._mouseMove, false);
        this.loadDrawing();
      })
  }

  loadDrawing() {
    this.drwsrv.GetSvgDrawing(this.svgPath)
    .subscribe(res => {
      this.filename = res.filename;
      this.filecontent = res.filecontent;
      this.svg = this.sanitizer.bypassSecurityTrustHtml(this.filecontent);

      setTimeout(() =>{
        this.zone.runOutsideAngular(() => {
          this.htmlsvg = document.getElementById("mysvg");
          this.drwsrv.svg = this.htmlsvg;
          setTimeout(() =>{
            this.htmlsvg.style.height = '585px';
            this.htmlsvg.style.width = '1000px';
            this.htmlsvg.style.margin = '0 auto';
            this.htmlsvg.style.display = 'block';
            this.drwsrv.createNewArea();
          }, 500)
        })
      }, 1000)
    })
  }

  setMouseMode(mode:string){
    this.zone.run( () => {
      this.mouseMode = mode;
    })
  }

  __mouseWheel(e: any){
    this.zone.run(() => {
      var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
      const { scale, x, y } = this.getTransformParameters(this.htmlsvg);
      let dScale = 0.1;
      if (delta < 0) dScale *= -1;
      if (scale == 0.1 && delta < 0) dScale = 0;
      this.htmlsvg.style.transform = this.getTransformString(scale + dScale, x, y);
    })
  }

  __mouseDown(e: MouseEvent){
    this.zone.run(() => {
      switch (this.mouseMode){
        case "selection":
          console.log(e);
          break;
        case "pan":
          this.canMove = true;
          break;
        case "newTicket":
          this.drwsrv.setColour(this.color.hex);
          let p: SVGPoint = this.htmlsvg.createSVGPoint();
          p.x = e.clientX;
          p.y = e.clientY;
          var cursorpt =  p.matrixTransform(this.htmlsvg.getScreenCTM().inverse());
          console.log("(" + cursorpt.x + ", " + cursorpt.y + ")");
          this.drwsrv.addTicket(cursorpt);
        break;
      }
    })
  }

  __mouseUp(e: MouseEvent){
    this.zone.run(() => {
      switch (this.mouseMode){

        case "pan":
          this.canMove = false;
          break;
      }
    })
  }

  __mouseMove(e: MouseEvent){
    this.zone.run(() => {
      switch (this.mouseMode){
        case "selection":
          if(String(e.detail).includes('text')) console.log(e.detail);
        break;
        case "pan":
          if(this.canMove){
            var dx = Math.max(-1, Math.min(1, (e.movementX || -e.detail)));
            var dy = Math.max(-1, Math.min(1, (e.movementY || -e.detail)));
            const { scale, x, y } = this.getTransformParameters(this.htmlsvg);
            //e.movementX > 0 ? dx = 1 : dx = -1;
            //e.movementY > 0 ? dy = 1 : dy = -1;
            //dx = e.movementX;
            //dy = e.movementY;
            this.htmlsvg.style.transform = this.getTransformString(scale, x + dx, y + dy);
          }
        break;
      }
    })
  }

  getTransformParameters(element){
    const transform = element.style.transform;
    let scale = 1,
      x = 0,
      y = 0;
    if (transform.includes("scale"))
      scale = parseFloat(transform.slice(transform.indexOf("scale") + 6));
    if (transform.includes("translateX"))
      x = parseInt(transform.slice(transform.indexOf("translateX") + 11));
    if (transform.includes("translateY"))
      y = parseInt(transform.slice(transform.indexOf("translateY") + 11));
    return { scale, x, y };
  }

  getTransformString(scale, x, y) {
    return"scale(" + scale + ") " + "translateX(" + x + "%) translateY(" + y + "%)";
  }

  saveSvg(){

  }

  refresh(){
    let svgcontainer = document.getElementById("svgcontainer");
    svgcontainer.innerHTML = "";
    this.loadDrawing();
  }
}
