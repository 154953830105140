<mat-grid-list [cols]=12  rowHeight='400px'>
  <mat-grid-tile [colspan]=6>
    <mat-card class="helmcard">
      <mat-card-header>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Utenti interni</h5>
        </span>
        <div class="right">
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/internalusers/new"  matTooltip="Nuovo utente interno" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
            <i class="material-icons">person_add</i></button>
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/internalusers" matTooltip="Visualizza utenti interni" matTooltipPosition="below">
            <i class="material-icons">open_in_new</i></button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="intuserDataSource">
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef> Immagine </th>
            <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.image"></td>
          </ng-container>
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="cognome">
            <th mat-header-cell *matHeaderCellDef> Cognome </th>
            <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> <div [ngClass]="element.state == 'active' ? 'circle_green' : 'circle_red'"></div></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="userCol sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: userCol;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=6>
    <mat-card class="helmcard">
      <mat-card-header>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Utenti esterni</h5>
        </span>
        <div class="right">
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/externalusers/new" matTooltip="Nuovo utente esterno" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
            <i class="material-icons">person_add</i></button>
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/externalusers" matTooltip="Visualizza utenti esterni" matTooltipPosition="below">
            <i class="material-icons">open_in_new</i></button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="extuserDataSource">
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef> Immagine </th>
            <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.image"></td>
          </ng-container>
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="cognome">
            <th mat-header-cell *matHeaderCellDef> Cognome </th>
            <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> <div [ngClass]="element.state == 'active' ? 'circle_green' : 'circle_red'"></div></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="userCol sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: userCol;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=6>
    <mat-card class="helmcard">
      <mat-card-header>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Commesse</h5>
        </span>
        <div class="right">
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/orders/new" matTooltip="Nuova commessa" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
            <i class="material-icons">add</i></button>
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/orders" matTooltip="Visualizza commesse" matTooltipPosition="below">
            <i class="material-icons">open_in_new</i></button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="orderDataSource">
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Codice </th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.client.name}} </td>
          </ng-container>
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef> Prodotto </th>
            <td mat-cell *matCellDef="let element"> {{element.product.code}} </td>
          </ng-container>
          <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef> Anno </th>
            <td mat-cell *matCellDef="let element"> {{element.year}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="orderCol sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: orderCol;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=6>
    <mat-card class="helmcard">
      <mat-card-header>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Aziende</h5>
        </span>
        <div class="right">
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/companies/new" matTooltip="Nuova azienda" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
            <i class="material-icons">add</i></button>
          <button mat-fab class="menubtn" color="primary"  routerLink="/helm/companies" matTooltip="Visualizza aziende" matTooltipPosition="below">
            <i class="material-icons">open_in_new</i></button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="companyDataSource">
          <ng-container matColumnDef="logo">
            <th mat-header-cell *matHeaderCellDef> Immagine </th>
            <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.logo"></td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="companyCol sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: companyCol;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=6>
    <mat-card class="helmcard">
      <mat-card-header>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Prodotti</h5>
        </span>
        <div class="right">
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/products/new" matTooltip="Nuova prodotto" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
            <i class="material-icons">add</i></button>
          <button mat-fab class="menubtn" color="primary"  routerLink="/helm/products" matTooltip="Visualizza prodotti" matTooltipPosition="below">
            <i class="material-icons">open_in_new</i></button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="prodDataSource">
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef> Immagine </th>
            <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.image"></td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Codice </th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Indirizzo </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="prodCol sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: prodCol;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]=6>
    <mat-card class="helmcard">
      <mat-card-header>
        <span class="underline-text-nebula">
          <h5 class="mdc-text left" class="assettitle">Linee di Prodotto</h5>
        </span>
        <div class="right">
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/productlines/new" matTooltip="Nuova linea di prodotto" matTooltipPosition="below" *ngIf="userprivilege == 'admin'">
            <i class="material-icons">add</i></button>
          <button mat-fab class="menubtn" color="primary" routerLink="/helm/productlines/all" matTooltip="Visualizza linee di prodotti" matTooltipPosition="below">
            <i class="material-icons">open_in_new</i></button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="prodlineDataSource">
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef> Immagine </th>
            <td mat-cell *matCellDef="let element"><img class="profile-circle" [src]="element.image"></td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Codice </th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="prodlineCol sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: prodlineCol;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
