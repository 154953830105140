import { ViewChild } from '@angular/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { OrderLight } from 'src/app/models/helm/order-light';
import { ProdlinesLight } from 'src/app/models/helm/prodlines-light';
import { InfoLight } from 'src/app/models/helm/ResSrv/info-light';
import { UserLight } from 'src/app/models/helm/user-light';
import { ProdLight } from 'src/app/models/shared/prod-light';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    './dashboard.component.scss',
    '../../../app.component.scss'
  ]
})
export class DashboardComponent implements OnInit {

  homeInfoLight!: InfoLight;
  userprivilege = localStorage.getItem('priv');

  userCol = ['image', 'nome', 'cognome', 'status'];
  companyCol = ['logo', 'name', 'address'];
  prodCol = ['image', 'code', 'name', 'address'];
  prodlineCol = ['image', 'code', 'name'];
  orderCol = ['code', 'name', 'client', 'product', 'year'];
  intuserDataSource = new MatTableDataSource<UserLight>();
  extuserDataSource = new MatTableDataSource<UserLight>();
  companyDataSource = new MatTableDataSource<CompanyLight>();
  prodDataSource = new MatTableDataSource<ProdLight>();
  prodlineDataSource = new MatTableDataSource<ProdlinesLight>();
  orderDataSource = new MatTableDataSource<OrderLight>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private helmsrv: HelmService) { }

  ngOnInit(): void {
    this.LoadInfo();
  }

  ngAfterViewInit(): void{
    this.intuserDataSource.sort = this.sort;
    this.extuserDataSource.sort = this.sort;
    this.companyDataSource.sort = this.sort;
    this.prodDataSource.sort = this.sort;
    this.prodlineDataSource.sort = this.sort;
    this.orderDataSource.sort = this.sort;
  }


  LoadInfo(){
    this.helmsrv.LoadInfoLight().subscribe(
      (res: InfoLight) => {
        this.homeInfoLight = res;
        this.intuserDataSource.data = this.homeInfoLight.intuser;
        this.extuserDataSource.data = this.homeInfoLight.extuser;
        this.companyDataSource.data = this.homeInfoLight.companies;
        this.prodDataSource.data = this.homeInfoLight.products;
        this.prodlineDataSource.data = this.homeInfoLight.productline;
        this.orderDataSource.data = this.homeInfoLight.orders;
      },
      (error) => {
        console.log("Errore caricamento dati dashboard Helm", error);
      });
  }
}
