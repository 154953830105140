import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/models/shared/product';
import { HelmService } from 'src/app/services/helm.service';
import { InstantChatService } from 'src/app/services/instant-chat.service';

@Component({
  selector: 'app-chat-module',
  templateUrl: './chat-module.component.html',
  styleUrls: ['./chat-module.component.scss']
})
export class ChatModuleComponent implements OnInit {

  title = 'instant-chatting';
  user: String;
  room: String;
  // tslint:disable-next-line:ban-types
  messageText: String;
  messageArray: Array<{user: String , message: String }> = [];

  constructor(private instantChatservice: InstantChatService){
    this.room = localStorage.getItem('selectedProd');
    this.user = localStorage.getItem('name');
    this.instantChatservice.newUserJoined()
      .subscribe(data => this.messageArray.push(data));

    this.instantChatservice.userLeftRoom()
      .subscribe(data => this.messageArray.push(data));

    this.instantChatservice.newMessageReceived()
      .subscribe(data => this.messageArray.push(data));
  }

  ngOnInit(){
    this.instantChatservice.joinRoom({user: this.user, room: this.room});
  }

  leave(){
    this.instantChatservice.leaveRoom({user: this.user, room: this.room});
  }

  sendMessage()
  {
    if(this.messageText != ''){
      this.instantChatservice.sendMessage({user: this.user, room: this.room, message: this.messageText});
      this.messageText = '';
    }

  }

}
