import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProdlinesLight } from 'src/app/models/helm/prodlines-light';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-elenco-productlines',
  templateUrl: './elenco-productlines.component.html',
  styleUrls: ['./elenco-productlines.component.scss']
})
export class ElencoProductlinesComponent implements OnInit {

  allproductlines: ProdlinesLight[]= [];
  selectedprodline: ProdlinesLight;
  allprodlinesrc: ProdlinesLight[] = [];
  searchinput: string = "";

  allprodline: ProdlinesLight = {
    name: "TUTTE LE LINEE",
    code: "TUTTE LE LINEE",
    products: [],
    image: undefined,
    prodlineId: "TUTTE LE LINEE"
  }

  @Output() setSelProdline = new EventEmitter<string>();


  constructor(private helmsrv: HelmService) { }

  ngOnInit(): void {
    this.LoadProductsLight();
  }

  SelProductline(prodline:ProdlinesLight){
    this.selectedprodline = prodline;
    localStorage.setItem('selectedProdline', prodline.prodlineId);
    this.setSelProdline.emit(this.selectedprodline.prodlineId);
  }

  private LoadProductsLight(){
    this.helmsrv.LoadAllProductlinesLight()
      .subscribe( res => {
        if(res != undefined && res.length > 0) this.allproductlines = res;
        this.allproductlines.unshift(this.allprodline);
        this.allprodlinesrc = this.allproductlines;
        if(localStorage.getItem('selectedProdline') == null || localStorage.getItem('selectedProdline') == "TUTTE LE LINEE"){
          //this.selectedprod == this.allprodsrc.filter(p => p.productId == "TUTTI I PRODOTTI")[0];
          this.selectedprodline = this.allprodline;
        }
        else{
          this.selectedprodline = this.allproductlines.filter(p => p.prodlineId == localStorage.getItem('selectedProdline'))[0];
        }
      },
      (error) => {
        console.log("Errore caricamento linee di prodotto", error);
      });
  }

  searchProdline(){
    this.searchinput == '' ? this.allprodlinesrc = this.allproductlines : this.allprodlinesrc = this.allproductlines.filter(prod => {return prod.code.toUpperCase().includes(this.searchinput.toUpperCase())});
  }

}
