<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Nuovo utente esterno</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="AddNewUser(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/externalusers" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=21  rowHeight="550px">
          <mat-grid-tile [colspan]=4>
            <div class="col-int">
              <button type="button" id="loadFileBtn">
                <input type="file" id="loadFileInput" hidden (change)="readUrl($event)" accept=".png,.jpg"/>
                <label for="loadFileInput"><img id="usrimg" [src]="newuser.image"></label>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=8>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="newuser.name" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Cognome</mat-label>
                <input matInput type="text" name="surname" [ngModel]="newuser.surname" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Email</mat-label>
                <input matInput type="text" name="email" [ngModel]="newuser.email" required
                  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Telefono</mat-label>
                <input matInput type="text" name="phone" [ngModel]="newuser.phone">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=8>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Azienda</mat-label>
                <mat-select name="company" [ngModel]="newuser.company" required>
                  <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Autorizzazione</mat-label>
                <mat-select name="privilege" [ngModel]="newuser.privilege" required>
                  <mat-option value="user">User</mat-option>
                  <mat-option value="viewer">Viewer</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="row" id="role-container">
                <mat-form-field id="role">
                  <mat-label>Ruolo</mat-label>
                  <mat-select name="role" [ngModel]="newuser.role" >
                    <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-fab class="utilbtn" type="button" (click)="addNewRole()"  matTooltip="Nuovo ruolo"  matTooltipPosition="below">
                  <i class="material-icons">add</i></button>
              </div>
              <mat-form-field class="row">
                <mat-label>Password</mat-label>
                <input matInput type="password" name="password" [ngModel]="newuser.password" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
