import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-text-input-bottom',
  templateUrl: './text-input-bottom.component.html',
  styleUrls: ['./text-input-bottom.component.scss']
})
export class TextInputBottomComponent implements OnInit {

  textToAdd: string;

  constructor(private _bottomSheetRef: MatBottomSheetRef<TextInputBottomComponent>, private sharedsrv: SharedService) { }

  ngOnInit(): void {
  }

  setTextToAdd(){
    this.sharedsrv.emitValue(["OK", this.textToAdd]);
    this._bottomSheetRef.dismiss();
  }
}
