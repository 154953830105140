<div class="example-container">
  <div class="form-login">
    <img [src]="nebulaImg" width="260" height="119" class="login-image">
    <mat-form-field appearance="fill">
      <mat-label>Enter your password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="login()">Login</button>
  </div>
</div>
