import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProdLight } from 'src/app/models/shared/prod-light';
import { HelmService } from 'src/app/services/helm.service';

@Component({
  selector: 'app-elenco-asset',
  templateUrl: './elenco-asset.component.html',
  styleUrls: [
    './elenco-asset.component.scss',
    '../../../app.component.scss']
})
export class ElencoAssetComponent implements OnInit {

  allproducts: ProdLight[]= [];
  selectedprod: ProdLight;
  allprodsrc: ProdLight[] = [];
  searchinput: string = "";

  allprod: ProdLight = {
    name: "TUTTI I PRODOTTI",
    address: undefined,
    code: "TUTTI I PRODOTTI",
    description: undefined,
    image: undefined,
    productId: "TUTTI I PRODOTTI"
  }

  @Output() setSelProd = new EventEmitter<string>();

  constructor(private helmsrv: HelmService) {

  }

  ngOnInit(): void {
    this.LoadProductsLight();
  }

  SelProduct(prod:ProdLight){
    this.selectedprod = prod;
    localStorage.setItem('selectedProd', prod.productId);
    this.setSelProd.emit(this.selectedprod.productId);
  }

  private LoadProductsLight(){
    this.helmsrv.LoadAllProductsLight()
      .subscribe( res => {

        if(res != undefined && res.length > 0) this.allproducts = res;
        this.allproducts.unshift(this.allprod);
        this.allprodsrc = this.allproducts;
        if(localStorage.getItem('selectedProd') == null || localStorage.getItem('selectedProd') == "TUTTI I PRODOTTI"){
          //this.selectedprod == this.allprodsrc.filter(p => p.productId == "TUTTI I PRODOTTI")[0];
          this.selectedprod = this.allprod;
        }
        else{
          this.selectedprod = this.allproducts.filter(p => p.productId == localStorage.getItem('selectedProd'))[0];
        }
      },
      (error) => {
        console.log("Errore caricamento prodotti", error);
      });
  }

  ricercaProd(){
    this.searchinput == '' ? this.allprodsrc = this.allproducts : this.allprodsrc = this.allproducts.filter(prod => {return prod.code.toUpperCase().includes(this.searchinput.toUpperCase())});
  }
}
