<mat-card id="assetinfocontainer" *ngIf="loaded">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="nebula-dark left" class="assettitle">{{selectedProd.code}}</h5>
    </span>
  </mat-card-header>
  <img mat-card-image src="{{selectedProd.image}}" class="responsive-img"/>
  <mat-card-content>
    <table style="table-layout: fixed;" id="elecoinfo">
      <thead class="no-line" style="visibility: collapse">
          <tr>
              <th style="width: 40%;">Key</th>
              <th style="width: 5%;">Key</th>
              <th style="width: 55%;">Value</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngIf="selectedProd.name">
          <td class="singleinfo">Nome</td><td class="singleinfo">:</td>
          <td class="singleinfo">{{selectedProd.name}}</td>
        </tr>
        <tr *ngIf="selectedProd.description">
          <td class="singleinfo">Descrizione</td><td class="singleinfo">:</td>
          <td class="singleinfo">{{selectedProd.description}}</td>
        </tr>
        <tr *ngIf="selectedProd.address">
          <td class="singleinfo">Indirizzo</td><td class="singleinfo">:</td>
          <td class="singleinfo overflow-ellipsis"><a href="{{'https://www.google.it/maps/place/' + selectedProd.address}}" target="_blank" title="Go to Google Maps">{{selectedProd.address}}</a></td>
        </tr>
        <tr *ngIf="selectedProd.owner">
          <td class="singleinfo">Proprietario</td><td class="singleinfo">:</td>
          <td class="singleinfo">{{selectedProd.owner.surname}} {{selectedProd.owner.name}}</td>
        </tr>
      </tbody>
    </table>


  </mat-card-content>
</mat-card>
