<div class="maincontainer">
  <app-navbar></app-navbar>
  <mat-grid-list [cols]=12 class="appcontainer" rowHeight='fit'>
    <mat-grid-tile [colspan]=1>
      <app-menu-prodotto [selectedProdId]="selectedProdId" *ngIf="selectedProdId != 'TUTTI I PRODOTTI'"></app-menu-prodotto>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=7>
      <img *ngIf="selectedProdId == 'TUTTI I PRODOTTI'" [src]="nebulaImg" id="nebulaImg">
      <app-info-prodotto *ngIf="selectedProdId != 'TUTTI I PRODOTTI'" [selectedProdId]="selectedProdId"></app-info-prodotto>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4>
      <app-elenco-asset (setSelProd)="SelProd($event)"></app-elenco-asset>
    </mat-grid-tile>
  </mat-grid-list>
</div>
