import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { download } from '../models/cde/common/download';
import { X3dmodelInfo } from '../models/shared/x3dmodels';

@Injectable({
  providedIn: 'root'
})
export class Viewerx3dService {

  option!: HttpHeaders;

  constructor(private http: HttpClient) { }

  GetX3dModel(path: string): Observable<X3dmodelInfo>{
    const ApiUrl = environment.mainUrl + 'x3Dmodel';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<X3dmodelInfo>(ApiUrl, {params:{path: path}, headers: this.option})
      .pipe(
        map(res => {
          return res;
      }),
        catchError(this.errorhandler)
      );
  }

  GetSpareParts(parts: any[]): any {
        const ApiUrl = environment.mainUrl + 'spareparts';
    this.option = new HttpHeaders()
      .set('Content-Type','application/json')
        .set('Authorization', 'Bearer ' + (localStorage.getItem('token') || localStorage.getItem('link_token')))
        return this.http.post(ApiUrl, parts,{ headers: this.option, responseType: 'blob'});
  }

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
