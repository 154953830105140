import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.scss',
    '../../app.component.scss'
  ]
})
export class HomeComponent implements OnInit {

  nebulaImg: string = environment.imageUrl + 'nebula.png';

  constructor() { }

  ngOnInit(): void {
  }

}
