<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Nuova Cartella</mat-label>
        <input matInput [(ngModel)]="nameFolder" name="folder">
      </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" class="confbtn menubtn" position="right" (click)="createNewFolder()" cdkFocusInitial>Crea</button>
        <button mat-raised-button color="accent" mat-dialog-close class="confbtn menubtn">Chiudi</button>
    </mat-dialog-actions>
</form>

