<form #fm="ngForm" (submit)="setTextToAdd()" >
  <div class="other-infos">
    <mat-form-field appearance="fill">
      <mat-label>Testo da inserire</mat-label>
      <textarea matInput [(ngModel)]="textToAdd" name="textToAdd" required></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" [disabled]="fm.invalid">Inserisci</button>
  </div>
</form>

