import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Markup } from '../models/activity/markup';
import { Model3d } from '../models/activity/model3d';
import { Ticket } from '../models/activity/ticket';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  option!: HttpHeaders;

  constructor(private http: HttpClient) { }

  //#region Markup
  LoadMarkup(code:string) : Observable<Markup[]>{
    const ApiUrl = environment.mainUrl + 'markup/' + code;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['markups'];
      }),
        catchError(this.errorhandler)
      );
  }

  DeleteMarkup(markupcode:string) : Observable<Markup[]>{
    const ApiUrl = environment.mainUrl + 'markup/' + markupcode;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.delete(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['message'];
      }),
        catchError(this.errorhandler)
      );
  }

  //#endregion

  //#region Ticket
  LoadAllTickets() : Observable<Ticket[]>{
    let ticketId = localStorage.getItem('selectedProd');
    if(ticketId == 'TUTTI I PRODOTTI') ticketId = 'allProds';
    const ApiUrl = environment.mainUrl + 'ticket/' + ticketId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          let alltickets: Ticket[] = res['tickets'];
          for(let ticket of alltickets){
            ticket.startDate = new Date(ticket.startDate);
            ticket.endDate = new Date(ticket.endDate);
          }
          return alltickets;
      }),
        catchError(this.errorhandler)
      );
  }

  LoadModels(prodiId: string): Observable<Model3d[]>{
    const ApiUrl = environment.mainUrl + 'models/' + prodiId;
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(ApiUrl, {headers: this.option})
      .pipe(
        map(res => {
          return res['models'];
      }),
        catchError(this.errorhandler)
      );
  }

  //#endregion

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
