import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companies-helm',
  templateUrl: './companies-helm.component.html',
  styleUrls: [
    './companies-helm.component.scss'
  ]
})
export class CompaniesHelmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
