import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { Order } from 'src/app/models/helm/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-ao-table',
  templateUrl: './ao-table.component.html',
  styleUrls: [
    './ao-table.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class AoTableComponent implements OnInit {

  userprivilege = localStorage.getItem('priv');
  allOrders: Order[] = [];
  confirm: boolean = false;
  order: Order;

  displayedColumns = ['code', 'name', 'company', 'date_delivery', 'price_awarded', 'actions'];
  dataSource = new MatTableDataSource<Order>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private ordsrv: OrderService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.LoadOrders();
    this.ordsrv.SetOrder(this.order);
  }

  ngAfterViewInit(): void{
    this.dataSource.sort = this.sort;
  }

  LoadOrders(){
    this.ordsrv.LoadAllOrders()
      .subscribe(res => {
        this.allOrders = res;
        this.dataSource.data = this.allOrders;
      })
  }

  openDialog(orderId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare la commessa?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.ordsrv.DeleteOrder(orderId).subscribe((res)=>{
          console.log(res);
          this.LoadOrders();
        }, (err) => {
          console.log(err);
        })
      }
    })
  }
}
