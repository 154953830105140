import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NebDrawing } from '../models/activity/neb-drawing';

@Injectable({
  providedIn: 'root'
})
export class DrawingsService {

  option!: HttpHeaders;
  public strokeWidth: number;
  public strokeColor: string;
  public circleRadius: number;
  public circleFill: string;

  protected _svg?: SVGElement;
  public ticketgroup?: SVGElement;

  protected _SVGPoints: Array<SVGCircleElement>;
  protected _polylines: Record<string, SVGPolylineElement>;
  protected _rectangles: Record<string, SVGRectElement>;
  protected _circles: Record<string, SVGCircleElement>;

  constructor(private http: HttpClient) {
    this.strokeWidth  = 2;
    this.strokeColor  = '#000000';
    this.circleFill   = '#0000ff';
    this.circleRadius = 2;

    this._SVGPoints = new Array<SVGCircleElement>();
    this._polylines = {}
    this._rectangles = {}
    this._circles = {}
  }

  public set svg(surface: SVGElement)
  {
    if (surface !== undefined && surface != null && surface instanceof SVGElement) {
      this._svg = surface;
    }
  }

  GetSvgDrawing(path: string): Observable<NebDrawing>{
    const ApiUrl = environment.mainUrl + 'svgdrawing';
    this.option = new HttpHeaders().set('Content-Type','application/json').set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get<NebDrawing>(ApiUrl, {params:{path: path}, headers: this.option})
      .pipe(
        map(res => {
          return res;
      }),
        catchError(this.errorhandler)
      );
  }

  public createNewArea(){
    this.ticketgroup = document.createElementNS('http://www.w3.org/2000/svg','g');
    this.ticketgroup.setAttribute('id', `rfigroup`);
    if (this._svg)
    {
      this._svg.appendChild(this.ticketgroup);
    }
  }

  public addTicket(p: DOMPoint): void {
    var circle = document.createElementNS('http://www.w3.org/2000/svg','circle');
    circle.setAttribute('cx', `${p.x}`);
    circle.setAttribute('cy', `${p.y}`);
    circle.setAttribute('r', `${this.circleRadius}`);
    circle.setAttribute('fill', `${this.circleFill}`);

    if (this._svg)
    {
      this.ticketgroup.appendChild(circle);
    }
  }

  public clear(): void
  {
/*     if (this._svg)
    {
      this._SVGPoints.forEach( (circle: SVGCircleElement): void => {
        this._svg.remove(circle);
      });

      this._SVGPoints.length = 0;

      Object.keys(this._polylines).forEach( (name: string): void => {
        this._svg.remove(this._polylines[name]);
      });

      this._polylines = {};


    } */
  }

  public removeSelObj(name: string, type:string){
/*     switch (type){
      case 'polyline':
        this._svg.remove(this._polylines[name]);
      break;
      case 'rectangle':
        this._svg.remove(this._rectangles[name]);
      break;
    }
 */
  }

  public addPoint(p: DOMPoint): void {
    var circle = document.createElementNS('http://www.w3.org/2000/svg','circle');
    circle.setAttribute('cx', `${p.x}`);
    circle.setAttribute('cy', `${p.y}`);
    circle.setAttribute('r', `${this.circleRadius}`);
    circle.setAttribute('fill', `${this.circleFill}`);
/*     circle.cx =
      {
        left: p.x - this.circleRadius,
        top: p.y - this.circleRadius,
        fill: this.circleFill,
        radius: this.circleRadius
      }); */

      console.log(circle);
    this._SVGPoints.push(circle);

    if (this._svg)
    {
      this._svg.appendChild(circle);
    }
  }

  public addPolyline(name: string, SVGPoints: Array<SVGPoint>, clear: boolean = true): void
  {
/*     const polyLine: SVGPolylineElement = new SVGPolylineElement(SVGPoints,
      {
        strokeWidth: this.strokeWidth,
        stroke: this.strokeColor,
        fill: 'transparent',
        selectable: false,
        moveCursor: 'arrow',
        hoverCursor: 'arrow',
      });

    if (this._svg)
    {
      if (clear && this._polylines[name] !== undefined) {
        this._svg.remove(this._polylines[name]);
      }

      this._svg.add(polyLine);

    }

    this._polylines[name] = polyLine; */
  }

  public addRectangle(name: string, SVGPoints: Array<SVGPoint>, clear: boolean = true){
/*     const newRect = new SVGRectElement({
      left: SVGPoints[0].x,
      top: SVGPoints[0].y,
      fill:'transparent',
      width: Math.abs(SVGPoints[0].x - SVGPoints[1].x),
      height: Math.abs(SVGPoints[0].y - SVGPoints[1].y),
      strokeWidth: this.strokeWidth,
      stroke: this.strokeColor,
      selectable: false,
      moveCursor: 'arrow',
      hoverCursor: 'arrow'
    })

    if (this._svg)
    {
      if (clear && this._rectangles[name] !== undefined) {
        this._svg.remove(this._rectangles[name]);
      }

      this._svg.add(newRect);

    }

    this._rectangles[name] = newRect; */
  }

  public addCircle(name: string, SVGPoints: Array<SVGPoint>, clear: boolean = true){
/*     let radius = Math.sqrt(Math.pow(Math.abs(SVGPoints[0].x - SVGPoints[1].x),2) + Math.pow(Math.abs(SVGPoints[0].y - SVGPoints[1].y),2));
    const newCirc = new SVGCircleElement({
      left: SVGPoints[0].x - radius,
      top: SVGPoints[0].y- radius,
      fill:'transparent',
      radius: radius,
      strokeWidth: this.strokeWidth,
      stroke: this.strokeColor,
      selectable: false,
      moveCursor: 'arrow',
      hoverCursor: 'arrow'
    })

    if (this._svg)
    {
      if (clear && this._circles[name] !== undefined) {
        this._svg.remove(this._circles[name]);
      }

      this._svg.add(newCirc);

    }

    this._circles[name] = newCirc; */
  }

  public addText(text: string, SVGPoint:SVGPoint, size: number){
/*     const newtext = new fabric.Text(text, {
      left: SVGPoint.x,
      top: SVGPoint.y,
      fontSize: size,
      fill: this.strokeColor
    })
    this._svg.add(newtext);
    this._svg.renderAll(); */
  }

  public setPenWidth(val:number){
/*     this._svg.freeDrawingBrush.width = val;
    this.strokeWidth = val; */
  }

  public setColour(val:string){
    this.circleFill = '#' + val;
    this.strokeColor = '#' + val;
  }

  public refreshCanvas(){
/*     let drawings = this._svg.getObjects();
    for(let d of drawings){
      this._svg.remove(d)
    }
    this._svg.renderAll(); */
  }

  public deleteObjects(){
/*     if(this._svg.getActiveObjects().length > 0) {
      for(let ob of this._svg.getActiveObjects()){
        this._svg.remove(ob)
      }
    } */
  }

  public allowSelect(){
/*     this._svg.selection = true;
    if(this._svg.getObjects().length > 0){
      for(let ob of this._svg.getObjects()){
        ob.selectable = true;
        ob.moveCursor= 'SVGPointer';
        ob.hoverCursor= 'SVGPointer';
      }
    } */
  }

  public denySelect(){
/*     this._svg.selection = false;
    if(this._svg.getObjects().length > 0){
      for(let ob of this._svg.getObjects()){
        ob.selectable = false;
        ob.moveCursor= 'arrow';
        ob.hoverCursor= 'arrow';
      }
    } */
  }

  /*Gestione errori*/
  errorhandler(error: any){
    console.log(error);
    let msg: string;
    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        msg = 'App offline'
      }
      else{
        msg = `${error.error.message}`
      }
      return throwError(() => {new Error(msg)});
    }
    return throwError(()=> {new Error(`Si è verificato un errore di tipo: ${error.message}`)});
  }
}
