import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  constructor() { }
  @HostBinding('class.fileover')  fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>()
  files: any[] = [];

  @HostListener('dragover', ['$event']) onDragOver = (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave = (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('drop', ['$event']) public onDrop = async (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    console.log(evt)
    this.fileOver = false;

    const { dataTransfer } = evt;


    if (dataTransfer.items) {
      const entries = Array.from(dataTransfer.items)
      .filter(item => item.kind === 'file')
      .map(item => item.webkitGetAsEntry());
      this.buildArray(entries).then(() => {
        dataTransfer.items.clear();
        console.log(this.files)
        this.fileDropped.emit(this.files);
      });
    } else {
      const files = dataTransfer.files;
      dataTransfer.clearData();
      this.fileDropped.emit(Array.from(files));
    }
  }


  private parseFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        file => {
          resolve(new File([file], fileEntry.fullPath.substring(1)));
        },
        err => {
          reject(err);
        }
      );
    });
  }
  
  private parseDirectoryEntry(directoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        entries => {
          console.log(entries);
          resolve(this.buildArray(entries))
        },
        err => {
          reject(err);
        }
      );
    });
  }

  private buildArray(entries) {
    const promises = [];
    entries.forEach(entry => {
      if (entry.isFile) {
        const promise = this.parseFileEntry(entry).then(file => {
          this.files.push(file)
        });
        promises.push(promise);
      } else if (entry.isDirectory) {
        const promise = this.parseDirectoryEntry(entry).then(directories => {
          console.log(directories)
        });
        promises.push(promise);
    }});
    return Promise.all(promises).then(() => {});
  }





}
