import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-new-util-dialog',
  templateUrl: './new-util-dialog.component.html',
  styleUrls: [
    './new-util-dialog.component.scss',
    '../../../app.component.scss'
  ]
})
export class NewUtilDialogComponent implements OnInit {

  @Input() title: string = "";
  @Input() settingType: string = "";
  settingValue: string = "";

  @Output() confirm = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<NewUtilDialogComponent>, private cmnsrv: CommonService,) { }

  ngOnInit(): void {
  }

  Add(): void {
    if(this.settingValue != ""){
      this.cmnsrv.addUtil(this.settingType, this.settingValue)
      .subscribe(res => {
        console.log(res);
        this.confirm.emit(this.settingValue);
        this.dialogRef.close();
      },
      (error) => {
        console.log("Errore inserimento nuova impostazione", error);
      });
    }
  }
}
