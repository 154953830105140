<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Nuova azienda</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="AddNewCompany(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/companies" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=5 rowHeight="550px">
          <mat-grid-tile>
            <div class="col-int">
              <button type="button" id="loadFileBtn">
                <input type="file" id="loadFileInput" hidden (change)="readUrl($event)" accept=".png,.jpg"/>
                <label for="loadFileInput"><img id="usrimg" [src]="newCompany.logo"></label>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Nome Azienda</mat-label>
                <input matInput type="text" name="name" [ngModel]="newCompany.name" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Indirizzo</mat-label>
                <input matInput type="text" name="address" [ngModel]="newCompany.address" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="email" [ngModel]="newCompany.email" required
                  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Telefono</mat-label>
                <input matInput type="text" name="phone" [ngModel]="newCompany.phone">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Sito web</mat-label>
                <input matInput type="text" name="website" [ngModel]="newCompany.website">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Tipo</mat-label>
                <mat-select name="type" [ngModel]="newCompany.type" required>
                  <mat-option value="Partner">Partner</mat-option>
                  <mat-option value="Cliente">Cliente</mat-option>
                  <mat-option value="Fornitore">Fornitore</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Contatto interno</mat-label>
                <input matInput type="text" name="internalname" [ngModel]="newCompany.internalname" required>
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="internalemail" [ngModel]="newCompany.internalemail" required
                  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              </mat-form-field>
              <mat-form-field class="row">
                <mat-label>Telefono</mat-label>
                <input matInput type="text" name="internalphone" [ngModel]="newCompany.internalphone">
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
