import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/shared/product';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-prodotto',
  templateUrl: './menu-prodotto.component.html',
  styleUrls: [
    './menu-prodotto.component.scss',
    '../../../../app.component.scss'
  ]
})
export class MenuProdottoComponent implements OnInit {

  // selectedProdId: string = localStorage.getItem('selectedProd');
  @Input() selectedProdId: string;

  selectedProd: string = '/';


  constructor(private helmsrv: HelmService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.LoadProduct();
  }

  ngOnChanges(){
    this.LoadProduct();
  }

  LoadProduct(){
    this.selectedProdId = localStorage.getItem('selectedProd');
    if(this.selectedProdId != "" && this.selectedProdId != "TUTTI I PRODOTTI"){
      this.helmsrv.LoadSelectedProduct(this.selectedProdId)
      .subscribe( res => {
          this.selectedProd = res.code;
        },
        (error) => {
          console.log("Errore caricamento prodotto", error);
        });
    }
  }

  Load3D(){
    console.log(this.selectedProd);
    var path1 = "/REPO/PRODUCTS/COD01/COD01_CONCEPT/Mot_FIN.stp";
    var path2 = "/REPO/PRODUCTS/COD02/COD02_CONCEPT/Rim_FIN.stp";
    var path3 = "/REPO/PRODUCTS/VIA2.214.00.001/VIA2.214.00.001_CONCEPT/VIA2.214.00.001.fbx";
    var path4 = "/REPO/PRODUCTS/VIA2.244.00.001/VIA2.244.00.001_CONCEPT/VIA2.244.00.001.fbx";
    var path5 = "/REPO/PRODUCTS/VIA2.247.00.001/VIA2.247.00.001_CONCEPT/VIA2.247.00.001.fbx";
    var path6 = "/REPO/PRODUCTS/VIA2.256.00.001/VIA2.256.00.001_CONCEPT/VIA2.256.00.001.fbx";

    var fbx = "https://devnebulateam.bimspace.it/fbxviewer/"
    if(this.selectedProdId == "4420705700"){
      window.open('/#/3dviewer?path=' + path2, '_blank');
    }
    else if(this.selectedProdId == "12429657166"){
      window.open('/#/threeviewer?path=' + path3, '_blank');
    }
    else if(this.selectedProdId == "4962025386"){
      window.open('/#/threeviewer?path=' + path4, '_blank');
    }
    else if(this.selectedProdId == "6265931358"){
      window.open('/#/threeviewer?path=' + path5, '_blank');
    }
    else if(this.selectedProdId == "2627190852"){
      window.open('/#/threeviewer?path=' + path6, '_blank');
    }
    else{
      window.open('/#/3dviewer?path=' + path1, '_blank');
    }

  }

  LoadGallery(){
    var path1 = "/REPO/PRODUCTS/COD01/COD01_GALLERY/";
    var path2 = "/REPO/PRODUCTS/COD02/COD02_GALLERY/";
    var path3 = "/REPO/PRODUCTS/GJA300000001/GJA300000001_GALLERY/";
    var path4 = "/REPO/PRODUCTS/VIT3/VIT3_GALLERY/";
    if(this.selectedProdId == "4420705700"){
      window.open('/#/cde?path=' + path2, '_blank');
    }
    else if(this.selectedProdId == "11571951455"){
      window.open('/#/cde?path=' + path3, '_blank');
    }
    else if(this.selectedProdId == "2254341357"){
      window.open('/#/cde?path=' + path4, '_blank');
    }
    else{
      window.open('/#/cde?path=' + path1, '_blank');
    }
  }

  LoadMap(){
    const dialogRef = this.dialog.open(MapDialogContent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  navigateTo(): void {
    localStorage.setItem("CLOUD", "P");
    this.router.navigate(["/cde"], { queryParams: { path: '/REPO/PRODUCTS/' + this.selectedProd + '/'} });
  }

}

@Component({
  selector: 'map-dialog-content',
  templateUrl: 'map-dialog.html',
  styles: [
    `
    ::ng-deep .mat-dialog-content{
    height: 100%;
    width: 100%
  }`]
})
export class MapDialogContent {}
