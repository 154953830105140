import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NewUtilDialogComponent } from 'src/app/components/shared/new-util-dialog/new-util-dialog.component';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { User } from 'src/app/models/helm/user';
import { CommonService } from 'src/app/services/common.service';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-internaluser',
  templateUrl: './new-internaluser.component.html',
  styleUrls: [
    './new-internaluser.component.scss',
    '../../../../app.component.scss'
  ]
})
export class NewInternaluserComponent implements OnInit {

  newImg: string;
  newuser: User= {
    userId: "",
    name: "",
    surname: "",
    email: "",
    company: {companyId: "", name: "", address: "", logo: "", type: ""},
    phone: "",
    image: environment.imageUrl + "/defaultuser.png",
    privilege: "",
    role: "",
    user_type: "",
    password:"",
  };

  companies: CompanyLight[];
  roles: string[] = [];

  constructor(private helmsrv: HelmService, private cmnsrv: CommonService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.LoadCompanies();
  }

  LoadCompanies(){
    this.helmsrv.LoadAllCompaniesLight()
      .subscribe((res) => {
        this.companies = res
        this.companies = this.companies.filter((comp) => {return comp.type == 'Owner' || comp.type == 'Partner'});
        this.cmnsrv.getUtil('roles')
          .subscribe((res) => {
            this.roles = res;
          });
      });
  }

  AddNewUser(form: NgForm){
    this.newuser = {...form.value};
    if(this.newImg != undefined) this.newuser.image = this.newImg;
    if(this.newuser.image == environment.imageUrl + "/defaultuser.png" || this.newuser.image == undefined) this.newuser.image = "";
    this.newuser.user_type = "Interno";
    this.helmsrv.AddNewUser(this.newuser)
      .subscribe(res => {
        this.router.navigateByUrl('/helm/internalusers')},
      (error) => {
        console.log("Errore inserimento nuovo utente", error);
      });
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.newuser.image = this.newImg;
      };
    }
  }

  addNewRole(): void {
    const dialogRef = this.dialog.open(NewUtilDialogComponent, {
      height: '270px',
      width: '450px'
    });
    dialogRef.componentInstance.title = "Nuovo ruolo";
    dialogRef.componentInstance.settingType = "roles";
    dialogRef.componentInstance.confirm.subscribe(res => {
      this.roles.push(res);
    })
  }
}
