import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/components/shared/confirm-dialog/confirm-dialog.component';
import { Company } from 'src/app/models/helm/company';
import { CommonService } from 'src/app/services/common.service';
import { HelmService } from 'src/app/services/helm.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-all-companies-helm',
  templateUrl: './all-companies-helm.component.html',
  styleUrls: [
    './all-companies-helm.component.scss',
    '../../../../app.component.scss']
})
export class AllCompaniesHelmComponent implements OnInit {

  userprivilege = localStorage.getItem('priv');
  allCompanies: Company[];

  displayedColumns = ['logo', 'name', 'address', 'phone', 'email', 'type', 'interno','azioni'];
  dataSource = new MatTableDataSource<Company>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('fileInput') fileIn: ElementRef;

  constructor(private helmsrv: HelmService, public dialog: MatDialog, private cmnsrv: CommonService) { }

  ngOnInit(): void {
    this.LoadAllCompanies();
  }

  ngAfterViewInit(): void{
    this.dataSource.sort = this.sort;
  }

  LoadAllCompanies(){
    this.helmsrv.LoadAllCompanies().subscribe(
      (res: Company[]) => {
        this.allCompanies = res;
        this.dataSource.data = this.allCompanies;
      },
      (error) => {
        console.log("Errore caricamento aziende", error);
      });
  }

  UploadTemplate(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const formData = new FormData();
      const[file] = event.target.files;
      formData.append('file', file);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.cmnsrv.UploadTemplate(formData, 'company')
        .subscribe(res => {
          console.log(res);
          this.LoadAllCompanies();
          this.fileIn.nativeElement.value = "";
        })
      };
    }
  }

  DownloadTemplate(){
    this.cmnsrv.DownloadTemplate('company')
    .subscribe((response) => {
      const blob = new Blob([response], {type: response.type});
      saveAs(blob, 'CompanyTemplate.xlsx');
    })
  }

  openDialog(companyId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px'
    });
    dialogRef.componentInstance.message = "Sicuro di voler cancellare l'azienda selezionata?";
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res == "SI"){
        this.helmsrv.DeleteCompany(companyId).subscribe(res=>{
          console.log(res);
          this.LoadAllCompanies();
        }, (err) => {
          console.log(err);
        })
      }
    })
  }
}
