<div class="maincontainer">
  <app-navbar></app-navbar>
  <mat-grid-list [cols]=12 class="appcontainer" rowHeight='fit'>
    <mat-grid-tile [colspan]=1>
      <app-menu-activities [selectedProd]="selectedProdId" (setSelMenu)="NavigateTo($event)"></app-menu-activities>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=7>
      <app-home-ticket [selectedProdId]="selectedProdId"></app-home-ticket>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=4>
      <app-elenco-asset (setSelProd)="SelProd($event)"></app-elenco-asset>
    </mat-grid-tile>
  </mat-grid-list>
</div>
