<mat-toolbar class="navbar" (click)="resetInfo()">
    <span *ngFor="let el of breadcumbs; let index = index">
        <button mat-button (click)="navigateTo(el.path)"> {{el.name}}</button>
        <span *ngIf="index < (breadcumbs).length-1"> / </span>
    </span>
    <span class="right">
        <button mat-mini-fab color="primary" class="mr-10"
            #tooltip="matTooltip"
            matTooltip="Upload files"
            [matTooltipPosition]="positionTooltip"
            *ngIf="flags && flags.write"
            (click)="openUpdateDialog()"><mat-icon>upload</mat-icon></button>
    </span>
</mat-toolbar>

<div class="mat-elevation-z8 mt-64" (click)="resetInfo()" >
    <table mat-table [dataSource]="dataSource" matSort class="full-container">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <!-- Nome Column -->
        <ng-container matColumnDef="Nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 65em"> Nome </th>
            <td mat-cell *matCellDef="let element" style="width: 65em">
                <mat-list class="no-padding">
                    <mat-list-item (click)="navigate(element)" class="no-padding pointer">
                        <img *ngIf="element.type == 'file'" [src]="'https://dev.bimspace.it/image/format/' + element.icon.substring(1) + '.png'" height="32" width="32" class="left"/>
                        <div mat-line *ngIf="element.type == 'file' && !element.renameActive" class="pl-40">{{element.name.substring(1, element.name.lastIndexOf('.'))}} </div>
                        <img *ngIf="element.type == 'folder'" [src]="'https://dev.bimspace.it/image/folder2.png'" height="32" width="32" class="left"/>
                        <div mat-line *ngIf="element.type == 'folder' && !element.renameActive" class="pl-40"> {{element.name.substring(1)}} </div>
                    </mat-list-item>
                </mat-list>
            </td>
        </ng-container>

        <!-- Dimensione Column -->
        <ng-container matColumnDef="Dimensione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dimensione </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.type == 'file' && (element.dimension/1024) > 0 && (element.dimension/1024) <= 1024 "> {{ element.dimension/1024 |
                    number: '1.2-2' }} KB </span>
                <span *ngIf="element.type == 'file' && (element.dimension/(1024*1024)) > 1.1  && (element.dimension/1024) <= 1000000"> {{element.dimension/(1024*1024) | number: '1.2-2' }} MB </span>
                <span *ngIf="element.type == 'file' && (element.dimension/(1024*1024*1024)) > 1.1 && (element.dimension/1024) <= 1000000000"> {{ element.dimension/(1024*1024*1024) | number: '1.2-2' }} GB  </span>
                <span *ngIf="element.type == 'folder'"> &mdash; </span>
            </td>
        </ng-container>

        <!-- Ultima modifica Column -->
        <ng-container matColumnDef="Ultima modifica">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ultima modifica </th>
            <td mat-cell *matCellDef="let element"> {{element.date | date:'MMM d, y, h:mm' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]="row.selected" [class.opacity]="row.hidden" (contextmenu)="onRightClick($event, row)"></tr>
    </table>

      <!-- an hidden div is created to set the position of appearance of the menu-->
      <div style="visibility: hidden; position: fixed;"
        [style.left]="menuTopLeftPosition.x"
        [style.top]="menuTopLeftPosition.y"
        [matMenuTriggerFor]="rightMenu" #rMenu="matMenuTrigger"></div>

      <!-- standard material menu -->
      <mat-menu #rightMenu="matMenu">
        <ng-template matMenuContent let-item="item">
          <button mat-menu-item><mat-icon>done</mat-icon>Selected {{item.name.substring(1)}}</button>
          <button mat-menu-item *ngIf="item.type == 'file' && item.hidden == false" (click)="onPreviewItem(item)"><mat-icon>remove_red_eye</mat-icon> Anteprima</button>
          <button mat-menu-item (click)="download(item)" *ngIf="!flags.view && (flags.read || flags.write)"><mat-icon>file_download</mat-icon> Download</button>
          <button mat-menu-item (click)="deleteItem(item)" *ngIf="item.hidden == false && flags.write"><mat-icon>delete</mat-icon> Cancella</button>
        </ng-template>
    </mat-menu>

    <mat-card *ngIf="showProgressBar" style="position: absolute; top: 40%; width: 40%; left: 28%; box-shadow: 1px 1px 2px 2px lightgrey;">
        <div class="right top-right">
            <button mat-mini-fab (click)="onCloseProgress()" aria-label="Close download">
              <mat-icon>clear</mat-icon>
            </button>
        </div>
        <br>
        <br>
        <h5>Download {{selectItem.type == "folder" ? "cartella" : selectItem.type}} - {{selectItem.name.substring(1)}}</h5>
        <mat-progress-bar *ngIf="download$ | async as download"
            [mode]="download.state == 'IN_PROGRESS' ? 'buffer' : 'determinate'"
            [value]="download.progress">
        </mat-progress-bar>
    </mat-card>
</div>
