<div id="smenucontainer" *ngIf="selectedProd != 'TUTTI I PRODOTTI'">
  <button mat-fab class="menubtn" color="primary" (click)="SetMenu('ticket')" matTooltip="TICKET" matTooltipPosition="right">
    <i class="material-icons">fact_check</i></button>
  <button mat-fab class="menubtn" color="primary" (click)="SetMenu('markup')" matTooltip="MARKUP" matTooltipPosition="right">
    <i class="material-icons">feedback</i></button>
  <button *ngIf="selectedProd != 'TUTTI I PRODOTTI'" mat-fab class="menubtn" color="primary" (click)="SetMenu('chat')" matTooltip="CHAT" matTooltipPosition="right">
    <i class="material-icons">question_answer</i></button>
</div>

<div id="smenucontainer2" *ngIf="selectedProd == 'TUTTI I PRODOTTI'">
  <button mat-fab class="menubtn" color="primary" (click)="SetMenu('ticket')" matTooltip="TICKET" matTooltipPosition="right">
    <i class="material-icons">fact_check</i></button>
  <button mat-fab class="menubtn" color="primary" (click)="SetMenu('markup')" matTooltip="MARKUP" matTooltipPosition="right">
    <i class="material-icons">feedback</i></button>
</div>
