import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ticket } from 'src/app/models/activity/ticket';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-all-ticket',
  templateUrl: './all-ticket.component.html',
  styleUrls: ['./all-ticket.component.scss']
})
export class AllTicketComponent implements OnInit {

  selprodId: string;
  allTickets: Ticket[] = [];
  alerts: Ticket[] = [];
  classification= {
    strut: 0,
    elet: 0,
    idra: 0,
    mec: 0
  }
  opened: number;
  resolved: number;

  constructor(private actsrv: ActivityService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.selprodId = routeParams.prodid;
      console.log(this.selprodId);
      this.LoadTicket();
    })
  }

  LoadTicket(){
    this.actsrv.LoadAllTickets()
      .subscribe(res => {
        this.allTickets = res;
        this.alerts = this.allTickets.filter(t => t.endDate < (new Date()))
        this.classification.strut = this.allTickets.filter(t => t.classification == '0').length;
        this.classification.elet = this.allTickets.filter(t => t.classification == '1').length;
        this.classification.idra = this.allTickets.filter(t => t.classification == '2').length;
        this.classification.mec = this.allTickets.filter(t => t.classification == '3').length;
        this.opened = this.allTickets.filter(t => t.opened).length;
        this.resolved = this.allTickets.length - this.opened;
      })
  }

}
