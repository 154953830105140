<mat-card id="assetinfocontainer" *ngIf="loaded">
  <mat-card-header>
    <span class="underline-text-nebula">
      <h5 class="nebula-dark left" class="assettitle">Chat</h5>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div id="mk-container">
      <div class="full-container" *ngIf="selprodId != 'all'">
        <mat-grid-list rowHeight="320px" [cols]="2">
          <mat-grid-tile>
            <div id="img-container">
              <img [src]="selProd.image" id="prodimg">
            </div>

          </mat-grid-tile>
          <mat-grid-tile>
            <div id="part-container">
              <mat-card id="members-container">
                <mat-card-header>
                  <span class="underline-text-nebula">
                    <h4 class="nebula-dark left" >Partecipanti</h4>
                  </span>
                </mat-card-header>
                <mat-card-content>
                  <mat-list id="members-list">
                    <mat-list-item *ngFor="let memb of selProd.members">{{memb.user.surname}} {{memb.user.name}}</mat-list-item>
                  </mat-list>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan=2>
            <div *ngIf="chatenabled" class="full-container">
              <app-chat-module ></app-chat-module>
            </div>
            <div class="msg" *ngIf="!chatenabled">
              <h4>Entrare in chat?</h4>
              <button (click)="chatenabled = true" mat-raised-button color=primary>Avvia</button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>

      </div>
      <img *ngIf="selprodId == 'all'" [src]="nebulaImg" id="nebulaImg">
    </div>
  </mat-card-content>
</mat-card>
