<form #fm="ngForm" >
  <div id="dialog-container">
    <mat-dialog-content>
      <mat-form-field id="input-title" color="primary" appearance="standard">
        <mat-label>{{title}}</mat-label>
        <input matInput name="mkName" color="primary" [(ngModel)]="textInput" type="text" required/>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button class="confbtn menubtn" color="primary" position="right" (click)="onSiClick()" [disabled]="fm.invalid" cdkFocusInitial>SALVA</button>
      <button mat-raised-button mat-dialog-close class="confbtn menubtn" color="accent">ANNULLA</button>
    </mat-dialog-actions>
  </div>
</form>
