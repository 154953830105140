import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CostSet } from 'src/app/models/helm/cost-set';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { OrderService } from 'src/app/services/order.service';
import { CostSetDialogComponent } from '../../shared/cost-set-dialog/cost-set-dialog.component';

@Component({
  selector: 'app-no-cost',
  templateUrl: './no-cost.component.html',
  styleUrls: [
    './no-cost.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class NoCostComponent implements OnInit {

  newOrder: Order;
  newCost: OrderCost;
  allcostset: CostSet[];


  constructor(private ordsrv: OrderService, private router: Router, public dialog: MatDialog) {
    this.newOrder = this.ordsrv.GetOrder();
  }

  ngOnInit(): void {
    this.newCost = this.newOrder.costs;
    this.SetDaysPlannned();
    this.ordsrv.getCostSet()
      .subscribe(res => {
        this.allcostset = res;
        if(this.newCost.cost_set != undefined && this.newCost.cost_set.name != '') this.newCost.cost_set = this.allcostset.filter(c => c.name == this.newCost.cost_set.name)[0];
      })
  }

  SetDaysPlannned(){
    this.newCost.days_assigned = 0;
    this.newCost.costs_planned = 0;
    for(let act of this.newOrder.activity_planned){
      if(act.days != undefined) this.newCost.days_assigned = this.newCost.days_assigned + act.days;
      if(act.cost != undefined) this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
    }
  }

  UpdateCost(){
    this.newCost.costs_planned = 0;
    if(this.newCost.cost_set != undefined && this.newCost.cost_set.name != ''){
      for(let act of this.newOrder.activity_planned){
        act.cost = 0;
        if(act.user_type == 'Commerciale'){
            switch(act.type){
              case 'Onsite':
                act.cost = this.newCost.cost_set.onsiteC * act.days;
                this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
                break;
              case 'Online':
                act.cost = this.newCost.cost_set.onlineC * act.days;
                this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
                break;
              case 'Remote':
                act.cost = this.newCost.cost_set.remoteC * act.days;
                this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
                break;
            }

        }
        else if(act.user_type == 'Tecnico'){
          switch(act.type){
            case 'Onsite':
              act.cost = this.newCost.cost_set.onsiteT * act.days;
              this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
              break;
            case 'Online':
              act.cost = this.newCost.cost_set.onlineT * act.days;
              this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
              break;
            case 'Remote':
              act.cost = this.newCost.cost_set.remoteT * act.days;
              this.newCost.costs_planned = this.newCost.costs_planned + act.cost;
              break;
          }
        }
      }
    }
  }

  navigate(form: NgForm, area: string){
    this.newOrder.costs = this.newCost;
    // this.newOrder = {...form.value};
    this.ordsrv.SetOrder(this.newOrder);
    this.router.navigateByUrl('/helm/orders/new/'+ area);
  }

  openDialog(dialogtype: string): void {
    const dialogRef = this.dialog.open(CostSetDialogComponent, {
      width: '600px'
    });
    dialogRef.componentInstance.costname = this.newCost.cost_set.name;
    dialogRef.componentInstance.dialogtype = dialogtype;
    dialogRef.componentInstance.confirm.subscribe(res => {
      if(res != undefined && res != ''){
        this.ordsrv.getCostSet()
          .subscribe(costs => {
            this.allcostset = costs;
            this.newCost.cost_set = costs.filter(c => c.name == res)[0];
          })
      }
    })
  }
}
