import { Component, OnInit, Input} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivityLight } from 'src/app/models/helm/activity-light';
import { CompanyLight } from 'src/app/models/helm/company-light';
import { Order } from 'src/app/models/helm/order';
import { OrderCost } from 'src/app/models/helm/order-cost';
import { OrderProd } from 'src/app/models/helm/order-prod';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { HelmService } from 'src/app/services/helm.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-no-main',
  templateUrl: './no-main.component.html',
  styleUrls: [
    './no-main.component.scss',
    '../../../../../app.component.scss'
  ]
})
export class NoMainComponent implements OnInit {

  newOrder: Order;
  activitySet: ActivityLight[];
  costsSet: OrderCost;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  products: OrderProd[]= [];
  prodsForSel: AutocompObj[] = [];
  prodCtrl = new FormControl();
  filteredprod: Observable<AutocompObj[]>;
  selectedprod: AutocompObj;

  companies: CompanyLight[]= [];
  companiesForSel: AutocompObj[] = [];
  companiesCtrl = new FormControl();
  filteredcompanies: Observable<AutocompObj[]>;
  selectedcompany: AutocompObj;

  constructor(private helmsrv: HelmService, private ordsrv: OrderService, private router: Router, public snackBar: MatSnackBar) {
    this.newOrder = this.ordsrv.GetOrder();
   }

  ngOnInit(): void {
    this.activitySet = this.newOrder.activity_planned;
    this.costsSet = this.newOrder.costs;
    this.LoadSettings();
  }

  LoadSettings(){
    this.helmsrv.LoadAllUsersAuth()
      .subscribe((res) => {
        this.users = res
        for(let usr of this.users){
          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(this.newOrder.manager.userId == "" && usr.userId == localStorage.getItem('id'))
            this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
        }
        this.filteredusrsowner = this.ownerCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
        this.ordsrv.LoadOrderProds()
          .subscribe(res => {
            this.products = res;
            for(let prod of this.products){
              this.prodsForSel.push({id: prod.id, text: prod.code});
            }
            this.filteredprod = this.prodCtrl.valueChanges
            .pipe(
              startWith(''),
              map(prod => this._filterProd(prod))
            );
            this.helmsrv.LoadAllCompaniesLight()
            .subscribe(res => {
              this.companies = res;
              for(let comp of this.companies){
                this.companiesForSel.push({id: comp.companyId, text: comp.name});
              }
              this.filteredcompanies = this.companiesCtrl.valueChanges
              .pipe(
                startWith(''),
                map(comp => this._filterComp(comp))
              );
            })
          })
      });
      if(this.selectedowner == undefined && this.newOrder.manager.userId != undefined)
        this.selectedowner = {id: this.newOrder.manager.userId, text: `${this.newOrder.manager.surname} ${this.newOrder.manager.name}  - ${this.newOrder.manager.company.name}`};
      if(this.selectedprod == undefined && this.newOrder.product != undefined)
        this.selectedprod = {id: this.newOrder.product.id, text: this.newOrder.product.code};
      if(this.selectedcompany == undefined && this.newOrder.client != undefined)
        this.selectedcompany = {id: this.newOrder.client.companyId, text: this.newOrder.client.name};
  }


  navigate(form: NgForm, area: string){
    this.newOrder = {...form.value};
    if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
      this.newOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
    if(this.selectedprod != undefined && typeof(this.selectedprod) == 'object')
      this.newOrder.product = this.products.filter(prod => prod.id == this.selectedprod.id)[0];
    if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
      this.newOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
    this.newOrder.activity_planned = this.activitySet;
    this.newOrder.costs = this.costsSet;
    this.ordsrv.SetOrder(this.newOrder);
    this.router.navigateByUrl('/helm/orders/new/' + area);
  }

  AddNewOrder(form: NgForm){
    if(typeof(this.selectedowner) != 'object'){
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else if(typeof(this.selectedprod) != 'object'){
      this.snackBar.open("Selezionare un prodotto o una linea di prodotti", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
    else{
      this.newOrder = {...form.value};
      if(this.selectedowner != undefined && typeof(this.selectedowner) == 'object')
        this.newOrder.manager = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
      if(this.selectedprod != undefined && typeof(this.selectedprod) == 'object')
        this.newOrder.product = this.products.filter(prod => prod.id == this.selectedprod.id)[0];
      if(this.selectedcompany != undefined && typeof(this.selectedcompany) == 'object')
        this.newOrder.client = this.companies.filter(comp => comp.companyId == this.selectedcompany.id)[0];
      this.newOrder.activity_planned = this.activitySet;
      this.newOrder.costs = this.costsSet;
      this.ordsrv.SetOrder(this.newOrder);
      this.ordsrv.SaveOrder()
        .subscribe(res => {
          console.log(res);
          this.router.navigateByUrl('/helm/orders');
        })
    }
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterProd(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.prodsForSel.filter(prod => prod.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterComp(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.companiesForSel.filter(comp => comp.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }

}
