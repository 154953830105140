<form #fm="ngForm">
  <mat-card class="helmcard">
    <mat-card-header>
      <span class="underline-text-nebula">
        <h5 class="mdc-text left" class="assettitle">Anagrafica Commessa</h5>
      </span>
      <div class="right">
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'cost')" matTooltip="Tempi e Costi" matTooltipPosition="below" type="button">
          <i class="material-icons">euro</i></button>
        <button mat-fab class="menubtn" color="primary"(click)="navigate(fm, 'activity')" matTooltip="Attività" matTooltipPosition="below" type="submit">
          <i class="material-icons">event</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Salva" matTooltipPosition="below" (click)="AddNewOrder(fm)" [disabled]="fm.invalid" type="submit">
          <i class="material-icons">save</i></button>
        <button mat-fab class="menubtn" color="primary" matTooltip="Indietro" routerLink="/helm/orders" matTooltipPosition="below">
          <i class="material-icons">close</i></button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="internalMatGrid">
        <mat-grid-list [cols]=10  rowHeight="100px">
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Codice</mat-label>
                <input matInput type="text" name="code" [ngModel]="newOrder.code" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [ngModel]="newOrder.name" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=6>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Descrizione</mat-label>
                <input matInput type="text" name="description" [ngModel]="newOrder.description" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Anno</mat-label>
                <input matInput type="number" name="year" [ngModel]="newOrder.year">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Color</mat-label>
                <input matInput type="color" name="color" [ngModel]="newOrder.color">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=6>
            <div class="col-int">
              <mat-form-field class="row2" style="margin-right: 2.5%;">
                <mat-label>Proprietario</mat-label>
                <input matInput type="text" name="owner" [(ngModel)]="selectedowner"  required [matAutocomplete]="auto" [formControl]="ownerCtrl">
                <mat-autocomplete  #auto="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let usr of filteredusrsowner | async" [value]="usr">{{usr.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="row2" style="margin-left: 2.5%;">
                <mat-label>Prodotto</mat-label>
                <input matInput type="text" name="product" [(ngModel)]="selectedprod"  required [matAutocomplete]="autoProd" [formControl]="prodCtrl">
                <mat-autocomplete  #autoProd="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let prod of filteredprod | async" [value]="prod">{{prod.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data offerta</mat-label>
                <input matInput type="date" name="date_offer" [ngModel]="newOrder.date_offer">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Data Consegna</mat-label>
                <input matInput type="date" name="date_delivery" [ngModel]="newOrder.date_delivery">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=6>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Cliente</mat-label>
                <input matInput type="text" name="client" [(ngModel)]="selectedcompany"  required [matAutocomplete]="autoComp" [formControl]="companiesCtrl">
                <mat-autocomplete  #autoComp="matAutocomplete" [displayWith]="getDisplayFn()">
                  <mat-option *ngFor="let comp of filteredcompanies | async" [value]="comp">{{comp.text}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Costi previsti</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="cost_tot" [ngModel]="newOrder.cost_tot">
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=2>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Valore</mat-label>
                <span matPrefix>€ &nbsp;</span>
                <input matInput type="number" name="price_awarded" [ngModel]="newOrder.price_awarded" required>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]=6>
            <div class="col-int">
              <mat-form-field class="row">
                <mat-label>Note</mat-label>
                <input matInput type="text" name="note" [ngModel]="newOrder.note">
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
</form>
