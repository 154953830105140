import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloudItem } from 'src/app/models/cde/cloud-item';
import { CdeServiceService } from 'src/app/services/cde-service.service';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {

  blob: Blob;
  safeUrl: SafeUrl;
  videoItemLink: string;
  videoLink: string;
  docUrl: string;
  nameDocLink: string;
  viewer: string;

  constructor(public dialogRef: MatDialogRef<PreviewDialogComponent>,
    private cdeservices: CdeServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer) {
      console.log('conttype', this.data.cloud.contentType);
      if (this.data.cloud.contentType.includes('video')){
        this.cdeservices
        .getStream(this.data.cloud, this.data.share)
          .subscribe(res => {
            this.videoItemLink = res.link
            this.videoLink = environment.mainUrl + "stream?path=" + res.link
          })

      } else if (
        this.data.cloud.contentType.includes("msword") ||
        this.data.cloud.contentType.includes("vnd.openxml") ||
        this.data.cloud.contentType.includes("vnd.ms-excel") ||
        this.data.cloud.contentType.includes("vnd.ms-powerpoint") ||
        this.data.cloud.contentType.includes("text")
        ){
          this.cdeservices
            .getOfficePreview(this.data.cloud, this.data.share)
              .subscribe(res => {
                console.log(res);
                this.nameDocLink = res.name
                this.docUrl = environment.mainUrl + res.link;
              })

        }
        else if(this.data.cloud.abs_path.includes(".ifc")){
          this.viewer = "on";
        }
        else if(this.data.cloud.contentType.includes('octet-stream')){
          console.log('x3dmodel');
        }
        else{
          this.cdeservices
              .dowloadPdfV1(this.data.cloud)
                .subscribe(res => {
                    console.log(res)
                    if (res.type.includes('image')){
                      console.log("Immagine")
                      let blb = new Blob([res], {type: res.type});
                      let unsafeImageUrl = URL.createObjectURL(blb);
                      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
                    }/* else if (res.type.includes('video')){
                      console.log("video")
                      let unsafeVideoUrl = URL.createObjectURL(res);
                      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeVideoUrl);
                    } */
                    else{
                      this.blob = new Blob([res], {type: res.type});
                    }

                })
      }
  }

  ngOnInit(): void {
  }

  stopVideo(){
    this.cdeservices
        .deleteStream(this.videoItemLink)
          .subscribe(res => {
            this.nameDocLink = undefined;
            this.videoLink = undefined;
            console.log(res)
          })
  }

  deleteDocPreview(){
    this.cdeservices
        .deleteOfficePreview(this.nameDocLink)
          .subscribe(res => {
            this.nameDocLink = undefined;
            this.videoLink = undefined;
            console.log(res)
          })
  }

}
