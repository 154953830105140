import { Component, OnInit} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ProdMemb } from 'src/app/models/helm/prod-memb';
import { AuthUser } from 'src/app/models/shared/auth-user';
import { AutocompObj } from 'src/app/models/shared/autocomp-obj';
import { Product } from 'src/app/models/shared/product';
import { HelmService } from 'src/app/services/helm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-product-helm',
  templateUrl: './new-product-helm.component.html',
  styleUrls: [
    './new-product-helm.component.scss',
    '../../../../app.component.scss'
  ]
})
export class NewProductHelmComponent implements OnInit {

  newImg: string;
  newProd: Product = {
    productId: "",
    code: "",
    image: environment.imageUrl + "/defaultproduct.png",
    address: "",
    name: "",
    description: "",
    owner: {
      userId: "",
      name: "",
      surname: "",
      company: {
        companyId: "",
        name: "",
        address: "",
        type: "",
        logo: ""
      },
      privilege: "",
      user_type: ""
    },
    members: []
  }

  esiste: boolean = false;

  users: AuthUser[] = [];
  usersForSel: AutocompObj[] = [];
  ownerForSel: AutocompObj[] = [];

  ownerCtrl = new FormControl();
  filteredusrsowner: Observable<AutocompObj[]>;
  selectedowner: AutocompObj;

  memberCtrl = new FormControl();
  filteredusrsmember: Observable<AutocompObj[]>;
  selectedmember: AutocompObj;

  members: ProdMemb[] = [];

  displayedColumns = ['memb', 'permission', 'actions'];
  dataSource = new MatTableDataSource<ProdMemb>();

  constructor(private helmsrv: HelmService, private router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.LoadUsers();
  }

  LoadUsers(){
    this.helmsrv.LoadAllUsersAuth()
      .subscribe((res) => {
        this.users = res
        for(let usr of this.users){
          this.usersForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(usr.privilege == 'admin' || usr.privilege == 'superuser') this.ownerForSel.push({id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`})
          if(usr.userId == localStorage.getItem('id')) this.selectedowner = {id: usr.userId, text: `${usr.surname} ${usr.name}  - ${usr.company.name}`};
        }
        this.dataSource.data = this.members;
        this.filteredusrsowner = this.ownerCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterOwnUsr(usr))
        );
        this.filteredusrsmember = this.memberCtrl.valueChanges
        .pipe(
          startWith(''),
          map(usr => this._filterUsr(usr))
        );
      });
  }

  addMember(){
    if(typeof(this.selectedmember) === 'object'){
      let usr = this.users.filter(u => u.userId == this.selectedmember.id);
      let newmemb = {member: this.selectedmember, privilege: usr[0].privilege, permission: false}
      if(this.members.filter(mem => mem.member.id === newmemb.member.id).length === 0 && this.selectedmember.id != this.selectedowner.id){
        this.members.push(newmemb);
        this.dataSource.data = this.members;
      }
      this.memberCtrl.setValue('');
    }
    else this.memberCtrl.setValue('');
  }

  remMemb(usr: ProdMemb){
    let delusr = this.members.filter(mem => mem.member.id == usr.member.id)[0];
    let index = this.members.indexOf(delusr)
    this.members.splice(index, 1);
    this.dataSource.data = this.members;
  }

  readUrl(event: any): void{
    const reader = new FileReader();
    if (event.target.files && event.target.files.length){
      const[file] = event.target.files;
      this.newImg = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImg = reader.result as string;
        this.newProd.image = this.newImg;
      };
    }
  }

  CheckCode(form: NgForm){
    this.newProd = {...form.value};
    if(this.newImg != undefined) this.newProd.image = this.newImg;
    else this.newProd.image = environment.imageUrl + "/defaultproduct.png";
    this.helmsrv.CheckCode(this.newProd.code, 'product')
      .subscribe(res => {
        this.esiste = res;
      })
  }

  AddNewProd(form: NgForm){
    if(typeof(this.selectedowner) == 'object'){
      this.newProd = {...form.value};
      if(this.esiste){
        if(this.newImg != undefined) this.newProd.image = this.newImg;
        else this.newProd.image = environment.imageUrl + "/defaultproduct.png";
        this.snackBar.open("Il codice inserito appartiene già ad un altro prodotto", "OK", {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "bottom"
        })
      }
      else{
        this.newProd.owner = this.users.filter(usr => usr.userId == this.selectedowner.id)[0];
        if(this.newProd.owner.privilege == 'viewer'){
          this.snackBar.open("Il proprietario non può essere un utente VIEWER", "OK", {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "bottom"
          })
        }
        else{
          if(this.newImg != undefined) this.newProd.image = this.newImg;
          if(this.newProd.image == environment.imageUrl + "/defaultproduct.png" || this.newProd.image == undefined) this.newProd.image = "";
          this.newProd.members = new Array();
          for(let memb of this.members){
            this.newProd.members.push({user: this.users.filter(usr => usr.userId == memb.member.id)[0], permission: memb.permission});
          }
          this.helmsrv.AddNewProduct(this.newProd)
            .subscribe(res => {
              this.router.navigateByUrl('/helm/products')},
            (error) => {
              console.log("Errore inserimento nuovo utente", error);
            });
        }
      }
    }
    else{
      this.snackBar.open("Nessun proprietario selezionato", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      })
    }
  }

  private _filterUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.usersForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  private _filterOwnUsr(value: string): AutocompObj[] {
    if(typeof(value) === 'string'){
      const filterValue = value?.toLowerCase() ?? '';
      return this.ownerForSel.filter(usr => usr.text.toLowerCase().indexOf(filterValue) > -1);
    }
  }

  public getDisplayFn() {
    return (val) => this.display(val);
  }

  private display(user): string {
    //access component "this" here
    return user ? user.text : user;
  }
}
